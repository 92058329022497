<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Mis Solicitudes
        <v-spacer></v-spacer>
        <v-select
          dense
          :items="liststatus"
          v-model="statusfilter"
          label="Filtro por estado"
          item-text="status"
          item-value="id"
          class="mr-2"
          multiple
        >
        </v-select>
        <v-btn text icon class="mr-2" @click="removeFilter">
          <v-icon>mdi-sticker-remove-outline</v-icon>
        </v-btn>
        <v-text-field
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Búsqueda"
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="d-flex justify-space-between">
            <v-btn color="primary" @click="removeFilter">
              <v-icon>mdi-refresh</v-icon>
              Actualizar</v-btn>
            <v-btn
              color="info"
              @click="$router.push('/iel-alta-cliente')"
            >
              Crear Solicitud
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div></div>
        <v-data-table
          :headers="headers"
          :items="customersrequests"
          :loading="loading"
          :server-items-length="totalItems"
          :options.sync="pagination"
          @update:options="onPagination"
        >
          <!-- <template v-slot:[`item.index`]="{ index }">
            {{ page > 2 ? (page * pagination.itemsPerPage) + index + 1  : index + 1}}
          </template> -->
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="item.status == 'Rechazada Crédito'"
              class="ma-2"
              color="red"
              text-color="white"
            >
              {{ item.status }}
            </v-chip>
            <v-chip
              v-if="item.status == 'Rechazado Gerencia'"
              class="ma-2"
              color="warning"
              text-color="white"
            >
              {{ item.status }}
            </v-chip>
            <v-chip
              v-if="item.status == 'Aceptada'"
              class="ma-2"
              color="success"
              text-color="white"
            >
              {{ item.status }}
            </v-chip>

            <span
              v-if="
                item.status != 'Rechazado Gerencia' &&
                item.status != 'Rechazada Crédito' &&
                item.status != 'Aceptada'
              "
              >{{ item.status }}</span
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  icon
                  @click="$router.push(`/iel-alta-cliente/${item.id}`)"
                >
                  <v-icon> mdi-eye </v-icon>
                </v-btn>
              </template>
              <span>Ver/editar datos de la solicitud</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="accent"
                  icon
                  @click="showObservations(item.observations)"
                >
                  <v-icon> mdi-list-status </v-icon>
                </v-btn>
              </template>
              <span>Ver observaciones de la solicitud</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="item.customer_status.slug == 'revision-gerencia'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  icon
                  @click="openDeclineDialog(item)"
                >
                  <v-icon> mdi-cancel </v-icon>
                </v-btn>
              </template>
              <span>Rechazar solicitud</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="loadRequest"> Recargar </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="observations.dialog" hide-overlay persistent width="50%">
      <v-card>
        <v-card-title>
          <span> Observaciones </span>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="observations.dialog = false"
            >Cerrar</v-btn
          >
        </v-card-title>
        <v-card-text class="h2">
          <observations-timeline
            :observations="observations.data"
          ></observations-timeline>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="observations.dialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeclineDialog" hide-overlay persistent width="50%">
      <v-card>
        <v-card-title>
          <span> Rechazar solicitud </span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="h2">
          <p>Regresar solicitud al Vendedor para actualizar información</p>
          <v-form ref="formDecline" v-model="formDecline.valid">
            <v-text-field
              readonly
              label="Razón social"
              v-model="formDecline.name"
              :rules="[(v) => !!v || 'Razón social es requerido']"
            ></v-text-field>
            <v-text-field
              readonly
              label="RFC"
              v-model="formDecline.rfc"
              :rules="[(v) => !!v || 'RFC es requerido']"
            ></v-text-field>
            <v-textarea
              label="Observaciones"
              v-model="formDecline.observations"
              :rules="[(v) => !!v || 'Observaciones es requerido']"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="cancelDecline">Cancelar</v-btn>
          <v-btn color="error" @click="declineRequest">Rechazar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import ObservationsTimeline from "@/components/iel/alta/ObservationsTimeline.vue";

export default {
  components: { ObservationsTimeline },
  data() {
    return {
      customersrequests: [],
      params: null,
      loading: false,
      search: null,
      totalItems: 0,
      page: 1,
      headers: [
        { text: "Vendedor", value: "agent_number" },
        { text: "Fecha", value: "created" },
        { text: "Nombre", value: "company_name" },
        { text: "RFC", value: "rfc" },
        { text: "Estado", value: "a_estate" },
        { text: "Municipio", value: "a_town" },
        { text: "Estatus", value: "status" },
        { text: "Acciones", value: "actions" },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        descending: [false],
      },
      observations: {
        data: [],
        dialog: false,
      },
      showDeclineDialog: false,
      formDecline: {
        valid: false,
        id: null,
        name: null,
        rfc: null,
        status: "rechazado-gerencia",
        observations: null,
      },
      liststatus: [],
      statusfilter: [3, 6],
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  watch: {
    search() {
      this.searchRequest();
    },
    statusfilter(value) {
      this.filterRequest();
      console.log(value);
    },
  },

  mounted() {
    this.cleanData();
    this.loadStatus();
    this.loadRequest(this.getParams());
  },

  methods: {
    ...mapMutations({
      cleanData: "customerRequest/cleanData",
    }),
    getParams() {
      let params = `page=${this.pagination.page}&agent_number=${this.user.employee_number}`;
      this.page = this.pagination.page;

      if (this.search) {
        params += `&search=${this.search}`;
      }

      if (this.statusfilter) {
        params += `&status=${this.statusfilter}`;
      }

      params += `&per_page=${this.pagination.itemsPerPage}`;

      return params;
    },
    loadRequest(params) {
      if (this.loading) return;

      this.loading = true;

      axios
        .get(`${process.env.VUE_APP_API_IEL_SERVER}/customerrequests?${params}`)
        .then((response) => {
          this.customersrequests = response.data.data;
          this.pagination.page = response.data.current_page;
          this.pagination.itemsPerPage = response.data.per_page;
          this.totalItems = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchRequest() {
      this.loadRequest(this.getParams());
    },
    goTo(requestId) {
      this.$router.push("manager/requests/" + requestId);
    },
    showObservations(observations) {
      this.observations.data = observations;
      this.observations.dialog = true;
    },
    loadStatus() {
      axios
        .get(`${process.env.VUE_APP_API_IEL_SERVER}/customer-status`)
        .then((response) => {
          this.liststatus = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    filterRequest() {
      this.loadRequest(this.getParams());
    },
    removeFilter() {
      this.statusfilter = null;
      this.loadRequest(this.getParams());
    },
    onPagination(options) {
      this.pagination = options;
      this.loadRequest(this.getParams());
    },
    openDeclineDialog(item) {
      this.formDecline.id = item.id;
      this.formDecline.name = item.company_name;
      this.formDecline.rfc = item.rfc;
      this.formDecline.status = "rechazado-gerencia";
      this.showDeclineDialog = true;
    },
    cancelDecline() {
      this.formDecline.id = null;
      this.formDecline.name = null;
      this.formDecline.rfc = null;
      this.showDeclineDialog = false;
    },
    declineRequest() {
      this.loading = true;
      this.$refs.formDecline.validate();

      if (!this.formDecline.valid) {
        this.$toast("Revisa los campos faltantes", {
          type: "warning",
          timeout: 2000,
        });
        this.loading = false;
        return false;
      }
      axios
        .put(
          `/customer-data/${this.formDecline.id}/customer-status`,
          this.formDecline
        )
        .then((response) => {
          this.$toast(
            "Cambio de estado actualizado correctamente. Nuevo estado: " +
              response.data.status.status,
            {
              type: "success",
              timeout: 2000,
            }
          );
          this.filterRequest();
          this.cancelDecline();
        })
        .catch((error) => {
          this.$toast("Ocurrio un error. Revisa la solicitud", {
            type: "error",
            timeout: 2000,
          });
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    changeStatus(id, status) {
      let index = this.customersrequests.findIndex((item) => item.id == id);

      if (index > 0) {
        this.customersrequests[index].status = status.status;
      }
    },
  },
};
</script>

<style></style>
