<template>
  <div class="container-fluid grey lighten-5 pt-4">
    <h2 class="h2">Movimientos de Cuenta</h2>
    <p></p>
    <v-row>
      <v-col md="4">
        <v-btn depressed x-large color="primary" block @click="history">
          Historial &nbsp;<v-icon> mdi-text-box-multiple-outline</v-icon>
        </v-btn>
      </v-col>
      <v-col md="4" v-if="bonds.length > 0">
        <v-select
          v-if="invoiceBone != 0"
          label="Selecciona Bono"
          placeholder="Selecciona Bono"
          v-model="bondCurrent"
          :items="bonds"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col md="4">
        <v-btn
          depressed
          x-large
          color="error"
          v-if="selected.length > 0"
          block
          @click="toPay"
        >
          Pagar &nbsp;<v-icon> mdi-credit-card-check </v-icon> </v-btn
        ><br />
        <v-btn
          depressed
          x-large
          color="info"
          v-if="bondCurrent > 0"
          block
          :disabled="loadingBono"
          @click="toBond"
          >Aceptar Bono</v-btn
        >
      </v-col>
    </v-row>
    <br />
    <v-progress-linear
      :active="loadingBono"
      :indeterminate="loadingBono"
      top
      color="deep-purple accent-4"
    ></v-progress-linear>
    <br />
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar..."
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="markers"
            :items-per-page="5"
            show-select
            item-key="number"
            class="elevation-1"
          >
            <template v-slot:[`item.amount`]="{ item }">
              $ &nbsp;&nbsp;{{ format(item.amount) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <p></p>
    <div>
      <form ref="form1" method="post" :action="urlbankSend">
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_signature"
          name="mp_signature"
          v-model="form.mp_signature"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_account"
          name="mp_account"
          v-model="form.mp_account"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_product"
          name="mp_product"
          v-model="form.mp_product"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_order"
          name="mp_order"
          v-model="form.mp_order"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_reference"
          name="mp_reference"
          v-model="form.mp_reference"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_node"
          name="mp_node"
          v-model="form.mp_node"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_concept"
          name="mp_concept"
          v-model="form.mp_concept"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_amount"
          name="mp_amount"
          v-model="form.mp_amount"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_currency"
          name="mp_currency"
          v-model="form.mp_currency"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_urlsuccess"
          name="mp_urlsuccess"
          v-model="form.mp_urlsuccess"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_urlfailure"
          name="mp_urlfailure"
          v-model="form.mp_urlfailure"
        />
      </form>
    </div>

    <div class="text-center">
      <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Pago en línea
          </v-card-title>
          <v-card-text>
            <p></p>
            <h6>Por favor, seleccione un banco:</h6>
            <p></p>
            <v-row>
              <v-col md="6" style="margin-top: 25px">
                <v-img src="img/logo-banorte.svg"></v-img>
                <input
                  type="radio"
                  style="margin-top: 10px"
                  id="1"
                  value="1"
                  v-model="bank"
                />
              </v-col>
              <v-col md="6">
                <v-img src="img/logo-bbva.svg"> </v-img>
                <input type="radio" id="2" value="2" v-model="bank" />
              </v-col>
            </v-row>
            <p></p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" text @click="close()"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="send()"> Aceptar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog2" max-width="500px">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            <span>Pago Anticipado</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <br />
          <v-card-text>
            <label for="" class="mb-2 ml-2">Ingrese Monto</label>
            <div class="input-group">
              <span class="input-group-text" id="basic-addon1"
                ><i class="fa fa-credit-card"></i
              ></span>
              <input
                class="form-control"
                placeholder="Monto"
                v-model="antpay"
                onkeypress="this.value=this.value.replace(/[^0-9,]/g,'');"
                type="text"
              />
            </div>
            <v-row>
              <v-col md="6" style="margin-top: 25px">
                <v-img src="img/logo-banorte.svg"></v-img>
                <input
                  type="radio"
                  style="margin-top: 10px"
                  id="1"
                  value="1"
                  v-model="bank2"
                />
              </v-col>
              <v-col md="6">
                <v-img src="img/logo-bbva.svg"> </v-img>
                <input type="radio" id="2" value="2" v-model="bank2" />
              </v-col>
            </v-row>
          </v-card-text>
          <br />

          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" text @click="close2()"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="sendAnt()"> Aceptar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog3" width="90%">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Historial
          </v-card-title>
          <v-card-text>
            <br />
            <v-row>
              <v-col md="6">
                <v-row>
                  <v-col md="4">
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="start_date"
                          label="Fecha Inicials"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="start_date"
                        @input="closeDateMenu1"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="4">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="finish_date"
                          label="Fecha final"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="finish_date"
                        @input="closeDateMenu2"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="4">
                    <v-btn
                      depressed
                      color="success"
                      block
                      @click="accountStatus"
                    >
                      Ejecutar &nbsp;<v-icon>
                        mdi-tarrow-right-bold-circle-outline</v-icon
                      >
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="6">
                <v-row>
                  <v-col md="6"></v-col>
                  <v-col md="6">
                    <v-btn
                      depressed
                      large
                      color="primary"
                      block
                      @click="generatePDF"
                    >
                      Estado de Cuenta PDF &nbsp;<v-icon>
                        mdi-text-box-multiple-outline</v-icon
                      >
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <br />
            <v-progress-linear
              :active="loadingOrderDeliveryTime"
              :indeterminate="loadingOrderDeliveryTime"
              top
              color="deep-purple accent-4"
            ></v-progress-linear>
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar..."
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :search="search"
                :headers="headerHistory"
                :items="markersh"
                :items-per-page="5"
                item-key="number"
                class="elevation-1"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    large
                    class="mr-2"
                    @click="getPDFXMLInvoice(item.actions, 1)"
                    color="red darken-2"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                  <v-icon
                    large
                    @click="getPDFXMLInvoice(item.actions, 1)"
                    color="blue darken-2"
                  >
                    mdi-file-xml-box
                  </v-icon>
                </template>
                <template v-slot:[`item.pay_complement`]="{ item }">
                  <a
                    v-if="item.pay_complement != ''"
                    @click="getPDFXMLInvoice(item.pay_complement, 1)"
                  >
                    <v-icon dense large color="red">
                      mdi-file-pdf-box
                    </v-icon></a
                  >
                  <a
                    href="#"
                    v-if="item.pay_complement != ''"
                    @click="getPDFXMLInvoice(item.pay_complement, 2)"
                  >
                    <v-icon dense large color="blue">
                      mdi-file-xml-box
                    </v-icon></a
                  >
                  <a
                    href="#"
                    v-if="item.pay_complement != ''"
                    @click="senDEmailComp(item)"
                  >
                    <v-icon dense large color="blue">
                      mdi-email-arrow-right-outline
                    </v-icon></a
                  >
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="close3()"> Cerrar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  data() {
    return {
      loadingBono: false,
      url: null,
      bank: null,
      bank2: null,
      dialog: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      antpay: null,
      more: null,
      data_f: null,
      selected: [],
      bondCurrent: 0,
      bonds: [],
      bondCost: [],
      data: null,
      invoice: [],
      markers: [],
      markersh: [],
      search: "",
      loadingOrderDeliveryTime: true,
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      finish_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      menu2: false,
      datacustumer: null,
      datadays: null,
      token: null,
      mp_order_1: null,
      invoiceBone: 0,
      invoiceBoneCurrent: [],
      urlbank: process.env.VUE_APP_BBVA_URL,
      urlbankSend: process.env.VUE_APP_BBVA_SEND_URL,
      urlbankSendBamx: process.env.VUE_APP_BANAMEX_SEND_URL,
      headers: [
        {
          text: "Tipo Documento",
          align: "start",
          sortable: false,
          value: "type",
        },
        { text: "# Documento", value: "number" },
        { text: "Fecha", value: "date" },
        { text: "Vencimiento", value: "expiration" },
        { text: "Importe Por Pagar", align: "right", value: "amount" },
        { text: "Dias de Mora", value: "days" },
        { text: "Estatus", value: "status" },
        { text: "Nota de Crédito", align: "center", value: "vbelnnc" },
        {
          text: "Monto de Nota(s) de credito",
          align: "right",
          value: "dmbtrnc",
        },
        { text: "Valor NETO de la factura", align: "right", value: "dmbtrto" },
      ],
      headerHistory: [
        {
          text: "Tipo Documento",
          align: "start",
          sortable: false,
          value: "type",
        },
        //{ text: "Tipo Documento", value: "type" },
        { text: "# Documento", value: "number" },
        //{ text: "Orden de Compra", value: "purchase_oder" },
        { text: "Fecha", value: "date" },
        { text: "Vencimiento", value: "date_overdue" },
        { text: "Importe", value: "amount" },
        { text: "PDF/XML", value: "actions", sortable: false },
        {
          text: "Complemento de Pago",
          sortable: false,
          value: "pay_complement",
        },
      ],
      form: {
        mp_signature: "",
        mp_account: "",
        mp_product: "",
        mp_order: "",
        mp_reference: "",
        mp_node: "",
        mp_concept: "",
        mp_amount: "",
        mp_currency: "",
        mp_urlsuccess: "",
        mp_urlfailure: "",
      },
      bonoShow: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  watch: {
    selected(value) {
      this.invoice = [];
      this.invoiceBoneCurrent = [];
      this.invoiceBone = 0;
      if (value.length == 1) {
        this.invoiceBoneCurrent = value;
        this.invoiceBone = value[0].number;
        this.getBonds();
      }
      for (let i = 0; i < value.length; i++) {
        this.invoice.push({
          number: value[i].number,
          amount: value[i].amount,
        });
      }
    },
  },
  async mounted() {
    let self = this;
    console.log("send:" + this.urlbankSend);
    self.getCustomerInformation();
    self.cliente();
    self.daysPayDue();
    let uri = window.location.href.split("?");
    let vars = uri[1].split("=");
    let param = vars[0];
    console.log(vars[0]);

    if (param == "token") {
      self.getParam1();
    } else if (param == "data") {
      self.getParam2(this.$route.query.data);
    } else if (param == "strResponse") {
      self.getParam3();
    }
  },
  methods: {
    getBonds() {
      this.bonds = [];
      if (this.bonoShow) {
        this.$swal({
          title: "NO SE PUEDE UTILIZAR BONO CUANDO SE TIENEN FACTURAS VENCIDAS",
          icon: "error",
          type: "danger",
        }).then((result) => {
          console.log(result);
        });
        this.invoiceBone = 0;
      } else if (this.invoiceBoneCurrent[0].dmbtrto > 0) {
        axios
          .get(
            process.env.VUE_APP_API_IEL_SERVER +
              "/get-bonds/" +
              JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR +
              "/" +
              this.invoiceBone
          )
          .then((response) => {
            if (response.data.result == 1) {
              if (response.data.data.length == 0) {
                this.$swal({
                  title: "No tienes bonos asignados",
                  icon: "error",
                  type: "danger",
                }).then((result) => {
                  console.log(result);
                });
              } else {
                this.bonds = response.data.data;
              }
            } else {
              this.$swal({
                title: "No tienes bonos asignados",
                icon: "error",
                type: "danger",
              }).then((result) => {
                console.log(result);
              });
            }
          })
          .catch((error) => {
            console.warn(error);
          });
      } else {
        this.invoiceBone = 0;
      }
    },
    getCustomerInformation() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/getExpiredOrders", {
          client: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
        })
        .then((response) => {
          this.data = response.data;
          let info = this.data.length;
          for (let i = 0; i < info; i++) {
            if (this.data[i].ESTAT == "Vencido") {
              this.bonoShow = true;
            }
            this.markers.push({
              type: "FACTURA",
              number: this.data[i].BELNR,
              date: this.data[i].BUDAT,
              expiration: this.data[i].FVENC,
              amount: this.data[i].DMBTR,
              days: this.data[i].DMORA,
              status: this.data[i].ESTAT,
              vbelnnc: this.data[i].VBELNNC,
              dmbtrnc: this.data[i].DMBTRNC,
              dmbtrto: this.data[i].DMBTRTO,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    accountStatus() {
      this.markersh = [];
      this.loadingOrderDeliveryTime = true;
      let start_date = this.start_date;
      let finish_date = this.finish_date;
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/AccountStatus", {
          client: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
          date_start: start_date,
          date_end: finish_date,
        })
        .then((response) => {
          this.data = response.data;
          let info = this.data.length;
          for (let i = 0; i < info; i++) {
            let amount = this.format(this.data[i].DMBTR);
            this.markersh.push({
              currency: this.data[i].WAERS,
              type: "FACTURA",
              number: this.data[i].BELNR,
              purchase_oder: this.data[i].BSTKD,
              date: this.data[i].BUDAT,
              expiration: this.data[i].FAEDT,
              amount: amount,
              actions: this.data[i].BELNR,
              pay_complement: this.data[i].COMPL,
              date_overdue: this.data[i].FVENC,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => (this.loadingOrderDeliveryTime = false));
    },
    toBond() {
      this.loadingBono = true;
      this.bondCost = [];
      this.bondCost = this.bonds.filter((b) => b.id == this.bondCurrent);
      if (
        parseFloat(this.invoiceBoneCurrent[0].dmbtrto) >
        parseFloat(this.bondCost[0].cost)
      ) {
        axios
          .post(process.env.VUE_APP_API_IEL_SERVER + "/note-credit", {
            client_code: JSON.parse(localStorage.getItem("1us4-pv-cl13nt"))
              .KUNNR,
            factura: this.invoiceBoneCurrent[0].number,
            motivo: this.bondCurrent,
            cost: this.invoiceBoneCurrent[0].amount,
          })
          .then((response) => {
            this.loadingBono = false;
            console.log(response.data);
            if (response.data.result == 1) {
              this.$swal({
                title: response.data.message,
                icon: "success",
                type: "succes",
              }).then((result) => {
                console.log(result);
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              this.loadingBono = false;
              this.$swal({
                title: response.data.data,
                icon: "error",
                type: "danger",
              }).then((result) => {
                console.log(result);
              });
            }
          })
          .catch((e) => {
            this.loadingBono = false;
            console.warn(e);
          });
      } else {
        this.loadingBono = false;
        this.$swal({
          title: "El bono no puede ser mayor a el valor neto de la factura",
          icon: "error",
          type: "danger",
        }).then((result) => {
          console.log(result);
        });
      }
    },
    toAntPay() {
      this.dialog2 = true;
      this.selected;
    },
    history() {
      this.dialog3 = true;
      this.accountStatus();
    },
    close() {
      this.dialog = false;
      this.bank = null;
    },
    close2() {
      this.dialog2 = false;
      this.bank2 = null;
      this.antpay = null;
    },
    close3() {
      this.dialog3 = false;
    },
    close4() {
      this.dialog4 = false;
    },
    send() {
      let custumer_code = JSON.parse(
        localStorage.getItem("1us4-pv-cl13nt")
      ).KUNNR;
      let invoice = this.invoice;
      let bank = this.bank;
      if (this.bank) {
        axios
          .post(process.env.VUE_APP_API_IEL_SERVER + "/sendPayment", {
            invoice,
            bank,
            custumer_code,
          })
          .then((response) => {
            this.dialog = false;
            if (response.data.LREFE != "") {
              this.data = response.data.LREFE;
              this.url = this.data;
              window.location.href = this.url;
            } else {
              this.form = response.data;
              this.form.mp_signature = response.data.MPSIG;
              this.form.mp_account = response.data.MPACC;
              this.form.mp_product = response.data.MPPRO;
              this.form.mp_order = response.data.MPORD;
              this.form.mp_reference = response.data.MPREF;
              this.form.mp_node = response.data.MPNOD;
              this.form.mp_concept = response.data.MPCON;
              this.form.mp_amount = response.data.MPAMO;
              this.form.mp_currency = response.data.MPCUR;

              this.form.mp_urlsuccess = this.urlbank;
              this.form.mp_urlfailure = this.urlbank;
              console.log(response);

              if (response.data.MYERR == "1") {
                //
                this.$swal({
                  title: response.data.FNMSG,
                  icon: "success",
                  type: "succes",
                }).then((result) => {
                  console.log(result);
                });
              } else {
                //
                this.$nextTick(function () {
                  this.$refs.form1.submit();
                });
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        alert("Por favor, seleccione un banco...");
      }
    },
    format(value) {
      const result = Number(value).toFixed(2);
      console.log(result);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    sendAnt() {
      let custumer_code = JSON.parse(
        localStorage.getItem("1us4-pv-cl13nt")
      ).KUNNR;
      let invoice = "0000000000," + this.antpay;
      let bank = this.bank2;
      if (this.antpay == null) {
        alert("Por favor, Ingrese un Monto.");
      } else {
        if (this.bank2) {
          axios
            .post(process.env.VUE_APP_API_IEL_SERVER + "/sendPaymentAnt", {
              invoice,
              bank,
              custumer_code,
            })
            .then((response) => {
              this.dialog = false;
              if (response.data.LREFE != "") {
                this.data = response.data.LREFE;
                this.url = this.data;
                window.location.href = this.url;
              } else {
                this.form = response.data;
                this.$set(this.form, "mp_signature", response.data.MPSIG);
                this.$set(this.form, "mp_account", response.data.MPACC);
                this.$set(this.form, "mp_product", response.data.MPPRO);
                this.$set(this.form, "mp_order", response.data.MPORD);
                this.$set(this.form, "mp_reference", response.data.MPREF);
                this.$set(this.form, "mp_node", response.data.MPNOD);
                this.$set(this.form, "mp_concept", response.data.MPCON);
                this.$set(this.form, "mp_amount", response.data.MPAMO);
                this.$set(this.form, "mp_currency", response.data.MPCUR);
                this.$set(this.form, "mp_urlsuccess", this.urlbank);
                this.$set(this.form, "mp_urlfailure", this.urlbank);

                console.log(response);

                this.$nextTick(function () {
                  this.$refs.form1.submit();
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          alert("Por favor, seleccione un banco...");
        }
      }
    },
    getParam1() {
      let token = this.$route.query.token;
      let type_bank = 1;
      this.sendToken(token, type_bank);
    },
    getParam2(params) {
      this.$swal({
        title: params,
        icon: "success",
        type: "succes",
      }).then((result) => {
        if (result.value) {
          this.$router.replace({ query: null });
          location.reload();
        }
      });
    },
    getParam3() {
      let token = this.$route.query.strResponse;
      let type_bank = 3;
      this.sendToken(token, type_bank);
    },
    sendToken(token, bank) {
      if (token.length != 0) {
        axios
          .post(process.env.VUE_APP_API_IEL_SERVER + "/getToken", {
            token: token,
            bank: bank,
          })
          .then((response) => {
            console.log(response.data.FNMSG);
            this.$swal({
              title: response.data.FNMSG,
              icon: "success",
              type: "succes",
            }).then((result) => {
              if (result.value) {
                this.$router.replace({ query: null });
                location.reload();
              }
            });
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.msg = error.response.data.errors;
              this.hasError = true;
              this.snackbar = true;
              setTimeout(() => {
                this.hasError = false;
              }, 8000);
            } else {
              //alert(error);
              console.log(error);
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    closeDateMenu1() {
      this.menu1 = false;
      this.start_date = new Date(
        this.start_date - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      console.log(this.start_date);
    },
    closeDateMenu2() {
      this.menu2 = false;
      this.finish_date = new Date(
        this.finish_date - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
    },
    cliente() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/infoCustomer", {
          customer: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
          VKORG: "IUS2",
          VTWEG: "DT",
          VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
        })
        .then((response) => {
          this.datacustumer = response.data;
          console.log(response.data);
        });
    },
    daysPayDue() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/daysPayDue", {
          client: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
          VTWEG: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).VTWEG,
          VKBUR: this.user.VKBUR,
        })
        .then((response) => {
          this.datadays = response.data;
          console.log(response.data);
        });
    },
    enviar(e) {
      e.preventDefault();
      this.$refs.form1.submit();
    },
    getPDFXMLInvoice(invoice, type) {
      this.dialog_email = false;
      console.log(invoice);
      console.log(this.emai_user);
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/InvoicePDFXML", {
          invoice: invoice,
          type: type,
        })
        .then((response) => {
          this.url = response.data;
          window.open(this.url, "_blank");
        })
        .catch((error) => {
          // alert(error);
          console.log(error);
        });
    },
    generatePDF() {
      //this.cliente();
      this.$nextTick(function () {
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "in",
          format: "letter",
        });

        var img = new Image();
        img.src = "img/header.png";

        var footer = new Image();
        footer.src = "img/footer.png";
        doc.setFontSize(10);
        doc.addImage(img, "png", 0.5, 0.5, 7.5, 0.8);

        doc.autoTable({
          styles: {
            fillColor: [255, 255, 255],
          },
          alternateRowStyles: { fillColor: [255, 255, 255] },
          columns: [
            { header: "", dataKey: "title" },
            { header: "", dataKey: "content" },
          ],
          body: [
            { title: "Cliente:", content: this.datacustumer.KUNNR },
            { title: "Nombre:", content: this.datacustumer.NAME1 },
            { title: "RFC:", content: this.datacustumer.STCD1 },
            { title: "Email:", content: this.datacustumer.SMTP_ADDR },
            { title: "Teléfono:", content: this.datacustumer.TELF1 },
            {
              title: "Rango Fecha Solicitud:",
              content: this.start_date + " / " + this.finish_date,
            },
          ],
          columnStyles: {
            title: { fontStyle: "bold" },
          },
          margin: { left: 0.5, top: 1.5 },
        });

        doc.autoTable({
          styles: { halign: "left", fillColor: [255, 255, 255] },
          alternateRowStyles: { fillColor: [255, 255, 255] },
          tableLineColor: [169, 169, 169],
          tableLineWidth: 0.01,
          theme: "grid",
          columns: [
            { header: "Total", dataKey: "total" },
            { header: "Por Vencer Mes Actual", dataKey: "toover" },
            { header: "A 30 días", dataKey: "to30days" },
            { header: "A 60 días", dataKey: "to60days" },
            { header: "A 90 días", dataKey: "to90days" },
            { header: "A 120 días", dataKey: "to120days" },
          ],
          body: [
            {
              total: this.datadays.DMO00,
              toover: this.datadays.DMO00,
              to30days: this.datadays.DMO30,
              to60days: this.datadays.DMO60,
              to90days: this.datadays.DMO90,
              to120days: this.datadays.DM120,
            },
          ],
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            lineColor: [169, 169, 169],
            lineWidth: 0.01,
          },
          bodyStyles: {
            lineColor: [169, 169, 169],
            lineWidth: 0.01,
          },
          margin: { left: 0.5, top: 1.5 },
        });

        doc.autoTable({
          styles: { halign: "center" },
          alternateRowStyles: { fillColor: [211, 211, 211] },
          tableLineColor: [211, 211, 211],
          tableLineWidth: 0.01,
          theme: "grid",
          columns: [
            { header: "Tipo Documento", dataKey: "type" },
            { header: "# Documento", dataKey: "number" },
            //{ header: "Orden de Compra", dataKey: "purchase_oder" },
            { header: "Fecha", dataKey: "date" },
            { header: "Vencimiento", dataKey: "date_overdue" },
            { header: "Importe", dataKey: "amount" },
          ],
          body: this.markersh,
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: [0, 0, 0],
            lineColor: [211, 211, 211],
            lineWidth: 0.01,
          },
          bodyStyles: {
            lineColor: [211, 211, 211],
            lineWidth: 0.01,
            fillColor: [211, 211, 211],
          },
          margin: { left: 0.5, top: 1.5, bottom: 0.5 },
        });

        doc.text(20, 30, "This is some normal sized text underneath.");
        doc.addImage(footer, "png", 0.5, 9.8, 7.5, 0.6);
        doc.save("Estado_de_cuenta.pdf");
      });
    },
  },
};
</script>
<style></style>
