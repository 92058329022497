const general = {
  data: () => ({
    VKORG: "IUS2",
    VTWEG: "DT",
    VKBUR: "IU44",
    ORDER_TYPE: "PSIU",
  }),
  methods: {
    getCurrentClient() {
      let customer = JSON.parse(localStorage.getItem("1us4-pv-cl13nt"));

      if (!customer || Object.keys(customer).length <= 0) {
        return null;
      }
      return customer;
    },
  },
};

export default general;
