<template>
  <v-select
    :value="destiny"
    :items="customerDestinyList"
    :item-text="getItemTextDestinations"
    return-object
    dense
    outlined
    label="Destinatario de mercancía"
    @change="setValue($event)"
  >
  </v-select>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import general from "@/plugins/general.js";
import { eventBus } from "@/main.js";

export default {
  name: "CustomerAddress",
  mixins: [general],
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  data: () => ({
    customerDestinyList: [],
    destiny: "",
  }),
  async mounted() {
    this.getDestinyCustomer();
    eventBus.$on("change-client", () => {
      this.getDestinyCustomer();
    });
  },
  methods: {
    getDestinyCustomer() {
      let customer = this.getCurrentClient();
      this.customerDestinyList = [];
      if (!customer) {
        this.$swal({
          title: "Debe seleccionar el cliente.",
          icon: "error",
          type: "error",
        });
        return;
      }
      axios
        .post(`${process.env.VUE_APP_API_IEL_SERVER}/destinyCustomer`, {
          customer: customer.KUNNR,
          VKORG: this.VKORG,
          VTWEG: this.VTWEG,
          VKBUR: this.VKBUR,
        })
        .then((response) => {
          if (typeof response.data == "object") {
            this.customerDestinyList.push(response.data);
          } else {
            this.customerDestinyList = response.data;
          }
          this.destiny = this.customerDestinyList;
          //console.log(this.destiny);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.dialogLoading = false));
    },
    getItemTextDestinations(item) {
      return `${item.KUNNR} ${item.STRAS} ${item.ORT01}  ${item.PSTLZ} `;
    },
    setValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>
<style></style>
