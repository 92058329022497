<template>
  <v-card :loading="loading" class="mx-auto my-12" outlined>
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-radio-group
              v-model="person_type"
              row
              class="justify-center"
              @change="changeTaxRegimens"
            >
              <v-radio label="Personsa Física" value="fisica"></v-radio>
              <v-radio label="Persona Moral" value="moral"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.company_name"
              :rules="[(v) => !!v || 'El campo Razón Social es requerido']"
              :label="person_type == 'fisica' ? 'Nombre' : 'Razón Social'"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.company_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.rfc"
              :rules="[(v) => !!v || 'El campo RFC es requerido']"
              label="RFC"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.rfc"
              :counter="person_type == 'fisica' ? 13 : 12"
              @keydown="checkRFClength($event)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.a_street"
              :rules="[(v) => !!v || 'El campo Calle es requerido']"
              label="Calle"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.a_street"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="form.a_number"
              :counter="10"
              :rules="[(v) => !!v || 'El campo Número es requerido']"
              label="Número"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.a_number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="form.interior_number"
              label="Número"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.interior_number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.a_postal_code"
              :counter="5"
              :rules="[
                (v) =>
                  !!v || 'El campo Código postal es requerido ó no es válido',
              ]"
              label="Código Postal"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.a_postal_code"
              type="number"
            >
              <template v-slot:append-outer>
                <v-btn
                  @click="appendIconCallback"
                  style="top: -8px"
                  color="primary"
                  :disabled="authenticated.type == 3"
                >
                  <v-icon>mdi-crosshairs-gps</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <!-- <v-text-field
              v-model="form.a_estate"
              :rules="[(v) => !!v || 'El campo Estado es requerido']"
              label="Estado"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.a_estate"
            ></v-text-field> -->
            <v-select
              label="Estado"
              required
              outlined
              dense
              v-model="form.state_sap_id"
              :items="states"
              item-text="state"
              item-value="sap_id"
              :readonly="authenticated.type == 3"
              :error-messages="errors.state_sap_id"
              @change="setState"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.a_town"
              :rules="[
                (v) => !!v || 'El campo Delegación o municipio es requerido',
              ]"
              label="Delegación o Municipio"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.a_town"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-combobox
              v-model="form.a_suburb"
              :rules="[(v) => !!v || 'El campo Colonia es requerido']"
              label="Colonia"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.a_suburb"
              :items="suburbs"
            >
              <template v-slot:item="{ item }">
                {{ item }}
                <v-spacer></v-spacer>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.phone"
              :rules="[(v) => !!v || 'El campo Teléfono es requerido']"
              label="Teléfono"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.phone"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.contact_name"
              :rules="[
                (v) => !!v || 'El campo Nombre de contacto es requerido',
              ]"
              label="Nombre de Contacto"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.contact_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.email"
              :rules="emailRules"
              label="Correo Electrónico"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.email"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-actions class="justify-end">
      <v-btn color="success" @click="goToStep2" :disabled="loading">
        Continuar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { eventBus } from "@/main.js";

export default {
  props: ["form", "suburbs", "searchPostalCode", "states", "errors"],

  data: () => ({
    loading: false,
    selection: 1,
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
  }),

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
    }),
    person_type: {
      get() {
        return this.$store.state.customerRequest.form.person_type;
      },
      set(value) {
        this.$store.commit("customerRequest/updatePersonType", value);
      },
    },
  },

  mounted() {
    eventBus.$on("DatosGenerales::validateForm", () => {
      this.validate();
    });
    eventBus.$on("IELAltaCliente::resetForm", () => {
      this.reset();
    });
  },

  methods: {
    ...mapMutations({
      setFormValid: "customerRequest/SET_FORM1_VALID",
    }),
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
    validate() {
      this.loading = true;

      this.setFormValid(this.$refs.form.validate());

      this.loading = false;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    appendIconCallback() {
      if (isNaN(this.form.a_postal_code) || this.form.a_postal_code == "") {
        return this.$swal.fire({
          title: "El código postal no es válido",
          icon: "warning",
        });
      }
      this.searchPostalCode();
    },
    goToStep2() {
      eventBus.$emit("IELAltaCliente::setStep", 2);
    },
    changeTaxRegimens(value) {
      eventBus.$emit("IELAltaCliente::changeTaxRegimens", value);
    },
    checkRFClength(event) {
      let maxRfc = this.person_type == "fisica" ? 13 : 12;
      if (
        event.target.value.length >= maxRfc &&
        event.key != "Backspace" &&
        event.key != "Tab"
      ) {
        return event.preventDefault();
      }
    },
    setState(value) {
      console.log("Estado seleccionado: ", value);
      let state = this.states.find(item => item.sap_id == value);
      console.log(state);
      this.form.a_estate = state.state; 
    }
  },
};
</script>
