<template>
  <v-autocomplete
    v-model="destiny"
    :items="customerDestinyList"
    :item-text="getItemTextDestinations"
    return-object
    dense
    rounded
    label="Destino de Compra"
    style="background-color: #4a74ff !important; color: #fff !important"
  >
  </v-autocomplete>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { eventBus } from "@/main.js";

export default {
  name: "CustomerDestiny",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  props: {
    warnDestiny: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    customerDestinyList: [],
    destiny: "",
    client: "",
  }),
  created() {
    eventBus.$on("change-client", () => {
      this.client = JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR;
      this.getDestinyCustomer();
    });
  },
  async mounted() {
    let self = this;
    if (localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7")) {
      self.client = localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7");
      self.getDestinyCustomer();
    }
  },
  methods: {
    getDestinyCustomer() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/destinyCustomer", {
          customer: this.client,
          VKORG: "IUS2",
          VTWEG: "DT",
          VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
        })
        .then((response) => {
          this.customerDestinyList = response.data;
          this.destiny = this.customerDestinyList;
          //console.log(this.destiny);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.dialogLoading = false));
    },
    getItemTextDestinations(item) {
      return `${item.KUNNR} ${item.STRAS} ${item.ORT01}  ${item.PSTLZ} `;
    },
  },
  watch: {
    destiny: function (val) {
      this.warnDestiny(val);
    },
  },
  destroyed() {
    eventBus.$off("change-client");
  },
};
</script>
<style>
.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  color: white !important;
}
</style>
