<template>
  <v-card :loading="loading" class="mx-auto my-12" outlined>
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-text>
      <v-form ref="documentationForm" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="4">
            <DocumentFileInput
              :idRequest="idRequest"
              :document="documents.facade_photo"
              :form="form"
              label="Foto 1 (Fachada)"
              fileType="image/*"
              :errors="errors"
            ></DocumentFileInput>
          </v-col>
          <v-col cols="12" md="4">
            <DocumentFileInput
              :idRequest="idRequest"
              :document="documents.indoor_photo"
              :form="form"
              label="Foto 2 (Interior)"
              fileType="image/*"
              :errors="errors"
            ></DocumentFileInput>
          </v-col>
          <v-col cols="12" md="4">
            <DocumentFileInput
              :idRequest="idRequest"
              :document="documents.bar_photo"
              :form="form"
              label="Foto 3 (Mostrador/Bodega)"
              fileType="image/*"
              :errors="errors"
            ></DocumentFileInput>
          </v-col>
          <v-col cols="12" md="4">
            <DocumentFileInput
              :idRequest="idRequest"
              :document="documents.evid_tax_registration"
              :form="form"
              label="constancia de situación físcal"
              fileType="application/pdf"
              :errors="errors"
            ></DocumentFileInput>
          </v-col>
          <v-col cols="12" md="4">
            <DocumentFileInput
              :idRequest="idRequest"
              :document="documents.official_id"
              :form="form"
              label="identificación oficial de persona fisica o representante legal"
              fileType="application/pdf"
              :errors="errors"
            ></DocumentFileInput>
          </v-col>
          <v-col cols="12" md="4">
            <DocumentFileInput
              :idRequest="idRequest"
              :document="documents.proof_recidency"
              :form="form"
              label="comprobante de domicilio dirección fiscal"
              fileType="application/pdf"
              :errors="errors"
            ></DocumentFileInput>
          </v-col>
          <v-col cols="12" md="4" v-show="showCreditBureau">
            <DocumentFileInput
              :idRequest="idRequest"
              :document="documents.credit_bureau"
              :form="form"
              label="formato de buró de crédito"
              fileType="application/pdf"
              :errors="errors"
            ></DocumentFileInput>
          </v-col>
          <v-col cols="12" md="4" v-show="showAddresses">
            <DocumentFileInput
              :idRequest="idRequest"
              :document="documents.shcp_opening"
              :form="form"
              label="formato de alta de apertura ante SAT"
              fileType="application/pdf"
              :errors="errors"
            ></DocumentFileInput>
          </v-col>
          <v-col cols="12" md="4" v-show="showCharter">
            <DocumentFileInput
              :idRequest="idRequest"
              :document="documents.charter"
              :form="form"
              label="acta constitutiva"
              fileType="application/pdf"
              :errors="errors"
            ></DocumentFileInput>
          </v-col>
          <v-col cols="12" md="4" v-show="showPowerAttorney">
            <DocumentFileInput
              :idRequest="idRequest"
              :document="documents.power_attorney"
              :form="form"
              label="Documento del poder notarial"
              fileType="application/pdf"
              :errors="errors"
            ></DocumentFileInput>
          </v-col>
          <v-col cols="12" md="4" v-show="showPromissoryNote">
            <DocumentFileInput
              :idRequest="idRequest"
              :document="documents.promissory_note"
              :form="form"
              label="Pagaré"
              fileType="application/pdf"
              :errors="errors"
            ></DocumentFileInput>
          </v-col>
          <v-col cols="12" md="4" v-show="showOfficalIdEndors">
            <DocumentFileInput
              :idRequest="idRequest"
              :document="documents.official_id_endors"
              :form="form"
              label="Identificación oficial de aval"
              fileType="application/pdf"
              :errors="errors"
            ></DocumentFileInput>
          </v-col>
          <v-col cols="12" md="4" v-show="showProofRecidencyEndors">
            <DocumentFileInput
              :idRequest="idRequest"
              :document="documents.proof_recidency_endors"
              :form="form"
              label="comprobante de domicilio de aval"
              fileType="application/pdf"
              :errors="errors"
            ></DocumentFileInput>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <v-checkbox
              class="mr-5"
              v-model="form.regulation_data_protect"
              :label="`Aviso de Protección de Datos`"
              required
              :rules="[
                (v) => !!v || 'Debe aceptar el Aviso de Protección de Datos',
              ]"
              :readonly="!openedRegulationDataDialog"
              @click="openRegulationDataProtect($event)"
              :errors="errors.regulation_data_protect"
            ></v-checkbox>
            <v-checkbox
              v-model="form.credit_policies"
              :label="`Políticas de Crédito Nacionales`"
              required
              :rules="[
                (v) =>
                  !!v || 'Debe aceptar las Políticas de Crédito Nacionales',
              ]"
              :readonly="!openedCreditPoliciesDialog"
              @click="openPoliticalDialog"
              :errors="errors.credit_policies"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
      <v-list subheader v-if="Object.keys(errors).length > 0">
        <p class="red--text text-h5">
          Por favor revisa los siguientes errores de validación
        </p>
        <v-list-item v-for="(error, index) in errors" :key="'error-' + index">
          <v-list-item-content>
            <v-list-item-title>{{ error }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-end">
      <v-btn @click="back">Regresar</v-btn>
      <v-btn
        color="success"
        @click="validate"
        :disabled="authenticated.type == 3"
      >
        Guardar y enviar a revisión
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="showRegulationDataProtectectDialog" width="50%">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Aviso de Protección de Datos
        </v-card-title>

        <v-card-text>
          <iframe
            id="fred"
            style="border: 1px solid #666ccc"
            title="PDF in an i-Frame"
            :src="pdf_file"
            frameborder="1"
            scrolling="auto"
            width="100%"
            height="600px"
          ></iframe>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelRegulationData"
            >No acepto</v-btn
          >
          <v-btn color="primary" @click="acceptRegulationDataCheckbox">
            Acepto
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showPoliticalDialog" width="50%">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Políticas de Crédito Nacionales
        </v-card-title>

        <v-card-text>
          <iframe
            id="fred"
            style="border: 1px solid #666ccc"
            title="PDF in an i-Frame"
            :src="political_pdf"
            frameborder="1"
            scrolling="auto"
            width="100%"
            height="600px"
          ></iframe>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelCreditPolicies"
            >No acepto</v-btn
          >
          <v-btn color="primary" @click="acceptPoliticalCheckbox">
            Acepto
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/main.js";
import DocumentFileInput from "@/components/iel/alta/DocumentFileInput.vue";

export default {
  props: [
    "form",
    "sendForm",
    "listDocuments",
    "idRequest",
    "errors",
    "loading",
    "sentManager",
  ],
  name: "DocumentationRequested",

  components: { DocumentFileInput },

  data: () => ({
    selection: 1,
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
    cfdiuses: [],
    documents: {
      facade_photo: {
        key: "facade_photo",
        edit: false,
        value: "facade_photo.png",
      },
      indoor_photo: {
        key: "indoor_photo",
        edit: false,
        value: "indoor_photo.jpg",
      },
      bar_photo: {
        key: "bar_photo",
        edit: false,
        value: "bar_photo.jpg",
      },
      evid_tax_registration: {
        key: "evid_tax_registration",
        edit: false,
        value: "evid_tax_registration.pdf",
      },
      official_id: {
        key: "official_id",
        edit: false,
        value: "official_id.pdf",
      },
      proof_recidency: {
        key: "proof_recidency",
        edit: false,
        value: "proof_recidency.pdf",
      },
      proof_recidency_distinct: {
        key: "proof_recidency_distinct",
        edit: false,
        value: "proof_recidency_distinct.pdf",
      },
      credit_bureau: {
        key: "credit_bureau",
        edit: false,
        value: "credit_bureau.pdf",
      },
      shcp_opening: {
        key: "shcp_opening",
        edit: false,
        value: "shcp_opening.pdf",
      },
      charter: {
        key: "charter",
        edit: false,
        value: "charter.pdf",
      },
      power_attorney: {
        key: "power_attorney",
        edit: false,
        value: "power_attorney.pdf",
      },
      promissory_note: {
        key: "promissory_note",
        edit: false,
        value: "promissory_note.pdf",
      },
      proof_recidency_endors: {
        key: "proof_recidency_endors",
        edit: false,
        value: "proof_recidency_endors.pdf",
      },
      official_id_endors: {
        key: "official_id_endors",
        edit: false,
        value: "official_id_endors.pdf",
      },
      bank_reference: {
        key: "bank_reference",
        edit: false,
        value: "bank_reference.pdf",
      },
    },
    showedPolitical: false,
    showPoliticalDialog: false,
    showedRegulationDataProtect: false,
    showRegulationDataProtectectDialog: false,
    openedRegulationDataDialog: false,
    openedCreditPoliciesDialog: false,
    pdf_file: "https://dev-api.iusaenlinea.com/storage/aviso-de-privacidad.pdf",
    political_pdf:
      "https://dev-api.iusaenlinea.com/storage/politica-de-ventas.pdf",
  }),

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      isRequestingCredit: "customerRequest/isRequestingCredit",
      lineRequestCredit: "customerRequest/lineRequestCredit",
      isMoralPerson: "customerRequest/isMoralPerson",
      showAddresses: "customerRequest/showAddresses",
    }),
    showCharter() {
      return this.isMoralPerson;
    },
    showPowerAttorney() {
      return this.isMoralPerson;
    },
    showCreditBureau() {
      return this.isRequestingCredit;
    },
    showPromissoryNote() {
      return this.isRequestingCredit;
    },
    showOfficalIdEndors() {
      return this.isRequestingCredit && this.lineRequestCredit > 50000;
    },
    showProofRecidencyEndors() {
      return this.isRequestingCredit && this.lineRequestCredit > 50000;
    },
    existerrors() {
      return this.errors.length > 0;
    },
  },

  mounted() {
    eventBus.$on("DocumentationRequested::validateForm", () => {
      this.validate();
    });

    eventBus.$on("IELAltaCliente::resetForm", () => {
      this.reset();
    });

    eventBus.$on("IELAltaCliente::changeDocument", (document) => {
      console.log("document", document);
      // console.log(this.documents[`official_id_endors`]);
      // this.documents[document.key].value = document.value;
    });
  },

  methods: {
    back() {
      eventBus.$emit("IELAltaCliente::setStep", 2);
    },
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
    validate() {
      this.sendForm();
    },
    reset() {
      this.$refs.documentationForm.reset();
    },
    resetValidation() {
      this.$refs.documentationForm.resetValidation();
    },

    openRegulationDataProtect(event) {
      if (!this.showedRegulationDataProtect) {
        this.showRegulationDataProtectectDialog = true;
        this.openedRegulationDataDialog = true;
        event.preventDefault();
      }
    },
    openPoliticalDialog(event) {
      if (!this.showedPolitical) {
        this.showPoliticalDialog = true;
        this.openedCreditPoliciesDialog = true;
        event.preventDefault();
      }
    },
    cancelRegulationData() {
      this.form.regulation_data_protect = false;
      this.showRegulationDataProtectectDialog = false;
    },
    cancelCreditPolicies() {
      this.form.credit_policies = false;
      this.showPoliticalDialog = false;
    },
    acceptRegulationDataCheckbox() {
      this.showRegulationDataProtectectDialog = false;
      this.form.regulation_data_protect = true;
    },
    acceptPoliticalCheckbox() {
      this.showPoliticalDialog = false;
      this.form.credit_policies = true;
    },
  },
};
</script>
