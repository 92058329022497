var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid grey lighten-5 pt-4"},[(_vm.hasError)?_c('div',{staticClass:"alert alert-danger",staticStyle:{"font-weight":"500"},attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")]):_vm._e(),_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"top":"","color":"deep-purple accent-4"}}),_c('br'),_c('v-card',{staticClass:"mx-auto rounded-lg elevation-4 mb-4",style:('border: 4px solid red;'),attrs:{"outlined":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-niv",staticStyle:{"text-align":"center"}},[_c('v-icon',{staticStyle:{"color":"red !important"}},[_vm._v("mdi-calendar-check")]),_vm._v(" NIVELES DE SERVICIO ")],1)])],1),(_vm.dataFillRate.length > 0)?_c('v-card',[_c('div',{staticClass:"card-body text-lg-center"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_fill_rate,"items":_vm.dataFillRate,"items-per-page":10,"item-key":"DESCR","locale":"esp","hide-default-footer":"","footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
        }}})],1)]):_vm._e(),_c('v-progress-linear',{attrs:{"active":_vm.loadingOrderDeliveryTime,"indeterminate":_vm.loadingOrderDeliveryTime,"top":"","color":"deep-purple accent-4"}}),_c('br'),_c('v-card',{staticClass:"mx-auto rounded-lg elevation-4 mb-4",style:('border: 4px solid red;'),attrs:{"outlined":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-niv",staticStyle:{"text-align":"center"}},[_c('v-icon',{staticStyle:{"color":"red !important"}},[_vm._v("mdi-calendar-check")]),_vm._v(" TIEMPO DE ENTREGA DE PEDIDOS EN LOS ÚLTIMOS 3 MESES ")],1)])],1),(_vm.dataOrderDeliveryTime.length > 0)?_c('v-card',[_c('div',{staticClass:"card-body text-lg-center"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_order_delivery_time,"items":_vm.dataOrderDeliveryTime,"items-per-page":10,"item-key":"DESCR","locale":"esp","hide-default-footer":"","footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
        }}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }