<template>
  <div>
    <div
      v-if="
        idRequest != null &&
        idRequest != undefined &&
        document &&
        document.edit == false &&
        document.value != undefined &&
        form.document && form.document[document.key]
      "
      class="d-flex justify-space-between align-center"
    >
      <div>
        <span>{{ capitalize(label) }}</span
        ><br />
        <a
          class="h6"
          target="_blank"
          :href="`${urlBaseIEL}/${document.value}`"
          >{{ getDocumentName(form.document[document.key]) }}</a
        >
      </div>
      <v-btn
        color="success"
        icon
        small
        @click="document.edit = true"
        :disabled="authenticated.type == 3"
      >
        <v-icon>mdi-upload</v-icon>
      </v-btn>
    </div>
    <div v-else>
      <v-file-input
        v-model="form[document.key]"
        :rules="[
          (v) => !!v || `El campo label es requerido`,
          (v) => (v && v.size > 0) || `El campo label es requerido`,
        ]"
        :label="label"
        required
        outlined
        dense
        :accept="fileType"
        :error-messages="errors[document.key]"
      ></v-file-input>
      <span
        >Solo se aceptan
        <b>{{ fileType == "application/pdf" ? "archivos PDF" : "imágenes" }}</b>
        <v-icon>{{
          fileType == "application/pdf" ? "mdi-file-pdf-box" : "mdi-image"
        }}</v-icon>
      </span>
    </div>
    <v-btn
      x-small
      color="red"
      style="color: #fff"
      v-if="document.edit == true"
      @click="document.edit = false"
      >Cancelar</v-btn
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DocumentFileInput",

  props: ["idRequest", "form", "document", "label", "fileType", "errors"],

  data() {
    return {
      urlBaseIEL: process.env.VUE_APP_BASE_IEL_SERVER,
    };
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
    }),
  },

  methods: {
    getDocumentName(path) {
      if (!path) return "";

      let arrayPath = path.split("/");

      return arrayPath[arrayPath.length - 1];
    },
    capitalize(message) {
      return message.charAt(0).toUpperCase() + message.slice(1);
    },
  },
};
</script>
