<template>
  <div>
    <div class="particles-js" id="particles-js"></div>
    <v-app-bar
      color="#FF000A"
      dark
      v-if="authenticated && authenticated.type == '1'"
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-img
          style="width: 100%; height: 100%"
          src="img/logo_iusa_w.svg"
        ></v-img>
      </v-toolbar-title>
      <v-toolbar-title style="margin-left: 15px; margin-top: 10px">
        <h6 style="font-size: 22px">Portal Vendedor</h6>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-badge bordered bottom color="green" dot offset-x="10" offset-y="10">
        <router-link :to="{ name: 'perfil' }" style="text-decoration: none">
          <v-avatar
            size="40"
            v-if="user.image_perfil == null"
            style="border: solid 2px #fff"
          >
            <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
          </v-avatar>
          <v-avatar
            size="40"
            v-if="user.image_perfil != null"
            style="border: solid 2px #fff"
          >
            <v-img :src="$hostImage + '/' + user.image_perfil"></v-img>
          </v-avatar>
        </router-link>
      </v-badge>
    </v-app-bar>
    <v-toolbar
      v-if="authenticated && authenticated.type == '1'"
      flat
      style="background-color: red; border-top: 1px white solid"
      class="d-none d-lg-flex justify-start"
      height="50%;"
      elevation="5"
    >
      <div style="color: #fff; cursor: pointer">
        <v-btn color="transparent" elevation="0" @click="goTo('MiCartera')">
          <v-icon class="mr-1" color="white">mdi-calendar</v-icon>
          <label class="text-white">Mi Cartera</label>
        </v-btn>
      </div>

      <div style="color: #fff; cursor: pointer">
        <v-btn
          color="transparent"
          elevation="0"
          @click="goTo('iel-solicitudes')"
        >
          <v-icon class="mr-1" color="white">mdi-account</v-icon>
          <label class="text-white">IEL Alta Cliente</label>
        </v-btn>
      </div>

      <div style="color: #fff; cursor: pointer">
        <v-btn color="transparent" elevation="0" @click="goTo('ShoppingCart')">
          <v-icon class="mr-1" color="white">mdi-cart</v-icon>
          <label class="text-white">Carrito de Compra</label>
        </v-btn>
      </div>

      <div style="color: #fff; cursor: pointer">
        <v-btn color="transparent" elevation="0" @click="goTo('Planeador')">
          <v-icon class="mr-1" color="white">mdi-calendar</v-icon>
          <label class="text-white">Planeador</label>
        </v-btn>
      </div>
    </v-toolbar>
    <v-autocomplete
      v-if="authenticated && visibleClient"
      filled
      solo
      label="BUSCAR CLIENTE"
      :items="clients"
      item-value="clients"
      :item-text="getItemText"
      return-object
      v-model="client"
      @change="setClient(client)"
      chips
      style="margin-bottom: -27px"
    >
    </v-autocomplete>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      v-if="authenticated && authenticated.type == '1'"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img
            v-if="user.image_perfil == null"
            src="https://cdn.vuetifyjs.com/images/lists/2.jpg"
          ></v-img>
          <v-img
            v-if="user.image_perfil != null"
            :src="$hostImage + '/' + user.image_perfil"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content style="padding-top: 28px">
          <v-list-item-title>{{ user.name }}</v-list-item-title>
          <p>{{ user.email }}</p>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <router-link
            :to="{ name: 'dashboard' }"
            style="text-decoration: none"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link :to="{ name: 'perfil' }" style="text-decoration: none">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Perfil</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link
            :to="{ name: 'opportunities' }"
            style="text-decoration: none"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Oportunidades</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link :to="{ name: 'routes' }" style="text-decoration: none">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-map-marker-distance</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Mis Rutas</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link
            :to="{ name: 'prospects' }"
            style="text-decoration: none"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Prospectos</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link
            :to="{ name: 'Planeador' }"
            style="text-decoration: none"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Planeador</v-list-item-title>
            </v-list-item>
          </router-link>
          <a
            href="catalogo/catalogo-de-construccion.pdf"
            target="_blank"
            style="font-size: 14px; text-decoration: none"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-file-find</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Cátalogo</v-list-item-title>
            </v-list-item>
          </a>
          <a @click="goTo('MiCartera')" style="text-decoration: none">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Mi Cartera</v-list-item-title>
            </v-list-item>
          </a>
          <v-list-group :value="false" prepend-icon="mdi-clipboard-list">
            <template v-slot:activator>
              <v-list-item-title>Facturación</v-list-item-title>
            </template>
            <router-link
              :to="{ name: 'QuoterHistory' }"
              style="text-decoration: none"
            >
              <v-list-item>
                <v-list-item-title>Cotización</v-list-item-title>
              </v-list-item>
            </router-link>
            <!-- <router-link
              :to="{ name: 'pedidoExporadico' }"
              style="text-decoration: none"
            >
              <v-list-item>
                <v-list-item-title>Crear Pedido Esporadico</v-list-item-title>
              </v-list-item>
            </router-link>-->

            <a @click="goTo('ShoppingCart')" style="text-decoration: none">
              <v-list-item>
                <v-list-item-title>Crear Pedido Clientes</v-list-item-title>
              </v-list-item>
            </a>
            <router-link
              :to="{ name: 'OrderView' }"
              style="text-decoration: none"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-file-delimited-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Pedido CSV</v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link
              :to="{ name: 'HistorialPedidos' }"
              style="text-decoration: none"
            >
              <v-list-item>
                <v-list-item-title>Historial Pedidos</v-list-item-title>
              </v-list-item>
            </router-link>
            <router-link
              :to="{ name: 'DiscontinuedMaterial' }"
              style="text-decoration: none"
            >
              <v-list-item>
                <v-list-item-title>Materiales Descontinuados</v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-group>

          <router-link :to="{ name: 'agenda' }" style="text-decoration: none">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Agenda</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link
            :to="{ name: 'iel-solicitudes' }"
            style="text-decoration: none"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>IEL Alta Cliente</v-list-item-title>
            </v-list-item>
          </router-link>
          <v-divider></v-divider>
          <v-list-item @click.prevent="signOut">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<style></style>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main.js";

export default {
  data: () => ({
    drawer: false,
    group: null,
    client: {},
    clients: [],
    clientCurrent: false,
    visibleClient: false,
  }),
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  async beforeUpdate() {
    this.validateVisibleClient();
  },
  async mounted() {
    this.getClient();
    this.validateVisibleClient();
    if (localStorage.getItem("1us4-pv-cl13nt")) {
      this.client = JSON.parse(localStorage.getItem("1us4-pv-cl13nt"));
    }
  },
  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),
    signOut() {
      this.signOutAction().then(() => {
        this.$router.replace({
          name: "signin",
        });
      });
    },
    getClient() {
      if (this.user) {
        axios
          .get("client/list/" + this.user.employee_number)
          .then((response) => {
            this.clients = response.data;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally();
      }
    },
    setClient(client) {
      localStorage.setItem("1us4-pv-cl13nt", JSON.stringify(client));
      localStorage.setItem("1us4-p0rt4l-v3nd3nd0r-cl13n7", client.KUNNR);
      //localStorage.setItem('id-client', )
      console.info("setClient() => ", this.client);
      eventBus.$emit("change-client", this.client);
      window.location.reload();
    },
    getItemText(item) {
      return `${item.KUNNR} - ${item.NAME1}`;
    },
    validateVisibleClient() {
      //console.info( "router => ", this.$route.name );debugger;
      if (
        this.$route.name == "ShoppingCart" ||
        this.$route.name == "HistorialPedidos" ||
        this.$route.name == "MiCartera" ||
        this.$route.name == "QuoterHistory" ||
        this.$route.name == "QuoterCart" ||
        this.$route.name == "OrderView" ||
        this.$route.name == "DiscontinuedMaterial" ||
        this.$route.name == "ProfileUser"
      ) {
        if (this.clients.length == 0) {
          this.getClient();
        }
        this.visibleClient = true;
      } else {
        this.visibleClient = false;
      }
    },
    goTo(routeName) {
      this.$router.push({ name: routeName });

      if (
        routeName == "ShoppingCart" ||
        routeName == "HistorialPedidos" ||
        routeName == "MiCartera" ||
        routeName == "QuoterHistory" ||
        routeName == "QuoterCart" ||
        routeName == "DiscontinuedMaterial" ||
        routeName == "ProfileUser"
      ) {
        window.location.reload();
        if (this.clients.length == 0) {
          this.getClient();
        }
        this.visibleClient = true;
      } else {
        this.visibleClient = false;
      }
    },
  },
};
</script>
