<template>
  <v-container fluid>
    <div class="row">
      <div class="col-12">
        <h3>Indicadores de Prospección</h3>
        <v-divider></v-divider>
      </div>
      <v-col cols="4" md="4" class="same-height-cols">
        <v-card>
          <v-card-title class="subheading font-weight-bold">
            Tasa de Oportunidad
          </v-card-title>
          <v-card-subtitle>
            Asesor, ¿Este negocio puede ser atendido de manera directa o por un
            distribuidor?
          </v-card-subtitle>
          <v-card-text>
            <v-divider></v-divider>
            <doughnut-chart
              :chartData="chartData"
              height="300px"
            ></doughnut-chart>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th>Resultado</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in data" :key="key">
                  <td>{{ key }}</td>
                  <th>{{ item }}</th>
                  <th>{{ roundedPercentage((item * 100) / total) }}%</th>
                </tr>
                <tr>
                  <td>Total</td>
                  <th>{{ total }}</th>
                  <th>100%</th>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" md="4" class="same-height-cols">
        <v-card>
          <v-card-title class="subheading font-weight-bold">
            Prospectos | De manera directa
          </v-card-title>
          <v-card-subtitle>
            ¿Cuenta con cédula fiscal vigente?
          </v-card-subtitle>
          <v-card-text>
            <v-divider></v-divider>
            <pie-chart :chartData="chartDataCF" height="300px"></pie-chart>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th>Resultado</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in dataCF" :key="key">
                  <td>{{ key }}</td>
                  <th>{{ item }}</th>
                  <th>{{ roundedPercentage((item * 100) / totalCF) }}%</th>
                </tr>
                <tr>
                  <td>Total</td>
                  <th>{{ totalCF }}</th>
                  <th>100%</th>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" md="4" class="text-center">
        <v-card>
          <v-card-title class="subheading font-weight-bold">
            Oportunidad de Negocio
          </v-card-title>
          <v-card-text class="mt-n2">
            <v-icon style="font-size: 95px; color: #0077b5"
              >mdi-handshake-outline</v-icon
            >
            <h1 style="color: #0179a8 !important; font-weigh: 400">
              {{ interestedProspects }}
            </h1>
            <p style="font-size: 16px; color: #99abb4">
              Interesados en ser atendidos por un asesor
            </p>
          </v-card-text>
        </v-card>
        <v-card class="mt-5">
          <v-card-title
            class="subheading font-weight-bold text-center"
            style="background: #0077b5; color: #fff"
          >
            Prospectos | Líneas de Crédito &nbsp;<v-icon
              style="font-size: 35px; color: #fff"
              >mdi-credit-card-search-outline</v-icon
            >
          </v-card-title>
          <v-card-text class="mt-5">
            <v-row>
              <v-col cols="6">
                <p
                  style="
                    font-weight: 600;
                    color: #00a28a !important;
                    font-size: 26px;
                  "
                >
                  {{ countCreditLines.Si }}
                </p>
                <p style="font-size: 16px; color: #99abb4">Si</p>
              </v-col>
              <v-col cols="6">
                <p style="font-weight: 600; color: #455a64; font-size: 22px">
                  {{ countCreditLines.No }}
                </p>
                <p style="font-size: 16px; color: #99abb4">No</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" md="4" class="same-height-cols">
        <v-card>
          <v-card-title
            class="subheading font-weight-bold"
            style="color: rgb(0, 162, 138) !important"
          >
            Rangos de Crédito
          </v-card-title>
          <v-card-subtitle style="text-align: justify">
            Tomando en cuenta a su principal distribuidor ferretero,
            ¿Aproximadamente cuál es rango de línea de crédito que le otorga?
          </v-card-subtitle>
          <v-card-text>
            <v-divider></v-divider>
            <!-- <bar-chart :chartData="chartDataCR" height="300px"></bar-chart> -->
            <v-simple-table>
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th>Resultado</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, key) in countProspectsCreditRanges"
                  :key="key"
                >
                  <td>{{ key }}</td>
                  <th>{{ item }}</th>
                  <th>
                    {{ roundedPercentage((item * 100) / totalCreditRanges) }}%
                  </th>
                </tr>
                <tr>
                  <td style="font-weight: bold">Total</td>
                  <th>{{ totalCreditRanges }}</th>
                  <th>100%</th>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" md="4" class="same-height-cols">
        <v-card>
          <v-card-title
            class="subheading font-weight-bold"
            style="color: rgb(0, 162, 138) !important"
          >
            Plazos de Crédito
          </v-card-title>
          <v-card-subtitle style="text-align: justify">
            ¿Cúantos días de plazo le otorga su principal distribuidor
            ferretero?
          </v-card-subtitle>
          <v-card-text>
            <v-divider></v-divider>
            <!-- <bar-chart :chartData="chartDataCR" height="300px"></bar-chart> -->
            <v-simple-table>
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th>Resultado</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in countCreditTerms" :key="key">
                  <td>{{ key }}</td>
                  <th>{{ item }}</th>
                  <th>
                    {{ roundedPercentage((item * 100) / totalCreditTerms) }}%
                  </th>
                </tr>
                <tr>
                  <td style="font-weight: bold">Total</td>
                  <th>{{ totalCreditTerms }}</th>
                  <th>100%</th>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" md="4">
        <v-card>
          <v-card-title
            class="subheading font-weight-bold"
            style="color: rgb(0, 162, 138) !important"
          >
            Años Funcionando
          </v-card-title>
          <v-card-subtitle style="text-align: justify">
            ¿Cuántos años tiene este negocio funcionando?
          </v-card-subtitle>
          <v-card-text>
            <v-divider></v-divider>
            <!-- <bar-chart :chartData="chartDataCR" height="300px"></bar-chart> -->
            <v-simple-table>
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th>Resultado</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in countYearsOfOperation" :key="key">
                  <td>{{ key }}</td>
                  <th>{{ item }}</th>
                  <th>
                    {{
                      roundedPercentage((item * 100) / totalYearsOfOperation)
                    }}%
                  </th>
                </tr>
                <tr>
                  <td style="font-weight: bold">Total</td>
                  <th>{{ totalYearsOfOperation }}</th>
                  <th>100%</th>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </div>
  </v-container>
</template>

<script>
import DoughnutChart from "./components/Doughnut.vue";
import PieChart from "./components/PieChart";
//import BarChart from "./components/BarChart";
import axios from "axios";
export default {
  components: {
    DoughnutChart,
    PieChart,
    //BarChart,
  },
  data: () => ({
    data: null,
    dataCF: null,
    dataCR: null,
    interestedProspects: null,
    countCreditLines: null,
    countProspectsCreditRanges: null,
    countCreditTerms: null,
    countYearsOfOperation: null,
    chartData: {},
    chartDataCF: {},
    chartDataCR: {},
    chartDataCT: {},
    chartDataYO: {},
    total: 0,
    totalCF: 0,
    totalCreditRanges: 0,
    totalCreditTerms: 0,
    totalYearsOfOperation: 0,
  }),
  async created() {
    this.getProspectsViable();
    this.getProspectsCF();
    this.countInterestedProspects();
    this.getCreditLines();
    this.getProspectsCreditRanges();
    this.getCreditTerms();
    this.getYearsOfOperation();
  },
  methods: {
    async getProspectsViable() {
      await axios
        .post("getProspectsViable")
        .then((response) => {
          this.data = response.data;
          let labels = Object.keys(this.data);
          let array = Object.values(this.data);
          let count = 0;
          for (let i = 0; i < array.length; i++) {
            count = count += array[i];
          }
          this.total = count;
          this.chartData = {
            labels: labels,
            datasets: [
              {
                borderWidth: 1,
                borderColor: [
                  "rgba(255,99,132,1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                ],
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                ],
                data: labels.map((label) => this.data[label]),
              },
            ],
          };
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => (this.loading = false));
    },
    async getProspectsCF() {
      await axios
        .post("getProspectsCF")
        .then((response) => {
          this.dataCF = response.data;
          let labels = Object.keys(this.dataCF);
          let array = Object.values(this.dataCF);
          let count = 0;
          for (let i = 0; i < array.length; i++) {
            count = count += array[i];
          }
          this.totalCF = count;
          this.chartDataCF = {
            labels: labels,
            datasets: [
              {
                borderWidth: 1,
                borderColor: [
                  "rgba(255,99,132,1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                ],
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                ],
                data: labels.map((label) => this.dataCF[label]),
              },
            ],
          };
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => (this.loading = false));
    },
    async countInterestedProspects() {
      await axios
        .post("countInterestedProspects")
        .then((response) => {
          this.interestedProspects = response.data;
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => (this.loading = false));
    },
    async getCreditLines() {
      await axios
        .post("getCreditLines")
        .then((response) => {
          this.countCreditLines = response.data;
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => (this.loading = false));
    },
    async getProspectsCreditRanges() {
      await axios
        .post("getProspectsCreditRanges")
        .then((response) => {
          this.countProspectsCreditRanges = response.data;
          let labels = Object.keys(this.countProspectsCreditRanges);
          let array = Object.values(this.countProspectsCreditRanges);
          let count = 0;
          for (let i = 0; i < array.length; i++) {
            count = count += array[i];
          }
          this.totalCreditRanges = count;
          this.chartDataCR = {
            labels: labels,
            datasets: [
              {
                borderWidth: 1,
                borderColor: [
                  "rgba(255,99,132,1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(255,99,132,1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                ],
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                ],
                data: labels.map(
                  (label) => this.countProspectsCreditRanges[label]
                ),
              },
            ],
          };
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => (this.loading = false));
    },
    async getCreditTerms() {
      await axios
        .post("getCreditTerms")
        .then((response) => {
          this.countCreditTerms = response.data;
          let labels = Object.keys(this.countCreditTerms);
          let array = Object.values(this.countCreditTerms);
          let count = 0;
          for (let i = 0; i < array.length; i++) {
            count = count += array[i];
          }
          this.totalCreditTerms = count;
          this.chartDataCT = {
            labels: labels,
            datasets: [
              {
                borderWidth: 1,
                borderColor: [
                  "rgba(255,99,132,1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(255,99,132,1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                ],
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                ],
                data: labels.map((label) => this.countCreditTerms[label]),
              },
            ],
          };
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => (this.loading = false));
    },
    async getYearsOfOperation() {
      await axios
        .post("getYearsOfOperation")
        .then((response) => {
          this.countYearsOfOperation = response.data;
          let labels = Object.keys(this.countYearsOfOperation);
          let array = Object.values(this.countYearsOfOperation);
          let count = 0;
          for (let i = 0; i < array.length; i++) {
            count = count += array[i];
          }
          this.totalYearsOfOperation = count;
          this.chartDataYO = {
            labels: labels,
            datasets: [
              {
                borderWidth: 1,
                borderColor: [
                  "rgba(255,99,132,1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(255,99,132,1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                ],
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                ],
                data: labels.map((label) => this.countYearsOfOperation[label]),
              },
            ],
          };
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => (this.loading = false));
    },
    roundedPercentage(value) {
      return value.toFixed(0);
    },
  },
};
</script>

<style scoped>
.same-height-cols {
  display: flex;
}

.same-height-cols .v-col {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.same-height-cols .v-col > * {
  flex: 1;
}
</style>