<template>
  <v-timeline>
    <v-timeline-item
      v-for="observation in observations"
      :key="'obs-' + observation.id"
    >
      {{ observation.observations }} <br />
      {{ observation.created_at }}
    </v-timeline-item>
  </v-timeline>
</template>

<script>
export default {
  props: ["observations"],
  data() {
    return {};
  },
};
</script>
