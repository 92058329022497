<template>
  <v-card :loading="loading" class="mx-auto my-12" outlined>
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-text>
      <v-form ref="fiscalForm" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="form.fiscal_regimen"
              :items="tax_regimes"
              outlined
              dense
              label="Selecciona el regimen fiscal"
              item-text="label"
              item-value="code"
              :error-messages="errors.fiscal_regimen"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="form.payment_method"
              :items="payment_methods"
              outlined
              dense
              label="Método de Pago"
              item-text="method"
              item-value="code"
              :rules="[(v) => !!v || 'El campo método de pago es requerido']"
              :readonly="authenticated.type == 3"
              :error-messages="errors.payment_method"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="form.cfdi_use"
              :items="cfdi_uses"
              outlined
              dense
              label="Uso de CFDI"
              item-text="label"
              item-value="id"
              :rules="[(v) => !!v || 'El campo uso de CFDI es requerido']"
              :readonly="authenticated.type == 3"
              :error-messages="errors.cfdi_use"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="form.way_pay_id"
              :items="way_pays"
              outlined
              dense
              label="Forma de pago"
              item-text="label"
              item-value="id"
              :rules="[(v) => !!v || 'El campo forma de pago es requerido']"
              :readonly="authenticated.type == 3"
              :error-messages="errors.way_pay_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="request_credit"
              :rules="[
                (v) =>
                  !!v || 'El campo solicitud de línea de crédito es requerido',
              ]"
              label="¿Solicitas línea de crédito?"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :items="[
                { value: 'Si', text: 'Sí' },
                { value: 'No', text: 'No' },
              ]"
              :error-messages="errors.request_credit"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4" v-show="showLineRequestCredit">
            <v-select
              v-model="line_request_credit"
              :rules="[
                (v) =>
                  !!v || 'El campo línea de crédito solicitada es requerido',
              ]"
              label="Línea de Crédito Solicitada"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :items="[
                { value: '20000', text: '$ 20,000' },
                { value: '25000', text: '$ 25,000' },
                { value: '30000', text: '$ 30,000' },
                { value: '35000', text: '$ 35,000' },
                { value: '40000', text: '$ 40,000' },
                { value: '45000', text: '$ 45,000' },
                { value: '50000', text: '$ 50,000' },
                { value: '55000', text: '$ 55,000' },
                { value: '60000', text: '$ 60,000' },
                { value: '65000', text: '$ 65,000' },
                { value: '70000', text: '$ 70,000' },
                { value: '75000', text: '$ 75,000' },
                { value: '80000', text: '$ 80,000' },
                { value: '85000', text: '$ 85,000' },
                { value: '90000', text: '$ 90,000' },
                { value: '95000', text: '$ 95,000' },
                { value: '100000', text: '$ 100,000' },
                { value: '125000', text: '$ 125,000' },
                { value: '150000', text: '$ 150,000' },
                { value: '175000', text: '$ 175,000' },
                { value: '200000', text: '$ 200,000' },
                { value: '225000', text: '$ 225,000' },
                { value: '250000', text: '$ 250,000' },
                { value: '275000', text: '$ 275,000' },
                { value: '300000', text: '$ 300,000' },
                { value: '325000', text: '$ 325,000' },
                { value: '350000', text: '$ 350,000' },
                { value: '375000', text: '$ 375,000' },
                { value: '400000', text: '$ 400,000' },
              ]"
              :error-messages="errors.line_request_credit"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.fiscal_rep_name"
              :rules="[
                (v) =>
                  !!v || 'El campo nombre de representante legal es requerido',
              ]"
              label="Nombre de Representante Legal"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.fiscal_rep_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <text-input-money
              label="Ventas Mensuales del Negocio"
              v-model="form.month_sales"
              :type="authenticated.type"
              :errors="errors.month_sales"
            ></text-input-money>
          </v-col>
          <v-col cols="12" md="4">
            <text-input-money
              label="Tamaño del Negocio (m2)"
              v-model="form.business_size"
              :type="authenticated.type"
              :errors="errors.business_size"
            ></text-input-money>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.line_bussines"
              :rules="[(v) => !!v || 'El campo giro del negocio es requerido']"
              label="Giro del negocio"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :error-messages="errors.line_bussines"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="form.own_facilities"
              :rules="[
                (v) => !!v || 'El campo instalaciones propias es requerido',
              ]"
              label="¿Las instalaciones son propias?"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :items="[
                { value: 'Si', text: 'Sí' },
                { value: 'No', text: 'No' },
              ]"
              :error-messages="errors.own_facilities"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="delivery_fiscal_addres"
              :rules="[
                (v) =>
                  !!v || 'El campo entregas en dirección fiscal es requerido',
              ]"
              label="¿Entregas en Dirección Fiscal?"
              required
              outlined
              :readonly="authenticated.type == 3"
              dense
              :items="[
                { value: 'Si', text: 'Sí' },
                { value: 'No', text: 'No' },
              ]"
              :error-messages="errors.delivery_fiscal_addres"
            ></v-select>
          </v-col>
          <v-col cols="12" v-if="false">
            <v-btn color="primary" @click="addCustomerAddress"
              >Agregar dirección</v-btn
            >
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Razón social</th>
                    <th class="text-left">RFC</th>
                    <th class="text-left">Dirección</th>
                    <th class="text-left">Delegación/Municipio</th>
                    <th class="text-left">Código postal</th>
                    <th class="text-left">Estado</th>
                    <th class="text-left">Contacto</th>
                    <th class="text-left">Teléfono</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in form.addresses" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.rfc }}</td>
                    <td>
                      {{ item.street }} {{ item.interior_number }}
                      {{ item.outdoor_number }} {{ item.suburb }}
                    </td>
                    <td>{{ item.town }}</td>
                    <td>{{ item.state }}</td>
                    <td>{{ item.postal_code }}</td>
                    <td>{{ item.contact_name }}</td>
                    <td>{{ item.contact_phone }}</td>
                    <td>
                      <v-btn icon color="warning" @click="editAddress(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn icon color="error" @click="deleteAddress(item.id)">
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="justify-end">
      <v-btn @click="back">Regresar</v-btn>
      <v-btn color="success" @click="validate"> Continuar </v-btn>
    </v-card-actions>

    <v-dialog v-model="addressDialog" width="70%">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"
          >Registrar dirección</v-card-title
        >
        <v-card-text>
          <CustomerAddresses
            :dataAddress="address"
            :submitAddress="onSubmitAddress"
            :cancelSubmitAddress="onCancelSubmitAddress"
          ></CustomerAddresses>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/main.js";
import TextInputMoney from "@/components/iel/alta/TextInputMoney.vue";
import CustomerAddresses from "./CustomerAddresses.vue";

export default {
  name: "FiscalData",

  components: { TextInputMoney, CustomerAddresses },

  props: [
    "form",
    "cfdi_uses",
    "payment_methods",
    "tax_regimes",
    "way_pays",
    "errors",
  ],

  data: () => ({
    loading: false,
    addressDialog: false,
    selection: 1,
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
    cfdiuses: [1, 2, 3, 4],
    address: null,
  }),

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      showAddresses: "customerRequest/showAddresses",
    }),
    showLineRequestCredit() {
      return this.request_credit == "Si";
    },
    request_credit: {
      get() {
        return this.$store.state.customerRequest.form.fiscal.request_credit;
      },
      set(value) {
        this.$store.commit("customerRequest/updateFiscalRequestCredit", value);
      },
    },
    line_request_credit: {
      get() {
        return this.$store.state.customerRequest.form.fiscal
          .line_request_credit;
      },
      set(value) {
        this.$store.commit("customerRequest/updateLineRequestCredit", value);
      },
    },
    delivery_fiscal_addres: {
      get() {
        return this.$store.state.customerRequest.form.fiscal
          .delivery_fiscal_addres;
      },
      set(value) {
        this.$store.commit("customerRequest/UPDATE_FISCAL_FORM_FIELD", {
          field: "delivery_fiscal_addres",
          value: value,
        });
      },
    },
  },

  mounted() {
    eventBus.$on("FiscalData::validateForm", () => {
      this.validate();
    });

    eventBus.$on("IELAltaCliente::resetForm", () => {
      this.reset();
    });
  },

  methods: {
    back() {
      eventBus.$emit("IELAltaCliente::setStep", 1);
    },
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
    validate() {
      // this.loading = true;

      // if (this.$refs.fiscalForm.validate()) {
      eventBus.$emit("IELAltaCliente::setStep", 3);
      // }
    },
    reset() {
      this.$refs.fiscalForm.reset();
    },
    resetValidation() {
      this.$refs.fiscalForm.resetValidation();
    },

    addCustomerAddress() {
      this.addressDialog = true;
    },
    onSubmitAddress(form) {
      if (this.address && this.address.id) {
        let index = this.form.addresses.findIndex(
          (item) => item.id == this.address.id
        );

        if (index >= 0) {
          this.form.addresses[index].person_type = form.person_type;
          this.form.addresses[index].name = form.name;
          this.form.addresses[index].rfc = form.rfc;
          this.form.addresses[index].postal_code = form.postal_code;
          this.form.addresses[index].state = form.state;
          this.form.addresses[index].town = form.town;
          this.form.addresses[index].suburb = form.suburb;
          this.form.addresses[index].street = form.street;
          this.form.addresses[index].outdoor_number = form.outdoor_number;
          this.form.addresses[index].interior_number = form.interior_number;
          this.form.addresses[index].contact_name = form.contact_name;
          this.form.addresses[index].contact_phone = form.contact_phone;
        }
      } else {
        this.form.addresses.push(form);
      }
      this.addressDialog = false;
    },
    onCancelSubmitAddress() {
      this.addressDialog = false;
    },
    editAddress(item) {
      this.address = item;
      this.addCustomerAddress();
    },
    deleteAddress(id) {
      this.$swal
        .fire({
          title: "Estas seguro de eliminar?",
          text: "Para confirmar los cambios debes enviar los cambios.",
          icon: "warning",
          showCancelButton: true,
          confirmButton: "#3085d",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si, eliminar!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let index = this.form.addresses.findIndex((item) => item.id == id);
            if (index >= 0) {
              this.form.addresses.splice(index, 1);
              this.$swal.fire({
                title: "Dirección eliminada",
                icon: "success",
              });
            }
          }
        });
    },
  },
};
</script>
