<template>
  <v-container fluid>
    <v-card-title>Cliente</v-card-title>
    <customer-info
      :catchTotal="total"
      :warnLock="catchRequestLock"
    ></customer-info>
    <v-card :loading="loading">
      <v-card-title>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="4" class="text-left"> Orden de Compra</v-col>
            <v-col cols="12" md="8">
              <v-text-field
                label="Código de orden de compra"
                outlined
                dense
                v-model="orderId"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" class="text-left"> Destinatario de mercancía </v-col>
            <v-col cols="12" md="8">
              <customer-address v-model="form.address"></customer-address>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <p>Agregar producto al carrito</p>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="addForm.code"
                  label="Código de material / SKU"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="addForm.quantity"
                  label="Cantidad"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn color="primary" @click="addItemToCart"
                  >Agregar al carrito</v-btn
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" md="12">
                <v-data-table
                  :loading="loading"
                  dense
                  :headers="headers"
                  :items="cart"
                  item-key="codigo_material"
                  class="elevation-1"
                >
                  <template v-slot:[`item.precio_lista`]="{ item }">
                    <p class="text-right m-0 p-0">
                      $ {{ formatNumber(item.precio_lista) }}
                    </p>
                  </template>
                  <template v-slot:[`item.precio_con_descuento`]="{ item }">
                    <p class="text-right m-0 p-0">
                      $ {{ formatNumber(item.precio_con_descuento) }}
                    </p>
                  </template>
                  <template v-slot:[`item.importe_total`]="{ item }">
                    <p class="text-right m-0 p-0">
                      $
                      {{
                        formatNumber(item.u_pedidas * item.precio_con_descuento)
                      }}
                    </p>
                  </template>
                  <template v-slot:[`item.acciones`]="{ item }">
                    <v-btn
                      color="error"
                      icon
                      small
                      @click="deleteFromCart(item)"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:[`body.append`]>
                    <tr>
                      <td colspan="8"></td>
                      <td class="text-center">
                        <b>IVA (16%):</b> <br />
                        <b>Total:</b>
                      </td>
                      <td class="text-right">
                        $ {{ formatNumber(iva) }} <br />
                        $ {{ formatNumber(total) }}
                      </td>
                      <td></td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn :disabled="loading">Cancelar</v-btn>
        <v-btn color="success" :disabled="loading" @click="makeOrderCsv"
          >Guardar y generar pedido <v-icon>mdi-cube-send</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import CustomerInfo from "@/views/shopping_cart/componets/CustomerInfo.vue";
import CustomerAddress from "@/views/ordercsv/CustomerAddress.vue";
import { eventBus } from "@/main";
import general from "@/plugins/general.js";

export default {
  name: "OrderCsv",
  components: { CustomerInfo, CustomerAddress },
  mixins: [general],
  data: () => ({
    loading: false,
    addForm: {
      code: null,
      quantity: null,
    },
    form: {
      order_id: null,
      order_number: null,
      address: {},
    },
    materialItem: {},
    headers: [
      { text: "Código", value: "codigo_material" },
      { text: "Nombre", value: "nombre_material" },
      { text: "Cantidad", value: "u_pedidas" },
      { text: "Empaque", value: "empaque" },
      { text: "Unidad medida", value: "unidad_medida" },
      { text: "Promedio mensual de compra", value: "PMCOM" },
      { text: "Días solicitados de inventario", value: "DSINV" },
      { text: "Precio lista", value: "precio_lista" },
      { text: "Precio descuento", value: "precio_con_descuento" },
      { text: "Importe total", value: "importe_total" },
      { text: "Acciones", value: "acciones" },
    ],
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
      orderId: "ordercsv/orderId",
      cart: "ordercsv/cart",
      total: "ordercsv/total",
      iva: "ordercsv/iva",
    }),
  },
  mounted() {
    eventBus.$on("OrderCsv::addOrderId", (data) => {
      this.form.order_id = data.orderId;
      console.log(data);
    });
  },
  methods: {
    ...mapMutations({
      setOrderId: "ordercsv/SET_ORDER_ID",
      setCard: "ordercsv/SET_CART",
      addItem: "ordercsv/ADD_ITEM",
      deleteItem: "ordercsv/DELETE_FROM_CART",
    }),
    catchRequestLock(data) {
      this.requestLock = data;
    },

    catchTotal(data) {
      this.subtotalPrint = parseFloat(data).toFixed(2);
      this.subtotal = parseFloat(data);
      this.iva = parseFloat((this.subtotal * 0.16).toFixed(2));
      this.total = this.subtotal + this.iva;
      this.total = this.total.toFixed(2);
    },
    catchErrorMaterial(data) {
      this.hasError = data;
      window.scroll(0, window.pageYOffset - 50);
    },
    catchDialogLoading(data) {
      this.dialogLoading = data;
    },
    formatNumber(value) {
      const f = parseFloat(value);
      // return f.toFixed(2);
      return f
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    deleteFromCart(item) {
      this.deleteItem(item.codigo_material);
      this.$swal({
        title: "Producto eliminado del carrito.",
        icon: "success",
        type: "success",
      });
    },
    makeOrderCsv() {
      this.loading = true;
      let customer = this.getCurrentClient();
      if (!customer) {
        this.$swal({
          title: "No se selecciono al cliente.",
          icon: "error",
          type: "error",
        });
      }
      let data = {
        VKORG: this.VKORG,
        VTWEG: this.VTWEG,
        VKBUR: this.VKBUR,
        order_type: this.ORDER_TYPE,
        carrito: this.cart,
        customer: customer.KUNNR,
        destino_compra: this.form.address.KUNNR,
        numero_vendedor: this.user.employee_number,
        orden_compra: this.orderId,
        order_id: null,
      };
      console.log(data);
      axios
        .post(
          `${process.env.VUE_APP_API_IEL_SERVER}/generateOrderCustomer`,
          data
        )
        .then((response) => {
          this.loading = false;
          let message = "Se realizó correctamente el pedido";
          let text = "";
          if (response.data.message == "BACKORDER GENERADO") {
            message = "Se realizó correctamente el pedido en BACKORDER";
            text = "";
          } else {
            text = `Folio de pedido: ${response.data.RDCreaEntrega.VBELN}`;
          }
          this.$swal.fire(message, text, "success").then(() => {
            this.form.order_id = null;
            this.form.order_number = null;
            this.form.address = {};
            this.setOrderId(null);
            this.setCard([]);
            eventBus.$emit("OrderCsv::orderCreated", response.data);
            this.$router.replace("/historial-pedidos");
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          let message = "Ocurrio un error.";
          let text = error;

          if (error.response?.data?.error_code) {
            message = error.response?.data?.error_code;
            text = "";
          }
          this.$swal.fire(message, text, "warning");
        });
    },
    addItemToCart() {
      let item = this.cart.find(
        (element) => element.codigo_material == this.addForm.code
      );

      if (item) {
        this.$swal({
          title: "producto existente en carrito. Modifique la cantidad.",
          icon: "error",
          type: "error",
        });
        return;
      }

      let data = {
        VKBUR: this.VKBUR,
        VKORG: this.VKORG,
        VTWEG: this.VTWEG,
        customer: this.user.custumer_code,
        code: this.addForm.code,
        units: this.addForm.quantity,
      };
      axios
        .post(`${process.env.VUE_APP_API_IEL_SERVER}/getMaterialInfo`, data)
        .then((response) => {
          console.log(response.data);
          this.addItem(response.data);
          this.resetAddForm();
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            title: "Error al agregar el producto, verifica el código",
            icon: "error",
            type: "error",
          });
        });
    },
    resetAddForm() {
      this.addForm.code = null;
      this.addForm.quantity = null;
    },
  },
};
</script>
