<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <div class="col-12 row">
          <div class="col-6">
            <v-avatar cols="6" size="120" style="border: solid 2px #ff000a">
              <img
                src="img/icon_default_user.svg"
                alt="IUSA"
                style="width: 90%; height: 90%"
              />
            </v-avatar>
          </div>
          <div class="col-6" style="align-items: left">
            <span style="text-align: left; font-weight: 800; color: #000">
              Cliente: ({{ customerInfo.KUNNR }}) {{ customerInfo.NAME1 }}
            </span>
            <br /><br />
            <span style="text-align: left; font-weight: 800; color: #000">
              Grupo: {{ customerInfo.KDGRP }}</span
            >
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-card-subtitle class="pa-lg-2"> </v-card-subtitle>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-card-subtitle>
          <div style="text-align: center">
            <span style="font-size: 28px">$ {{ formatPrice(total) }}</span>
            <br />
            MONTO TOTAL DEL PEDIDO
          </div>
          <!-- CREDITO DISPONIBLE:
                {{ parseFloat(customerInfo.CREDD) }} -->
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-alert
      icon="mdi-close-octagon"
      prominent
      type="error"
      dismissible
      v-if="customerInfo.AUFSD == 2"
    >
      <v-row align="center">
        <v-col class="grow">
          <span style="font-weight: 700; font-size: 16px"
            >Estimado cliente usted tiene un bloqueo de partidas vencidas, favor
            de realizar su pago en línea. Pagar aquí</span
          >
        </v-col>
        <!-- <v-col class="shrink">
                  <v-btn>Take action</v-btn>
              </v-col> -->
      </v-row>
    </v-alert>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { eventBus } from "@/main.js";

export default {
  name: "CustomerInfo",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  props: {
    catchTotal: {
      type: Number,
      default: null,
    },
    warnLock: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    customerInfo: [],
    total: 0,
    client: "",
    porcentaje: 0,
  }),
  created() {
    eventBus.$on("change-client", () => {
      this.client = JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR;
      console.info("clientCurrent => ", this.client);
      this.getInfoCustomer();
    });
  },
  async mounted() {
    let self = this;
    if (localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7")) {
      self.client = localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7");
      self.getInfoCustomer();
    }
  },
  methods: {
    getInfoCustomer() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/infoCustomer", {
          customer: this.client,
          VKORG: "IUS2",
          VTWEG: "DT",
          VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
        })
        .then((response) => {
          localStorage.setItem(
            "1us4-p0rt4l-v3nd3nd0r-VKBUR",
            response.data.VKBUR
          );
          this.customerInfo = response.data;
          this.saldo = this.customerInfo.SKFOR.replace(/,/g, "");
          this.limite = this.customerInfo.KLIMK.replace(/,/g, "");
          this.porcentaje =
            (parseInt(this.saldo) * 100) / parseInt(this.limite);
          this.porcentaje = parseInt(this.porcentaje);
          this.warnLock(this.customerInfo.AUFSD);
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally();
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  watch: {
    catchTotal: function (val) {
      this.total = val;
    },
  },
  destroyed() {
    eventBus.$off("change-client");
  },
};
</script>
