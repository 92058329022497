var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.idRequest != null &&
      _vm.idRequest != undefined &&
      _vm.document &&
      _vm.document.edit == false &&
      _vm.document.value != undefined &&
      _vm.form.document && _vm.form.document[_vm.document.key]
    )?_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.capitalize(_vm.label)))]),_c('br'),_c('a',{staticClass:"h6",attrs:{"target":"_blank","href":`${_vm.urlBaseIEL}/${_vm.document.value}`}},[_vm._v(_vm._s(_vm.getDocumentName(_vm.form.document[_vm.document.key])))])]),_c('v-btn',{attrs:{"color":"success","icon":"","small":"","disabled":_vm.authenticated.type == 3},on:{"click":function($event){_vm.document.edit = true}}},[_c('v-icon',[_vm._v("mdi-upload")])],1)],1):_c('div',[_c('v-file-input',{attrs:{"rules":[
        (v) => !!v || `El campo label es requerido`,
        (v) => (v && v.size > 0) || `El campo label es requerido`,
      ],"label":_vm.label,"required":"","outlined":"","dense":"","accept":_vm.fileType,"error-messages":_vm.errors[_vm.document.key]},model:{value:(_vm.form[_vm.document.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.document.key, $$v)},expression:"form[document.key]"}}),_c('span',[_vm._v("Solo se aceptan "),_c('b',[_vm._v(_vm._s(_vm.fileType == "application/pdf" ? "archivos PDF" : "imágenes"))]),_c('v-icon',[_vm._v(_vm._s(_vm.fileType == "application/pdf" ? "mdi-file-pdf-box" : "mdi-image"))])],1)],1),(_vm.document.edit == true)?_c('v-btn',{staticStyle:{"color":"#fff"},attrs:{"x-small":"","color":"red"},on:{"click":function($event){_vm.document.edit = false}}},[_vm._v("Cancelar")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }