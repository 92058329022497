<template>
  <div class="col-md-12">
    <div class="row">
      <!-- <div class="col-md-4">
          <v-text-field
            v-model.trim="material_code"
            label="Código de material / SKU"
            prepend-inner-icon="mdi-magnify"
            v-on:keyup.enter="getMaterialInfo()"
            style="padding: 15px"
          ></v-text-field>
        </div>
        <div class="col-md-4">
          <v-text-field
            v-model.trim="uniti_code"
            label="Unidades"
            v-on:keyup.enter="getMaterialInfo()"
            style="padding: 15px"
          ></v-text-field>
        </div>
        <div class="col-md-4">
          <v-btn
            color="primary"
            rounded
            @click="getMaterialInfo()"
            style="background: #4A74FF color: #fff !important ;padding: 15px;"
          >
            <v-icon style="color: #fff !important">mdi-plus</v-icon>Agregar al
            Carrito
          </v-btn>
        </div>-->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "MaterialIntoCart",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  props: {
    newMaterial: {
      type: String,
      default: null,
    },
    warnMaterial: {
      type: Function,
      default: null,
    },
    warnError: {
      type: Function,
      default: null,
    },
    warnDialogLoading: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    material_code: "",
    uniti_code: "",
  }),
  methods: {
    getMaterialInfo() {
      this.warnError(null);
      if (this.material_code === "" || this.material_code === null) {
        this.warnError("El campo CÓDIGO DE MATERIAL es obligatorio.");
      } else if (this.uniti_code === "" || this.uniti_code === null) {
        this.warnError("El campo UNIDADES es obligatorio.");
      } else {
        this.warnDialogLoading(true);
        axios
          .post(process.env.VUE_APP_API_IEL_SERVER + "/getMaterialInfo", {
            code: this.material_code,
            units: this.uniti_code,
            customer: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7"),
            VKORG: "IUS2",
            VTWEG: "DT",
            VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
          })
          .then((response) => {
            console.log(response.data);
            this.warnDialogLoading(false);
            this.warnMaterial(response.data);
            this.material_code = null;
            this.uniti_code = null;
          })
          .catch((error) => {
            this.warnDialogLoading(false);
            this.warnError(error.response.data[0]);
            this.warnError(error.response.data.error_code);
          })
          .finally(() => this.warnDialogLoading(false));
      }
    },
  },
  watch: {
    newMaterial: function (val) {
      this.material_code = val;
      this.uniti_code = 1;
      this.getMaterialInfo();
    },
  },
};
</script>
