<template>
  <v-container fluid class="grey lighten-5">
    <div class="col-md-12" v-if="hasError">
      <v-alert text dense outlined type="error">
        {{ hasError }}
      </v-alert>
    </div>
    <v-progress-linear
      :active="loadingOrderDeliveryTime"
      :indeterminate="loadingOrderDeliveryTime"
      top
      color="deep-purple accent-4"
    ></v-progress-linear>
  </v-container>
</template>

<script>
import axios from "axios";

import { mapGetters } from "vuex";
export default {
  name: "ShoppingCart",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  props: {
    newMaterial: {
      type: String,
      default: null,
    },
    warnMaterial: {
      type: Function,
      default: null,
    },
    warnError: {
      type: Function,
      default: null,
    },
    warnDialogLoading: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    hasError: null,
    materialItem: {},
    subtotal: 0,
    iva: 0,
    total: 0,
    total2: "",
    customerDestiny: {},
    order: "",
    order_id: null,
    orderShop: [],
    material_code: "",
    uniti_code: "",
    loadingOrderDeliveryTime: true,
    id: "",
    orden_compra: "",
    client: "",
  }),
  async mounted() {
    this.order = this.$route.query.data[0].orden_compra;
    this.saveOrderInfoReminder(this.order);
    this.saveOrderDataRemider(
      this.$route.query.data[0].id,
      this.$route.query.data[0].orden_compra
    );
    this.$nextTick(function () {
      this.getOrderID();
      this.getRoute(this.$route.query.data);
      this.client = localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7");
    });
  },
  methods: {
    getRoute(value) {
      for (let i = 0; i < value.length; i++) {
        this.material_code = value[i].codigo;
        this.uniti_code = value[i].cantidad_pedida;
        this.id = value[i].id;
        this.orden_compra = value[i].orden_compra;
        this.getMaterialInfo(this.material_code, this.uniti_code);
      }
    },
    saveOrderInfoReminder(order) {
      this.loadingOrderDeliveryTime = true;
      let type = "VE";
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/saveOrderInfoReminder", {
          customer_id: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7"),
          orden_compra: order,
          destiny: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7"),
          cart: this.cart,
          total: this.total,
          user_id: this.user.id,
          type: type,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          // alert(error);
          console.log(error);
        })
        .finally();
    },

    getMaterialInfo(material_code, uniti_code) {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/getMaterialInfo", {
          code: material_code,
          units: uniti_code,
          customer: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7"),
          VKORG: "IUS2",
          VTWEG: "DT",
          VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
        })
        .then((response) => {
          this.saveNewMaterialOrder(response.data);
          this.material_code = null;
          this.uniti_code = null;
        })
        .catch((error) => {
          this.loadingOrderDeliveryTime = false;
          this.catchErrorMaterial(error.response.data.error_code);
        });
    },
    getOrderID() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/getOrderIDReminder", {
          customer: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7"),
        })
        .then((response) => {
          this.order_id = response.data.id;
          this.id_r = response.data.backorder_id;
          this.orden_comfirm = response.data.name;
        })
        .catch((error) => {
          // alert(error);
          console.log(error);
        })
        .finally();
    },

    saveNewMaterialOrder(item) {
      axios
        .post(
          process.env.VUE_APP_API_IEL_SERVER + "/saveNewMaterialOrderReminder",
          {
            order_id: this.order_id,
            item_cart: item,
          }
        )
        .then((response) => {
          console.log(response);
          this.orderShop.push({
            id_r: this.id,
            orden_compra: this.orden_compra,
          });

          this.$router.push({
            name: "ShoppingCartReminder",
            query: {
              data: this.orderShop,
            },
          });
        })
        .catch((error) => {
          console.warn(error);
        })
        .finally(() => (this.loadingOrderDeliveryTime = false));
    },
    saveOrderDataRemider(back_id, orden_compra) {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/saveOrderDataRemider", {
          back_id: back_id,
          orden_compra: orden_compra,
          customer_code: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7"),
        })
        .then((response) => {
          console.log(response.data);
          // this.warnDialogLoading(false);
          // this.warnMaterial(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response.data[0]);
          console.log(error.response.data.error_code);
        });
    },
    catchErrorMaterial(data) {
      this.hasError = data;
      window.scroll(0, window.pageYOffset - 50);
    },
  },
};
</script>
<style></style>
