<template>
  <v-container fluid>
    <v-row>
      <v-toolbar>
        <v-btn color="primary" icon dark @click="volverRutas()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>&nbsp;Encuesta</v-toolbar-title>
      </v-toolbar>
    </v-row>
    <v-row>
      <v-card style="width: 100%; height: auto">
        <v-card-text>
          <v-icon>mdi-store</v-icon>
          <strong>{{ infoOportunity.numero_ruta }}</strong>
          {{ infoOportunity.nombre }}
        </v-card-text>
      </v-card>
    </v-row>
    <v-row>
      <v-tabs v-model="tab" background-color="primary" show-arrows>
        <v-tab :key="0" :href="`#tab-0`">
          <v-icon left> mdi-check </v-icon>
          Informacion del Cliente
        </v-tab>
        <v-tab :key="1" :href="`#tab-1`">
          <v-icon left v-if="pregunta1_1"> mdi-check </v-icon>
          pregunta 1
        </v-tab>
        <v-tab :key="2" :href="`#tab-2`">
          <v-icon left v-if="pregunta2"> mdi-check </v-icon>
          pregunta 2
        </v-tab>
        <v-tab :key="3" :href="`#tab-3`" v-if="(pregunta2 != 'NO')">
          <v-icon left v-if="pregunta3"> mdi-check </v-icon>
          pregunta 3
        </v-tab>
        <v-tab :key="4" :href="`#tab-4`" v-if="(pregunta2 != 'NO')">
          <v-icon left v-if="pregunta4_1 != null || pregunta4_2 != null"> mdi-check </v-icon>
          pregunta 4
        </v-tab>
        <v-tab :key="5" :href="`#tab-5`">
          <v-icon left v-if="pregunta5"> mdi-check </v-icon>
          pregunta 5
        </v-tab>
        <v-tab :key="6" :href="`#tab-6`">
          <v-icon left v-if="pregunta6"> mdi-check </v-icon>
          pregunta 6
        </v-tab>
        <v-tab :key="7" :href="`#tab-7`">
          <v-icon left v-if="pregunta7"> mdi-check </v-icon>
          pregunta 7
        </v-tab>
        <v-tab :key="8" :href="`#tab-8`">
          <v-icon left v-if="pregunta8"> mdi-check </v-icon>
          pregunta 8
        </v-tab>
        <v-tab :key="9" :href="`#tab-9`" v-if="(pregunta8 != 'NO')">
          <v-icon left v-if="pregunta9"> mdi-check </v-icon>
          pregunta 9
        </v-tab>
        <v-tab :key="10" :href="`#tab-10`" v-if="(pregunta8 != 'NO')">
          <v-icon left v-if="pregunta10"> mdi-check </v-icon>
          pregunta 10
        </v-tab>
        <v-tab :key="11" :href="`#tab-11`">
          <v-icon left v-if="pregunta11"> mdi-check </v-icon>
          pregunta 11
        </v-tab>
        <v-tab :key="12" :href="`#tab-12`">
          <v-icon left v-if="pregunta12"> mdi-check </v-icon>
          pregunta 12
        </v-tab>
        <v-tab :key="13" :href="`#tab-13`">
          <v-icon left v-if="pregunta13"> mdi-check </v-icon>
          pregunta 13
        </v-tab>
        <v-tab :key="14" :href="`#tab-14`">
          <v-icon left v-if="pregunta14"> mdi-check </v-icon>
          pregunta 14
        </v-tab>
        <v-tab :key="15" :href="`#tab-15`" v-if="pregunta14 == 'De manera directa'">
          <v-icon left v-if="pregunta15"> mdi-check </v-icon>
          pregunta 15
        </v-tab>
        <v-tab :key="16" :href="`#tab-16`" v-if="pregunta14 == 'Por un distribuidor'">
          <v-icon left v-if="pregunta16"> mdi-check </v-icon>
          pregunta 16
        </v-tab>
        <v-tab :key="17" :href="`#tab-17`" v-if="pregunta14 != 'No viable'">
          <v-icon left v-if="pregunta17"> mdi-check </v-icon>
          pregunta 17
        </v-tab>
        <v-tab :key="18" :href="`#tab-18`">
          <v-icon left v-if="pregunta18"> mdi-check </v-icon>
          pregunta 18
        </v-tab>
        <br />
        <v-tabs-items v-model="tab">
          <v-tab-item :key="0" :value="`tab-0`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>0.- ¿Me podria proporcionar la siguiente información?</p>
                <br />
                <v-text-field v-model="nombreCliente" label="Nombre del entrevistado"></v-text-field>
                <br />
                <v-text-field v-model="nombreEncargado" label="Nombre del encargado de compras"></v-text-field>
                <v-row v-if="(nombreCliente || nombreEncargado)">
                  <br />
                  <v-btn color="success" block @click="tab = `tab-1`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="1" :value="`tab-1`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>1.- ¿Me podría decir cuáles son las tres principales marcas que vende en su negocio?</p>
                <br />
                <v-select :items="opcionSelect1" v-model="pregunta1_1" label="Primera Mencion" chips
                  @change="activarOtro1_1()">
                </v-select><br />
                <v-text-field v-model="otrosPregunta1_1" label="Ingresar Otro"
                  v-if="banderaOtros1_1"></v-text-field><br />
                <v-select :items="opcionSelect1" v-model="pregunta1_2" label="Segunda Mencion" chips
                  @change="activarOtro1_2()">
                </v-select><br />
                <v-text-field v-model="otrosPregunta1_2" label="Ingresar Otro"
                  v-if="banderaOtros1_2"></v-text-field><br />
                <v-select :items="opcionSelect1" v-model="pregunta1_3" label="Tercera Mencion" chips
                  @change="activarOtro1_3()">
                </v-select><br />
                <v-text-field v-model="otrosPregunta1_3" label="Ingresar Otro" v-if="banderaOtros1_3"></v-text-field>
                <v-row v-if="pregunta1_1">
                  <v-btn color="success" block @click="tab = `tab-2`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" :value="`tab-2`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>2.- ¿Actualmente vende productos de la marca IUSA?</p>
                <br />
                <v-row no-gutters>
                  <v-col>
                    <v-btn :color="tabColor2('SI')" block @click="pregunta2 = 'SI'">
                      SI
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn :color="tabColor2('NO')" block @click="pregunta2 = 'NO'">
                      NO
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="pregunta2 == 'SI'">
                  <br />
                  <v-btn color="success" block @click="tab = `tab-3`">
                    Siguiente
                  </v-btn>
                </v-row>
                <v-row v-if="pregunta2 == 'NO'">
                  <br />
                  <v-btn color="success" block @click="tab = `tab-5`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="3" :value="`tab-3`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>3.- ¿Cómo compra los productos IUSA?</p>
                <br />
                <v-select :items="opcionSelect3" v-model="pregunta3" label="Seleccione opción" chips>
                </v-select><br />
                <v-row v-if="pregunta3">
                  <v-btn color="success" block @click="tab = `tab-4`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="4" :value="`tab-4`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>4.- ¿Me podría decir el nombre de la tienda especializada y/o distribuidor?</p>
                <br />
                <v-text-field v-model="pregunta4_1" label="Nombre Tienda Especializada"></v-text-field><br />
                <v-text-field v-model="pregunta4_2" label="Nombre Distribuidor"></v-text-field><br />
                <v-row v-if="(pregunta4_1 || pregunta4_2)">
                  <v-btn color="success" block @click="tab = `tab-5`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="5" :value="`tab-5`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>5.- De los productos que adquiere para su negocio ¿en qué tipo de presentaciones acostumbra
                  comprarlos?</p>
                <br />
                <v-select :items="opcionSelect5" v-model="pregunta5" label="Seleccione opción" chips multiple>
                </v-select><br />
                <v-row v-if="pregunta5">
                  <v-btn color="success" block @click="tab = `tab-6`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="6" :value="`tab-6`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>6.- ¿Con qué frecuencia compra o hace pedidos a sus principales distribuidores?</p>
                <br />
                <v-select :items="opcionSelect6" v-model="pregunta6" label="Seleccione opción" chips multiple>
                </v-select><br />
                <v-row v-if="pregunta6">
                  <v-btn color="success" block @click="tab = `tab-7`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="7" :value="`tab-7`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>7.- Tomando en cuenta a su principal distribuidor ferretero, ¿aproximadamente cuál es el rango de
                  compra mensual que destina para surtir su negocio?</p>
                <br />
                <v-select :items="opcionSelect7" v-model="pregunta7" label="Seleccione opción" chips>
                </v-select><br />
                <v-row v-if="pregunta7">
                  <v-btn color="success" block @click="tab = `tab-8`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="8" :value="`tab-8`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>8.- ¿Actualmente tiene líneas de crédito con sus distribuidores?</p>
                <br />
                <v-row no-gutters>
                  <v-col>
                    <v-btn :color="tabColor8('SI')" block @click="pregunta8 = 'SI'">
                      SI
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn :color="tabColor8('NO')" block @click="pregunta8 = 'NO'">
                      NO
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="pregunta8 == 'SI'">
                  <br />
                  <v-btn color="success" block @click="tab = `tab-9`">
                    Siguiente
                  </v-btn>
                </v-row>
                <v-row v-if="pregunta8 == 'NO'">
                  <br />
                  <v-btn color="success" block @click="tab = `tab-11`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="9" :value="`tab-9`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>9.- Tomando en cuenta a su principal distribuidor ferretero, ¿aproximadamente cuál es rango de línea
                  de crédito que le otorga?</p>
                <br />
                <v-select :items="opcionSelect9" v-model="pregunta9" label="Seleccione opción" chips>
                </v-select><br />
                <v-row v-if="pregunta9">
                  <v-btn color="success" block @click="tab = `tab-10`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="10" :value="`tab-10`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>10.- ¿Cúantos días de plazo le otorga su principal distribuidor ferretero? </p>
                <br />
                <v-select :items="opcionSelect10" v-model="pregunta10" label="Seleccione opción" chips
                  @change="activarOtro10()">
                </v-select><br />
                <br />
                <v-text-field v-model="otrosPregunta10" label="Ingresar Otro"
                  v-if="banderaOtros10"></v-text-field><br />
                <v-row v-if="pregunta10">
                  <v-btn color="success" block @click="tab = `tab-11`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="11" :value="`tab-11`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>11- ¿Cuántos años tiene este negocio funcionando? </p>
                <br />
                <v-select :items="opcionSelect11" v-model="pregunta11" label="Seleccione opción" chips>
                </v-select><br />
                <v-row v-if="pregunta11">
                  <v-btn color="success" block @click="tab = `tab-12`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="12" :value="`tab-12`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>12.- Sin contar este negocio, ¿cuántas sucursales tiene? </p>
                <br />
                <v-select :items="opcionSelect12" v-model="pregunta12" label="Seleccione opción" chips>
                </v-select><br />
                <v-row v-if="pregunta12">
                  <v-btn color="success" block @click="tab = `tab-13`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="13" :value="`tab-13`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>13.- ¿Cuenta con cédula fiscal vigente?</p>
                <br />
                <v-select :items="opcionSelect13" v-model="pregunta13" label="Seleccione opción" chips>
                </v-select><br />
                <v-row v-if="pregunta13">
                  <br />
                  <v-btn color="success" block @click="tab = `tab-14`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="14" :value="`tab-14`">
            <v-card flat>
              <v-card-text>
                <strong>Pregunta para el Vendedor: </strong><br />
                <p>14.- Asesor, ¿este negocio puede ser atendido de manera directa o por un distribuidor? </p>
                <br />
                <v-select :items="opcionSelect14" v-model="pregunta14" label="Seleccione opción" chips>
                </v-select><br />
                <v-row v-if="pregunta14 == 'De manera directa'">
                  <v-btn color="success" block @click="tab = `tab-15`">
                    Siguiente
                  </v-btn>
                </v-row>
                <v-row v-if="pregunta14 == 'Por un distribuidor'">
                  <v-btn color="success" block @click="tab = `tab-16`">
                    Siguiente
                  </v-btn>
                </v-row>
                <v-row v-if="pregunta14 == 'No viable'">
                  <v-btn color="success" block @click="tab = `tab-18`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="15" :value="`tab-15`">
            <v-card flat>
              <v-card-text><br />
                <p>15.- ¿Estaría interesado en ser visitado y atendido por uno de nuestros asesores para ayudarle a
                  gestionar sus pedidos de nuestros productos?</p>
                <br />
                <v-row no-gutters>
                  <v-col>
                    <v-btn :color="tabColor15('SI')" block @click="pregunta15 = 'SI'">
                      SI
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn :color="tabColor15('NO')" block @click="pregunta15 = 'NO'">
                      NO
                    </v-btn>
                  </v-col>
                </v-row><br />
                <v-row v-if="pregunta15 == 'SI'">
                  <v-btn color="success" block @click="tab = `tab-17`">
                    Siguiente
                  </v-btn>
                </v-row>
                <v-row v-if="pregunta15 == 'NO'">
                  <v-btn color="success" block @click="tab = `tab-18`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="16" :value="`tab-16`">
            <v-card flat>
              <v-card-text><br />
                <p>16- ¿Estaría interesado en ser visitado y atendido por un distribuidor de IUSA para ayudarle a
                  gestionar sus pedidos de nuestros productos?</p>
                <br />
                <v-row no-gutters>
                  <v-col>
                    <v-btn :color="tabColor16('SI')" block @click="pregunta16 = 'SI'">
                      SI
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn :color="tabColor16('NO')" block @click="pregunta16 = 'NO'">
                      NO
                    </v-btn>
                  </v-col>
                </v-row><br />
                <v-row v-if="pregunta16 == 'SI'">
                  <v-btn color="success" block @click="tab = `tab-17`">
                    Siguiente
                  </v-btn>
                </v-row>
                <v-row v-if="pregunta16 == 'NO'">
                  <v-btn color="success" block @click="tab = `tab-18`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="17" :value="`tab-17`">
            <v-card flat>
              <v-card-text>
                <br />
                <p>17.- ¿De qué líneas de producto IUSA le gustaría hacer un pedido sugerido?</p>
                <br />
                <v-select :items="opcionSelect17" v-model="pregunta17" label="Seleccione opción" chips multiple>
                </v-select><br />
                <v-row v-if="pregunta17">
                  <v-btn color="success" block @click="tab = `tab-18`">
                    Siguiente
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="18" :value="`tab-18`">
            <v-card flat>
              <v-card-text>
                <strong>Pregunta para el Vendedor: </strong><br />
                <p>18.- Asesor, identifique visualmente las marcas que comercializa este negocio</p>
                <br />
                <v-autocomplete :items="opcionSelect18" v-model="pregunta18" label="Seleccione opción" chips multiple
                  @change="activarOtro18()">
                </v-autocomplete><br />
                <v-text-field v-model="otrosPregunta18" label="Ingresar Otro"
                  v-if="banderaOtros18"></v-text-field><br />
                <v-row>
                  <v-btn color="success" block @click="saveForm()">
                    Guardar Encuesta
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>


        </v-tabs-items>
      </v-tabs>
    </v-row>
    <!-- Dialog loading -->
    <v-row justify="center">
      <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- /. Dialog -->
    <v-snackbar v-model="snackbar">
      {{ textAlert }}
      <v-btn color="pink" text @click="snackbar = false"> Cerrar </v-btn>
    </v-snackbar>
    <!-- /.SnackBar -->
  </v-container>
</template>

<script>
import axios from "axios";
import router from "../router";
export default {
  data: () => ({
    infoOportunity: {},
    dialogLoading: false,
    nombreCliente: null,
    nombreEncargado: null,
    pregunta1_1: null,
    pregunta1_2: null,
    pregunta1_3: null,
    banderaOtros1_1: null,
    banderaOtros1_2: null,
    banderaOtros1_3: null,
    otrosPregunta1_1: null,
    otrosPregunta1_2: null,
    otrosPregunta1_3: null,
    opcionSelect1: [
      "Truper/Pretul/Foset/Voltek",
      "Urrea/Surtek/Foy",
      "Aksi/Maxtool",
      'Anbec',
      'Lion Tools',
      'Toolcraft/Rotter/Fulgore',
      'Adir/Goni',
      'Otros'
    ],
    pregunta2: null,
    pregunta3: null,
    opcionSelect3: [
      "Acudo directamente a una tienda especializada",
      "Un distribuidor me visita",
      "Ambas opciones",
    ],
    pregunta4_1: null,
    pregunta4_2: null,
    pregunta5: null,
    opcionSelect5: [
      "Caja cerrada/atado",
      "Pieza"
    ],
    pregunta6: null,
    opcionSelect6: [
      "Semanal",
      "Quincenal",
      "Mensual"
    ],
    pregunta7: null,
    opcionSelect7: [
      "Menos de $10,000",
      "De $10,000 a $24,999",
      "De $25,000 a $50,000",
      "Más de $50,000",
      "No proporcionó",
    ],
    pregunta8: null,
    pregunta9: null,
    opcionSelect9: [
      "Menos de $5,000",
      "De $5,000 a $14,999",
      "De $15,000 a $24,999",
      "De $25,000 a $35,000",
      "Más de $35,000",
      "No proporcionó",
    ],
    pregunta10: null,
    opcionSelect10: [
      "7 días o menos",
      "15 días",
      "30 días",
      "Otro",
      "No proporcionó",
    ],
    banderaOtros10: null,
    otrosPregunta10: null,
    pregunta11: null,
    opcionSelect11: [
      "Menos de 1 año",
      "De 1 a 3 años",
      "De 4 a 6 años",
      "De 7 a 9 años",
      "10 años o más",
      "No proporcionó",
    ],
    pregunta12: null,
    opcionSelect12: [
      "Ninguna",
      "1",
      "2",
      "3",
      "4",
      "5 o mas",
      "No proporcionó",
    ],
    pregunta13: null,
    opcionSelect13: [
      "SI",
      "NO",
      "No proporciono"
    ],
    pregunta14: null,
    opcionSelect14: [
      "De manera directa",
      "Por un distribuidor",
      "No viable"
    ],
    pregunta15: null,
    pregunta16: null,
    pregunta17: null,
    opcionSelect17: [
      "Eléctrico",
      "Hidráulico",
      "Gas",
      "Herramienta",
      "Linea Blanca",
    ],
    pregunta18: null,
    opcionSelect18: [
      "Adir",
      "Aksi",
      "Amanco",
      "Anbec",
      "Aquaplas",
      "Argos",
      "Bosch",
      "Bticino",
      "Calorex",
      "Cinsa",
      "Citijal",
      "Condumex",
      "Dica",
      "Durman",
      "Ecoplas",
      "Eureka",
      "Evans",
      "Foset",
      "Igoto",
      "Indiana",
      "Keer",
      "Kobrex",
      "Kruger",
      "Leviton",
      "Magamex",
      "Nacobre",
      "Pretul",
      "Rheem",
      "Rotomex",
      "Rotoplas",
      "Royer",
      "Rugo",
      "Sanelec",
      "Sanplom",
      "Santul",
      "Schneider",
      "Siemens",
      "Simon",
      "Square D",
      "Stanley",
      "Surtek",
      "Tecno Lite",
      "Truper",
      "Urrea",
      "Volteck",
      "Otro",
    ],
    banderaOtros18: null,
    otrosPregunta18: null,
    tab: null,
    snackbar: false,
    textAlert: null,
  }),
  async mounted() {
    this.getInfoOpportunity();
  },
  methods: {
    async getInfoOpportunity() {
      this.dialogLoading = true;
      await axios
        .post("getOpportunity", {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.infoOportunity = response.data[0];
          this.dialogLoading = false;
        })
        .catch((error) => {
          alert(error);
          this.dialogLoading = false;
        })
        .finally(() => (this.dialogLoading = false));
    },
    activarOtro1_1() {
      this.banderaOtros1_1 = false;
      if (this.pregunta1_1 == "Otros") {
        this.banderaOtros1_1 = true;
      } //fin if
    },
    activarOtro1_2() {
      this.banderaOtros1_2 = false;
      if (this.pregunta1_2 == "Otros") {
        this.banderaOtros1_2 = true;
      } //fin if
    },
    activarOtro1_3() {
      this.banderaOtros1_3 = false;
      if (this.pregunta1_3 == "Otros") {
        this.banderaOtros1_3 = true;
      } //fin if
    },
    activarOtro10() {
      this.banderaOtros10 = false;
      if (this.pregunta10 == "Otro") {
        this.banderaOtros10 = true;
      } //fin if
    },
    activarOtro18() {
      this.banderaOtros18 = false;
      this.pregunta18.forEach(element => {
        console.log(element)
        if (element == "Otro") {
          this.banderaOtros18 = true;
        } //fin if
      });

    },
    tabColor2(tab) {
      return tab == this.pregunta2 ? "primary" : "normal";
    },
    tabColor8(tab) {
      return tab == this.pregunta8 ? "primary" : "normal";
    },
    tabColor13(tab) {
      return tab == this.pregunta13 ? "primary" : "normal";
    },
    tabColor15(tab) {
      return tab == this.pregunta15 ? "primary" : "normal";
    },
    tabColor16(tab) {
      return tab == this.pregunta16 ? "primary" : "normal";
    },
    volverRutas() {
      router.replace("/routes");
    },
    snackbarOut() {
      setTimeout(function () {
        this.snackbar = false;
        this.textAlert = null;
      }, 5000);
    },
    saveForm() {
      let me = this;

      var pregunta5String = "";
      var pregunta6String = "";
      var pregunta17String = "";
      var pregunta18String = "";
      if (me.pregunta5 != null && me.pregunta5.length != 0) {
        for (var j = 0; j < me.pregunta5.length; j++) {
          pregunta5String = me.pregunta5[j] + "," + pregunta5String;
        }
        pregunta5String = pregunta5String.slice(0, -1);
      }
      if (me.pregunta6 != null && me.pregunta6.length != 0) {
        for (var k = 0; k < me.pregunta6.length; k++) {
          pregunta6String = me.pregunta6[k] + "," + pregunta6String;
        }
        pregunta6String = pregunta6String.slice(0, -1);
      }
      if (me.pregunta17 != null && me.pregunta17.length != 0) {
        for (var l = 0; l < me.pregunta17.length; l++) {
          pregunta17String = me.pregunta17[l] + "," + pregunta17String;
        }
        pregunta17String = pregunta17String.slice(0, -1);
      }
      if (me.pregunta18 != null && me.pregunta18.length != 0) {
        for (var m = 0; m < me.pregunta18.length; m++) {
          pregunta18String = me.pregunta18[m] + "," + pregunta18String;
        }
        pregunta18String = pregunta18String.slice(0, -1);
      }
      me.dialogLoading = true;
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          axios
            .post("./saveForm", {
              idOportunidad: me.$route.params.id,
              nombreCliente: me.nombreCliente,
              nombreEncargado: me.nombreEncargado,
              pregunta1_1: me.pregunta1_1,
              pregunta1_2: me.pregunta1_2,
              pregunta1_3: me.pregunta1_3,
              otrosPregunta1_1: me.otrosPregunta1_1,
              otrosPregunta1_2: me.otrosPregunta1_2,
              otrosPregunta1_3: me.otrosPregunta1_3,
              pregunta2: me.pregunta2,
              pregunta3: me.pregunta3,
              pregunta4_1: me.pregunta4_1,
              pregunta4_2: me.pregunta4_2,
              pregunta5: pregunta5String,
              pregunta6: pregunta6String,
              pregunta7: me.pregunta7,
              pregunta8: me.pregunta8,
              pregunta9: me.pregunta9,
              pregunta10: me.pregunta10,
              otrosPregunta10: me.otrosPregunta10,
              pregunta11: me.pregunta11,
              pregunta12: me.pregunta12,
              pregunta13: me.pregunta13,
              pregunta14: me.pregunta14,
              pregunta15: me.pregunta15,
              pregunta16: me.pregunta16,
              pregunta17: pregunta17String,
              pregunta18: pregunta18String,
              otrosPregunta18: me.otrosPregunta18,
              latitud: pos.coords.latitude,
              longitud: pos.coords.longitude,
              id_promotor: localStorage.getItem("id"),
            })
            .then(function () {
              // handle success
              me.dialogLoading = false;
              //router.replace("/routes");
              router.replace("/Camera/" + me.$route.params.id );
            })
            .catch(function (error) {
              console.log(error);
              me.dialogLoading = false;
            });
        },
        (err) => {
          console.log(err);
          me.dialogLoading = false;
        }
      );
    }
  },
};
</script>
