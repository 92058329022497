import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import * as VueGoogleMaps from "vue2-google-maps";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment"
import VueMoment from "vue-moment";
import "../src/assets/css/styles.css";
import "sweetalert2/dist/sweetalert2.min.css";

require('moment/locale/es')
moment.locale('es')

Vue.use(VueMoment, {moment})

Vue.filter('formatDate', function (value) {
  if (!value) return ''
  value = value.toString()
  return moment(value).format('dd Do MMM');
})

library.add(fas);

Vue.component("font-awesome-icon", FontAwesomeIcon);

require("@/store/subscriber");

// axios.defaults.baseURL = process.env.VUE_APP_API_SERVER;
axios.defaults.baseURL = process.env.VUE_APP_API_SERVER;
//axios.defaults.baseURL = "https://dev-api-promotoria.iusa.com.mx/api/";
//axios.defaults.baseURL = "http://127.0.0.1:8000/api/";

Vue.config.productionTip = process.env.VUE_APP_PRODUCTION;

//Vue.prototype.$hostImage = "https://dev-api-promotoria.iusa.com.mx/";
Vue.prototype.$hostImage = process.env.VUE_APP_BASE_SERVER;
Vue.prototype.$captchaKey = process.env.VUE_APP_GOOGLE_RECATPCHA;
Vue.prototype.$iusaBaseUrl = process.env.VUE_APP_BASE_IUSA_SERVER;
Vue.prototype.$iusaApiUrl = process.env.VUE_APP_API_IEL_SERVER;

Vue.use(VueSweetalert2, VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPSKEY,
    libraries: "places",
  },
});

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
  Vue.prototype.$serveName = "dev.promotoria";
});

export const eventBus = new Vue();