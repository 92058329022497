<script>
import { Pie, mixins } from "vue-chartjs";
export default {
  extends: Pie,
  props: {
    chartData: {
      type: Object,
      default: null,
    },
  },
  mixins: [mixins.reactiveProp],
  data() {
    return {
      options: {
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>