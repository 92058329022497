export default {
  namespaced: true,
  state: {
    form1valid: false,
    form2valid: false,
    form3valid: false,
    form: {
      person_type: null,
      fiscal: {
        request_credit: null,
        line_request_credit: null,
        delivery_fiscal_addres: null,
      },
    },
  },

  getters: {
    formValid(state) {
      return state.formValid;
    },
    form1valid(state) {
      return state.form1valid;
    },
    form2valid(state) {
      return state.form2valid;
    },
    form3valid(state) {
      return state.form3valid;
    },
    canSendForm(state) {
      return state.form1valid && state.form2valid && state.form3valid;
    },
    formState(state) {
      return state.form;
    },
    isMoralPerson(state) {
      return state.form.person_type == "moral";
    },
    personType(state) {
      return state.form.person_type;
    },
    requestCredit(state) {
      return state.form.fiscal.request_credit;
    },
    lineRequestCredit(state) {
      return state.form.fiscal.line_request_credit;
    },
    deliveryFiscalAddres(state) {
      return state.form.fiscal.delivery_fiscal_addres;
    },
    isRequestingCredit(state) {
      return state.form.fiscal.request_credit == "Si";
    },
    showAddresses(state) {
      return state.form.fiscal.delivery_fiscal_addres == "No";
    },
  },

  mutations: {
    SET_FORM1_VALID(state, valid) {
      state.form1valid = valid;
    },
    SET_FORM2_VALID(state, valid) {
      state.form2valid = valid;
    },
    SET_FORM3_VALID(state, valid) {
      state.form3valid = valid;
    },
    updatePersonType(state, data) {
      state.form.person_type = data;
    },
    updateFiscalRequestCredit(state, data) {
      state.form.fiscal.request_credit = data;
    },
    updateLineRequestCredit(state, data) {
      state.form.fiscal.line_request_credit = data;
    },
    cleanData(state) {
      state.form.person_type = null;
      state.form.fiscal.request_credit = null;
      state.form.fiscal.line_request_credit = null;
    },
    UPDATE_FISCAL_FORM_FIELD(state, { field, value }) {
      state.form.fiscal[field] = value;
    },
  },
};
