<template>
  <v-form ref="addressForm" v-model="valid" lazy-validation>
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-select
            :items="['fisica', 'moral']"
            label="Tipo"
            v-model="form.person_type"
            :rules="[(v) => !!v || 'Tipo de persona es requerido']"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.name"
            label="Razón social"
            required
            :rules="[(v) => !!v || 'El nombre es requerido']"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.rfc"
            :counter="rfcCounter"
            label="RFC"
            required
            :rules="[
              (v) => !!v || 'El RFC es requerido',
              (v) =>
                (v && v.length == rfcCounter) ||
                'El número de caracteres es incorrecto',
            ]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            label="Código postal"
            v-model="form.postal_code"
            :rules="[(v) => !!v || 'El código postal es requerido']"
          >
            <template v-slot:append>
              <v-btn
                icon
                :disabled="!validCP"
                color="primary"
                @click="searchCP"
              >
                <v-icon>mdi-crosshairs-gps</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Estado"
            v-model="form.state"
            :rules="[(v) => !!v || 'El estado es requerido']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Delegación/Municipio"
            v-model="form.town"
            :rules="[(v) => !!v || 'El municipio/delegación es requerido']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            :items="suburbs"
            label="Colonia"
            v-model="form.suburb"
            :rules="[(v) => !!v || 'La colonia es requerida']"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.street"
            label="Calle"
            required
            :rules="[(v) => !!v || 'La calle es requerido']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Núm Ext"
            v-model="form.outdoor_number"
            :rules="[(v) => !!v || 'El número exterior es requerido']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Núm Int"
            v-model="form.interior_number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Contacto"
            v-model="form.contact_name"
            :rules="[(v) => !!v || 'El nombre de contacto es requerido']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Teléfono"
            required
            v-model="form.contact_phone"
            :rules="[(v) => !!v || 'El teléfono de contacto es requerido']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-btn @click="cancelAddress" class="mr-2">Cancelar</v-btn>
          <v-btn color="primary" @click="sendAddress">Guardar</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from "axios";
import { eventBus } from "@/main";

export default {
  name: "CustomerAddresses",
  props: ["dataAddress", "submitAddress", "cancelSubmitAddress"],
  data: () => ({
    valid: false,
    suburbs: [],
    form: {
      person_type: null,
      name: null,
      rfc: "",
      postal_code: "",
      state: null,
      town: null,
      suburb: null,
      street: null,
      outdoor_number: null,
      interior_number: null,
      contact_name: null,
      contact_phone: null,
    },
  }),
  computed: {
    validCP() {
      return this.form.postal_code?.length == 5;
    },
    rfcCounter() {
      return this.form.person_type == "fisica" ? 13 : 12;
    },
  },
  mounted() {
    if(this.dataAddress) {
      this.form.person_type = this.dataAddress.person_type;
      this.form.name = this.dataAddress.name;
      this.form.rfc = this.dataAddress.rfc;
      this.form.postal_code = this.dataAddress.postal_code;
      this.form.state = this.dataAddress.state;
      this.form.town = this.dataAddress.town;
      this.form.suburb = this.dataAddress.suburb;
      this.form.street = this.dataAddress.street;
      this.form.outdoor_number = this.dataAddress.outdoor_number;
      this.form.interior_number = this.dataAddress.interior_number;
      this.form.contact_name = this.dataAddress.contact_name;
      this.form.contact_phone = this.dataAddress.contact_phone;
    }
    eventBus.$on("CustomerAddresses::resetForm", () => {
      this.$refs.addressForm.reset();
    });
  },
  methods: {
    cancelAddress() {
      this.$refs.addressForm.resetValidation();
      this.$refs.addressForm.reset();
      this.cancelSubmitAddress();
    },
    async sendAddress() {
      await this.$refs.addressForm.validate();

      if (this.valid) {
        if(this.form.rfc.length != this.rfcCounter) {
          return this.$swal({
            title: "El rfc no cumple con los caracteres requeridos",
            icon: "warning",
            type: "warning"
          });
        }
        this.form.id = new Date().getTime();
        this.submitAddress(JSON.parse(JSON.stringify(this.form)));
      }

    },
    searchCP() {
      axios.post(`${process.env.VUE_APP_API_IEL_SERVER}/searchPostalCode`, { postal_code: this.form.postal_code })
        .then((response) => {
          this.form.state = response.data.a_estate;
          this.form.town = response.data.a_town;
          this.suburbs = response.data.a_suburb;

          if (this.suburbs.length == 1) {
            this.form.suburb = this.suburbs[0];
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            title: "Error al consultar el código postal.",
            icon: "error",
            type: "error",
          });
        });
    },
  },
};
</script>
