<template>
  <v-card class="mx-auto rounded-xl elevation-5 ma-4 ml-4 mr-4" outlined>
    <v-card-title>
      Observaciones
      <v-spacer></v-spacer>
      <v-btn color="error" @click="close">Cerrar</v-btn>
    </v-card-title>
    <v-card-text>
      <v-timeline align-top dense>
        <v-timeline-item
          v-for="(comment, index) in comments"
          :key="`comments-${index}`"
          v-bind:value="comment"
          class="mb-4 mr-10"
          color="blue"
          icon-color="grey lighten-2"
          small
        >
          <template v-slot:icon>
            <span class="white--text" style="font-size: 12px">{{
              comment.word
            }}</span>
          </template>
          <v-row justify="space-between">
            <v-col cols="7" class="text-left">
              {{ comment.observations }}
              <br />
              <span class="text-timeline-user">{{
                comment.user == "Cliente"
                  ? "Vendedor -" + user.name
                  : comment.user
              }}</span>
            </v-col>
            <v-col class="text-right" cols="5">
              {{ comment.date }}
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" @click="close">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/main.js";

export default {
  name: "Comments",

  props: ["comments"],

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  methods: {
    close() {
      eventBus.$emit("IELAltaCliente::closeObservations", false);
    }
  }
};
</script>
