<template>
  <div>
    <v-card
      class="mx-auto rounded-lg elevation-4 mb-4 ml4"
      outlined
      :style="'border: 4px solid #1086D4;'"
    >
      <v-list-item-content>
        <div class="text-primary text-desp" style="text-align: center">
          <v-icon style="color: #1086d4 !important"
            >mdi-clock-time-five-outline</v-icon
          >
          HISTORICO DE COMPRAS
        </div>
      </v-list-item-content>
    </v-card>
    <v-card class="mx-auto rounded-xl elevation-5 ma-5" outlined>
      <v-data-table
        :headers="headerHistoryShopping"
        :items="dataHistoryShopping"
        :loading="(!dataHistoryShopping.length  && loading)"
        loading-text="Cargando... Por favor espere"
        :items-per-page="10"
        item-key="name"
        class="elevation-1 p-2"
        locale="esp"
        hide-default-footer
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
        }"
      >
      <template v-slot:[`item.HC_VAL1`]="{ item }">
          <div v-if="item.HC_TITULO == 'Renglones disponibles para pedido especial de PNC'">
            <a class="blue--text" @click="getpncList(1)">
              <span>{{ item.HC_VAL1 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Facturación Neta'">
            <a class="black--text" @click="getInvoiceList(1)">
              <span>{{ item.HC_VAL1 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglonaje caja/atado del trimestre al día' && item.HC_VAL1 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(1,1)">
              <span>{{ item.HC_VAL1 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones Nuevos en el trimestre al día (Total últimos 4 trims)' && item.HC_VAL1 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(2,1)">
              <span>{{ item.HC_VAL1 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones sin reorden en el trimestre al día' && item.HC_VAL1 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(3,1)">
              <span>{{ item.HC_VAL1 }}</span></a>
          </div>
          <div v-else>
            {{ item.HC_VAL1 }}
          </div>
        </template>
        <template v-slot:[`item.HC_VAL2`]="{ item }">
          <div v-if="item.HC_TITULO == 'Facturación Neta'">
            <a class="black--text" @click="getInvoiceList(2)">
              <span>{{ item.HC_VAL2 }}</span></a>
          </div> 
          <div v-else-if="item.HC_TITULO == 'Renglonaje caja/atado del trimestre al día' && item.HC_VAL2 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(1,2)">
              <span>{{ item.HC_VAL2 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones Nuevos en el trimestre al día (Total últimos 4 trims)' && item.HC_VAL2 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(2,2)">
              <span>{{ item.HC_VAL2 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones sin reorden en el trimestre al día' && item.HC_VAL2 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(3,2)">
              <span>{{ item.HC_VAL2 }}</span></a>
          </div>
          <div v-else>
            {{ item.HC_VAL2 }}
          </div>
        </template>
        <template v-slot:[`item.HC_VAL3`]="{ item }">
          <div v-if="item.HC_TITULO == 'Facturación Neta'">
            <a class="black--text" @click="getInvoiceList(3)">
              <span>{{ item.HC_VAL3 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglonaje caja/atado del trimestre al día' && item.HC_VAL3 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(1,3)">
              <span>{{ item.HC_VAL3 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones Nuevos en el trimestre al día (Total últimos 4 trims)' && item.HC_VAL3 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(2,3)">
              <span>{{ item.HC_VAL3 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones sin reorden en el trimestre al día' && item.HC_VAL3 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(3,3)">
              <span>{{ item.HC_VAL3 }}</span></a>
          </div>
          <div v-else>
            {{ item.HC_VAL3 }}
          </div>
        </template>
        <template v-slot:[`item.HC_VAL4`]="{ item }">
          <div v-if="item.HC_TITULO == 'Facturación Neta'">
            <a class="black--text" @click="getInvoiceList(4)">
              <span>{{ item.HC_VAL4 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglonaje caja/atado del trimestre al día' && item.HC_VAL4 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(1,4)">
              <span>{{ item.HC_VAL4 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones Nuevos en el trimestre al día (Total últimos 4 trims)' && item.HC_VAL4 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(2,4)">
              <span>{{ item.HC_VAL4 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones sin reorden en el trimestre al día' && item.HC_VAL4 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(3,4)">
              <span>{{ item.HC_VAL4 }}</span></a>
          </div>
          <div v-else>
            {{ item.HC_VAL4 }}
          </div>
        </template>
        <template v-slot:[`item.HC_VAL5`]="{ item }">
          <div v-if="item.HC_TITULO == 'Facturación Neta'">
            <a class="black--text" @click="getInvoiceList(5)">
              <span>{{ item.HC_VAL5 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglonaje caja/atado del trimestre al día' && item.HC_VAL5 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(1,5)">
              <span>{{ item.HC_VAL5 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones Nuevos en el trimestre al día (Total últimos 4 trims)' && item.HC_VAL5 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(2,5)">
              <span>{{ item.HC_VAL5 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones sin reorden en el trimestre al día' && item.HC_VAL5 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(3,5)">
              <span>{{ item.HC_VAL5 }}</span></a>
          </div>
          <div v-else>
            {{ item.HC_VAL5 }}
          </div>
        </template>
        <template v-slot:[`item.HC_VAL6`]="{ item }">
          <div v-if="item.HC_TITULO == 'Facturación Neta'">
            <a class="black--text" @click="getInvoiceList(6)">
              <span>{{ item.HC_VAL6 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglonaje caja/atado del trimestre al día' && item.HC_VAL6 > 0">

            <a class="black--text text-decoration-underline" @click="getHistoryReng(1,6)">
              <span>{{ item.HC_VAL6 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones Nuevos en el trimestre al día (Total últimos 4 trims)' && item.HC_VAL6 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(2,6)">
              <span>{{ item.HC_VAL6 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones sin reorden en el trimestre al día' && item.HC_VAL6 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(3,6)">
              <span>{{ item.HC_VAL6 }}</span></a>
          </div>
          <div v-else>
            {{ item.HC_VAL6 }}
          </div>
        </template>
        <template v-slot:[`item.HC_VAL7`]="{ item }">
          <div v-if="item.HC_TITULO == 'Facturación Neta'">
            <a class="black--text" @click="getInvoiceList(7)">
              <span>{{ item.HC_VAL7 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglonaje caja/atado del trimestre al día' && item.HC_VAL7 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(1,7)">
              <span>{{ item.HC_VAL7 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones Nuevos en el trimestre al día (Total últimos 4 trims)' && item.HC_VAL7 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(2,7)">
              <span>{{ item.HC_VAL7}}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones sin reorden en el trimestre al día' && item.HC_VAL7 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(3,7)">
              <span>{{ item.HC_VAL7}}</span></a>
          </div>
          <div v-else>
            {{ item.HC_VAL7 }}
          </div>
        </template>
        <template v-slot:[`item.HC_VAL8`]="{ item }">
          <div v-if="item.HC_TITULO == 'Facturación Neta'">
            <a class="black--text" @click="getInvoiceList(8)">
              <span>{{ item.HC_VAL8 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglonaje caja/atado del trimestre al día' && item.HC_VAL8 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(1,8)">
              <span>{{ item.HC_VAL8}}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones Nuevos en el trimestre al día (Total últimos 4 trims)' && item.HC_VAL8 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(2,8)">
              <span>{{ item.HC_VAL8 }}</span></a>
          </div>
          <div v-else-if="item.HC_TITULO == 'Renglones sin reorden en el trimestre al día' && item.HC_VAL8 > 0">
            <a class="black--text text-decoration-underline" @click="getHistoryReng(3,8)">
              <span>{{ item.HC_VAL8 }}</span></a>
          </div>
          <div v-else>
            {{ item.HC_VAL8 }}
          </div>
        </template>
      </v-data-table>
    </v-card>

    <!-- /. Dialog -->
    <v-dialog v-model="dialog_pnc" width="auto">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Productos
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <br />
              <v-btn depressed large color="primary" block @click="generatePDF">
                Productos PNC/PC PDF &nbsp;<v-icon> mdi-file-pdf-box</v-icon>
              </v-btn>
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col md="6" style="text-align: center">
              <p><b>Productos No Comprados</b></p>
              <v-progress-linear
                :active="loading"
                :indeterminate="loading"
                top
                color="deep-purple accent-4"
              ></v-progress-linear>
              <v-data-table
                dense
                :headers="header_pnc"
                :items="dataItem"
                :items-per-page="5"
                :search="search"
                item-key="MAKTX"
                no-data-text="No hay datos disponibles"
              >
              </v-data-table>
            </v-col>
            <v-col md="6" style="text-align: center">
              <p><b>Productos Comprados</b></p>
              <v-data-table
                dense
                :headers="header_pnc"
                :items="datapc"
                :items-per-page="5"
                :search="search"
                item-key="MAKTX"
                no-data-text="No hay datos disponibles"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="close()"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /. Dialog -->
    <v-dialog v-model="dialog_reg" width="auto" max-width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Productos
        </v-card-title>
        <v-card-text>
          <v-row> 
            <v-col md="12">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line
                hide-details></v-text-field>
            </v-col>
          </v-row> 
          <v-row>
            <v-col md="12">
              <p><b>Productos No Comprados</b></p>  
                <v-data-table dense  :headers="headersReg" :items="dataReg" :items-per-page="5" :search="search"
                :loading="loading"   loading-text="Cargando... Por favor espere" no-data-text="No hay datos disponibles"> 
              </v-data-table>  
            </v-col> 
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialog_reg=false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /. Dialog -->
    <v-dialog v-model="dialog_inv" width="auto">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Facturación Neta
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <br />
              <v-btn
                depressed
                large
                color="primary"
                block
                @click="generatePDFInv"
              >
                Facturación Neta PDF &nbsp;<v-icon> mdi-file-pdf-box</v-icon>
              </v-btn>
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              top
              color="deep-purple accent-4"
            ></v-progress-linear>
            <v-data-table
              dense
              :headers="header_inv"
              :items="dataInv"
              :items-per-page="5"
              :search="search"
              item-key="NETWR1"
              no-data-text="No hay datos disponibles"
            >
            </v-data-table>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="close2()"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /. Dialog -->
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  props: {
    clientCurrent: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      dataHistoryShopping: [],
      headerHistoryShopping: [],
      data: null,
      dataItem: [],
      dataReg: [],
      dataInv: [],
      datapc: [],
      datacustumer: null,
      dialog_pnc: false,
      dialog_inv: false,
      dialog_reg: false,
      dataInvo: null,
      loading: true,
      quarter: null,
      search: "",
      header_pnc: [
        { text: "Código", align: "center", value: "MATNR" },
        { text: "Material", align: "center", value: "MAKTX" },
        { text: "Empaque", align: "center", value: "MEINS" },
      ],
      header_inv: [
        { text: "Factura", align: "center", value: "VBELN" },
        { text: "Neto", align: "center", value: "NETWR1" },
      ],
      headersReg:[
        { text: "Material", align: "center", value: "MATNR" },
        { text: "Descripcion", align: "center", value: "MAKTX" },
      ]
    };
  },
  async mounted() {
    let self = this;
    if (localStorage.getItem("1us4-pv-cl13nt")) {
      self.getHistoryShopping();
      self.cliente();
      self.pcList();
    }
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    getHistoryReng(type,trim){
     var params = {
        'customer': JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
        'VTWEG':JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).VTWEG,
        'trim':trim,
        'type':type
      } 
      this.dialog_reg = true; 
      this.loading =true;
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/history/reng",params)
        .then((response) => { 
          if (!response.data.FNMSG)
          this.dataReg = response.data 
        })
        .catch((error) => { 
          console.log(error);
        }).finally(() => (this.loading = false));

    },
    getHistoryShopping() {
      this.dataHistoryShopping=[];
      this.headerHistoryShopping=[];
      this.loading=true;
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/history-shopping", {
          client: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
          channel: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).VTWEG,
        })
        .then((response) => {
          console.info(response);
          var headerCurrent = response.data.header;
          this.dataHistoryShopping = response.data.data;
          if (this.dataHistoryShopping.length > 0) {
            this.headerHistoryShopping = [
              {
                text: headerCurrent[0].HC_TITULO,
                align: "start",
                value: "HC_TITULO",
              },
              { text: headerCurrent[0].HC_VAL1, value: "HC_VAL1" },
              { text: headerCurrent[0].HC_VAL2, value: "HC_VAL2" },
              { text: headerCurrent[0].HC_VAL3, value: "HC_VAL3" },
              { text: headerCurrent[0].HC_VAL4, value: "HC_VAL4" },
              { text: headerCurrent[0].HC_VAL5, value: "HC_VAL5" },
              { text: headerCurrent[0].HC_VAL6, value: "HC_VAL6" },
              { text: headerCurrent[0].HC_VAL7, value: "HC_VAL7" },
              { text: headerCurrent[0].HC_VAL8, value: "HC_VAL8" },
            ];
          }
        })
        .catch((e) => {
          console.warn(e);
        });
    },
    close() {
      this.dialog_pnc = false;
      //this.dataItem = [];
    },
    close2() {
      this.dialog_inv = false;
      //this.dataInv = [];
    },
    format(value) {
      let result = Number(value).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getpncList(trim) {
      //var trim = 1;
      this.dialog_pnc = true;
      this.dataItem = [];
      this.loading = true;
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/pncList", {
          client: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
          VTWEG: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).VTWEG,
          TRIM: trim,
        })
        .then((response) => {
          this.data = response.data;
          let info = this.data.length;
          for (let i = 0; i < info; i++) {
            this.dataItem.push({
              MATNR: this.data[i].MATNR,
              MAKTX: this.data[i].MAKTX,
              MEINS: this.data[i].MEINS,
            });
          }
        })
        .catch((error) => {
          // alert(error);
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    getInvoiceList(trim) {
      this.dialog_inv = true;
      this.dataInv = [];
      this.loading=true;
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/netBilling", {
          client: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
          VTWEG: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).VTWEG,
          TRIM: trim,
        })
        .then((response) => {
          this.dataInvo = response.data;
          let info = this.dataInvo.length;
          for (let i = 0; i < info; i++) {
            this.dataInv.push({
              VBELN: this.dataInvo[i].VBELN,
              NETWR1: this.format(this.dataInvo[i].NETWR1),
            });
          }
        })
        .catch((error) => {
          // alert(error);
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    cliente() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/infoCustomer", {
          customer: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
          VKORG: "IUS2",
          VTWEG: "DT",
          VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
        })
        .then((response) => {
          this.datacustumer = response.data;
          console.log(response.data);
        });
    },
    pcList() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/pcList", {
          client: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
          VTWEG: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).VTWEG,
          TRIM: 1,
        })
        .then((response) => {
          this.data = response.data;
          let info = this.data.length;
          for (let i = 0; i < info; i++) {
            this.datapc.push({
              MATNR: this.data[i].MATNR,
              MAKTX: this.data[i].MAKTX,
              MEINS: this.data[i].MEINS,
            });
          }
        })
        .catch((error) => {
          // alert(error);
          console.log(error);
        });
    },
    generatePDF() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });

      var img = new Image();
      img.src = "img/header_p.png";

      var footer = new Image();
      footer.src = "img/footer.png";
      doc.setFontSize(10);
      doc.addImage(img, "png", 0.5, 0.5, 7.5, 0.8);

      doc.autoTable({
        styles: {
          fillColor: [255, 255, 255],
        },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        columns: [
          { header: "", dataKey: "title" },
          { header: "", dataKey: "content" },
        ],
        body: [
          { title: "Cliente:", content: this.datacustumer.KUNNR },
          { title: "Nombre:", content: this.datacustumer.NAME1 },
          { title: "RFC:", content: this.datacustumer.STCD1 },
          { title: "Email:", content: this.datacustumer.SMTP_ADDR },
          { title: "Teléfono:", content: this.datacustumer.TELF1 },
        ],
        columnStyles: {
          title: { fontStyle: "bold" },
        },
        margin: { left: 0.5, top: 1.5 },
      });

      doc.autoTable({
        styles: { halign: "center", fillColor: [255, 255, 255] },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        tableLineColor: [169, 169, 169], 
        tableLineWidth: 0.01,
        theme:"grid",
        columns: [
          { header: "Código", dataKey: "MATNR" },
          { header: "Materiales Comprados", dataKey: "MAKTX" },
          { header: "Empaque", dataKey: "MEINS" },
        ],
        body: this.datapc,
        headStyles: {
          fillColor: [60, 179, 113],
          textColor: [255, 255, 255],
          lineColor: [169, 169, 169],
          lineWidth: 0.01,
        },
        bodyStyles: {
          lineColor: [169, 169, 169],
          lineWidth: 0.01,
        },
        margin: { left: 0.5, top: 1.5 },
      });

      doc.addPage();

      doc.autoTable({
        styles: { halign: "center", fillColor: [255, 255, 255] },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        tableLineColor: [169, 169, 169],
        tableLineWidth: 0.01,
        theme: "grid",
        columns: [
          { header: "Código", dataKey: "MATNR" },
          { header: "Materiales No Comprados", dataKey: "MAKTX" },
          { header: "Empaque", dataKey: "MEINS" },
        ],
        body: this.dataItem,
        headStyles: {
          fillColor: [255, 99, 71],
          textColor: [255, 255, 255],
          lineColor: [169, 169, 169],
          lineWidth: 0.01,
        },
        bodyStyles: {
          lineColor: [169, 169, 169],
          lineWidth: 0.01,
        },
        margin: { left: 0.5, top: 1.5 },
      });

      doc.addImage(footer, "png", 0.5, 9.8, 7.5, 0.6);
      doc.save("Productos.pdf");
    },
    generatePDFInv() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });

      var img = new Image();
      img.src = "img/header_i.png";

      var footer = new Image();
      footer.src = "img/footer.png";
      doc.setFontSize(10);
      doc.addImage(img, "png", 0.5, 0.5, 7.5, 0.8);

      doc.autoTable({
        styles: {
          fillColor: [255, 255, 255],
        },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        columns: [
          { header: "", dataKey: "title" },
          { header: "", dataKey: "content" },
        ],
        body: [
          { title: "Cliente:", content: this.datacustumer.KUNNR },
          { title: "Nombre:", content: this.datacustumer.NAME1 },
          { title: "RFC:", content: this.datacustumer.STCD1 },
          { title: "Email:", content: this.datacustumer.SMTP_ADDR },
          { title: "Teléfono:", content: this.datacustumer.TELF1 },
        ],
        columnStyles: {
          title: { fontStyle: "bold" },
        },
        margin: { left: 0.5, top: 1.5 },
      });
      doc.autoTable({
        styles: { halign: "center", fillColor: [255, 255, 255] },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        tableLineColor: [169, 169, 169],
        tableLineWidth: 0.01,
        theme: "grid",
        columns: [
          { header: "Factura", dataKey: "VBELN" },
          { header: "Neto", dataKey: "NETWR1" },
        ],
        body: this.dataInv,
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineColor: [169, 169, 169],
          lineWidth: 0.01,
        },
        bodyStyles: {
          lineColor: [169, 169, 169],
          lineWidth: 0.01,
        },
        margin: { left: 0.5, top: 1.5 },
      });

      doc.addImage(footer, "png", 0.5, 9.8, 7.5, 0.6);
      doc.save("Facturacion_neta.pdf");
    },
  },
  watch: {
    clientCurrent: function () {
      let self = this;
      self.getHistoryShopping();
      self.cliente();
    },
    dialog_reg(value){
      if (!value){
        this.dataReg=[];
      }
    },
    dialog_inv(value){
      if (!value){
        this.dataInv=[]
      }
    }
  },
};
</script>
<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
