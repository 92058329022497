<template>
  <v-container fluid class="card-rounded">
    <v-card class="p-5">
      <v-row>
        <v-col class="text-center">
          <h2>Planeador IEL</h2>
        </v-col>
      </v-row>

      <!-- Slider Trimestres -->
      <v-row class="d-flex justify-content-center">
        <v-col cols="5">
          <weeks @dateRange="(data) => dateRange = data" @sendWeek="sendWeek"></weeks>
        </v-col>
      </v-row>
      <!-- END Slider Trimestres -->


      <!-- Resumen semana -->
        <resumen :semana="selectWeek" :clientes="clientes"></resumen>
        <!-- End Resumen Semana -->
    </v-card>
  </v-container>
</template>

<script>
 /* eslint-disable */
import { mapGetters } from "vuex";
import Weeks from './components/Weeks'
import Resumen from './components/Resumen'
import axios from "axios";

export default { 
  components: {Weeks, Resumen},
  data(){
    return {
      dateRange: '',
      selectWeek: '',
      clientes: []
    }
  },
  mounted(){    
    this.getCustomers()
  },
  computed: {
    ...mapGetters({
        user: 'auth/user'
    })
  },
  methods:{
    getCustomers(){
      this.clientes = []
      axios.get(process.env.VUE_APP_API_SERVER + '/planeador/getEventsPlanificador', {
        params:{
          employee_number:  this.user.employee_number
        }            
      }).then((response) => {
        this.clientes = response.data.data           
      })
      .catch((err) => {
        console.log(err);
      })
    },
    sendWeek(value){
      this.selectWeek = value
    },
  }
}
</script>
