<template>
  <div>
    <v-skeleton-loader
      v-bind="attrs"
      type="card-avatar, article, actions"
      v-if="data == null"
    ></v-skeleton-loader>
    <div v-else>
      <v-card
        class="mx-auto rounded-lg elevation-4"
        outlined
        :style="'border: 4px solid gray;'"
      >
        <v-list-item-content>
          <div class="text-general" style="text-align: center">
            <v-icon>mdi-account-circle</v-icon>
            DATOS CLIENTE:
          </div>
        </v-list-item-content>
      </v-card>

      <v-dialog
        v-model="dialogContact"
        scrollable
        transition="dialog-bottom-transition"
        :max-width="isMobile ? '100%' : '30%'"
      >
        >
        <v-card class="dialog-contact">
          <v-toolbar color="default" title="Contacto" class="header-dialog">
            <v-row>
              <v-col style="text-align: left" md="10">CONTACTO</v-col>
              <v-col md="2"
                ><a style="cursor: pointer" @click="dialogContact = false"
                  >X</a
                ></v-col
              >
            </v-row>
          </v-toolbar>
          <template v-if="dataContact.length > 0">
            <v-card-text v-for="c in dataContact" :key="c.id">
              <v-row style="margin-top: 0px">
                <v-col md="12"
                  ><h6 style="text-transform: capitalize">
                    {{ c.name + " " + c.last_name }}
                  </h6></v-col
                >
              </v-row>
              <v-row style="margin-top: 5px">
                <v-col cols="2" sm="2" md="2" lg="2"
                  ><v-icon style="color: red">mdi-email</v-icon></v-col
                >
                <v-col
                  style="text-align: left"
                  cols="10"
                  sm="10"
                  md="10"
                  lg="10"
                  >{{ c.email }}</v-col
                >
              </v-row>
              <v-row style="margin-top: 0px">
                <v-col cols="2" sm="2" md="2" lg="2"
                  ><v-icon style="color: red"
                    >mdi-cellphone-check</v-icon
                  ></v-col
                >
                <v-col style="text-align: left" cols="8" sm="8" md="8" lg="8">{{
                  c.phone
                }}</v-col>
                <v-col cols="2" sm="2" md="2" lg="2"
                  ><v-icon
                    v-if="c.main == 1"
                    style="color: red; margin-right: 0.5em"
                    >mdi-star</v-icon
                  ></v-col
                >
              </v-row>
            </v-card-text>
          </template>
          <template v-else>
            <v-card-text>Cargando contacto...</v-card-text>
          </template>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogReception"
        transition="dialog-bottom-transition"
        :max-width="isMobile ? '100%' : '30%'"
      >
        >
        <v-card class="card-without-scroll">
          <v-toolbar
            color="default"
            title="HORARIOS DE RECEPCIÓN"
            class="header-dialog"
          >
            <v-row>
              <v-col style="text-align: left" md="10"
                >HORARIOS DE RECEPCIÓN</v-col
              >
              <v-col md="2"
                ><a style="cursor: pointer" @click="dialogReception = false"
                  >X</a
                ></v-col
              >
            </v-row>
          </v-toolbar>
          <label for="" class="mb-3">Nombre del Establecimiento</label>
          <v-select
            v-on:change="changeReception"
            :items="dataReception"
            label="Selecciona Establecimiento"
            persistent-hint
            :item-text="displayText"
            item-value="reception.KUNNR"
          ></v-select
          ><br />
          <v-card-text>
            <v-row
              style="text-align: left"
              v-for="c in dataReceptionCurrent"
              :key="c.id"
            >
              <v-col cols="12" sm="12" md="12" lg="12">{{
                daytext(c.day)
              }}</v-col
              ><br />
              <template v-if="c.start == null">
                <v-col cols="2" sm="2" md="2" lg="2"
                  ><v-icon style="color: red">mdi-clock</v-icon></v-col
                >
                <v-col
                  style="text-align: left"
                  cols="10"
                  sm="10"
                  md="10"
                  lg="10"
                  >Sin recepción</v-col
                >
              </template>
              <template v-else>
                <v-col md="2"><v-icon>mdi-clock</v-icon></v-col>
                <v-col
                  style="text-align: left"
                  cols="10"
                  sm="10"
                  md="10"
                  lg="10"
                  >Recepción De: {{ c.start }} A {{ c.end }}</v-col
                >
              </template>
            </v-row>
            <v-row style="text-align: left" v-if="receptionLast">
              <v-col
                ><b>Fecha del último registro:</b> {{ receptionLast }}</v-col
              >
            </v-row>
            <v-row style="text-align: left" v-if="receptionLast">
              <v-col
                ><b>Documentos Requeridos:</b>
                <span v-if="documentos_requeridos == null"
                  >Sin Documentos Requeridos</span
                >
                <span v-else>{{ documentos_requeridos }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-card class="mx-auto rounded-xl elevation-5 ma-5" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4" style="text-align: center">
              <v-avatar size="100" style="border: solid 4px #ff000a">
                <img
                  src="@/assets/img/logo_iusa@2x.png"
                  alt="Cliente"
                  v-if="!data?.CUSTOMER?.profile?.image_url"
                />
                <img
                  :src="urlServer + data?.CUSTOMER?.profile?.image_url"
                  alt="Cliente"
                  v-else
                />
              </v-avatar>
            </div>
            <v-list-item-title class="text-h5 mb-1" style="text-align: center">
              {{ nameCurrent }}
              <v-list-item-subtitle>
                {{ customerCodeCurrent }}
              </v-list-item-subtitle>
              <br />
            </v-list-item-title>
            <br />

            <p class="text-justify ma-0 mb-5">
              <strong>RFC:</strong>{{ rfcCurrent }}
            </p>

            <v-row class="list-sucu-mobile">
              <v-col cols="4" sm="4" md="4" lg="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" style="color: #ae388b"
                      >mdi-store-marker</v-icon
                    >
                  </template>
                  <span
                    ><strong>Sucursal: </strong>{{ data.VKBUR }} -
                    {{ data.VKBUR_TXT }}</span
                  >
                </v-tooltip>
              </v-col>
              <v-col cols="4" sm="4" md="4" lg="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" style="color: #ae388b"
                      >mdi-calendar-arrow-left</v-icon
                    >
                  </template>
                  <span><strong>Antigüedad:</strong> {{ data.ERDAT }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="4" sm="4" md="4" lg="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" style="color: #ae388b"
                      >mdi-cake-variant</v-icon
                    >
                  </template>
                  <span
                    ><strong>Cumpleaños:</strong>
                    {{ data?.CUSTOMER?.profile?.birthday }}</span
                  >
                </v-tooltip>
              </v-col>
            </v-row>
            <br />
            <v-row class="list-sucu-mobile">
              <v-col cols="4" sm="4" md="4" lg="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" style="color: #ae388b"
                      >mdi-sitemap</v-icon
                    >
                  </template>
                  <span><strong>Canal: </strong>{{ canalCurrent }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="4" sm="4" md="4" lg="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" style="color: #ae388b"
                      >mdi-map-marker-radius</v-icon
                    >
                  </template>
                  <span><strong>Region:</strong> {{ data.REGIO }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="4" sm="4" md="4" lg="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" style="color: #ae388b"
                      >mdi-map-marker</v-icon
                    >
                  </template>
                  <span
                    ><strong>Municipio:</strong>
                    {{ data.CUSTOMER?.a_suburb }}</span
                  >
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row
              class="list-sucu-desktop"
              style="margin-bottom: 0px; width: 100%"
            >
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-row style="width: 100%">
                  <v-col cols="2" sm="2" md="2" lg="2">
                    <p class="text-justify ma-0">
                      <v-icon style="color: #ae388b">mdi-sitemap</v-icon>
                    </p>
                  </v-col>
                  <v-col cols="10" sm="10" md="10" lg="10">
                    <p class="text-justify ma-0">
                      <strong>Canal:</strong><br />{{ canalCurrent }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-row style="width: 100%">
                  <v-col cols="2" sm="2" md="2" lg="2">
                    <p class="text-justify ma-0">
                      <v-icon style="color: #ae388b">mdi-store-marker</v-icon>
                    </p>
                  </v-col>
                  <v-col cols="10" sm="10" md="10" lg="10">
                    <p class="text-justify ma-0">
                      <strong>Sucursal:</strong><br />{{ data.VKBUR }} -
                      {{ data.VKBUR_TXT }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              class="list-sucu-desktop"
              style="margin-top: 5px; width: 100%"
            >
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-row style="width: 100%">
                  <v-col cols="2" sm="2" md="2" lg="2">
                    <p class="text-justify ma-0">
                      <v-icon style="color: #ae388b"
                        >mdi-map-marker-radius</v-icon
                      >
                    </p>
                  </v-col>
                  <v-col cols="10" sm="10" md="10" lg="10">
                    <p class="text-justify ma-0">
                      <strong>Region:</strong><br />{{ data.REGIO }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-row style="width: 100%">
                  <v-col cols="2" sm="2" md="2" lg="2">
                    <p class="text-justify ma-0">
                      <v-icon style="color: #ae388b">mdi-map-marker</v-icon>
                    </p>
                  </v-col>
                  <v-col cols="10" sm="10" md="10" lg="10">
                    <p class="text-justify ma-0">
                      <strong>Municipio:</strong><br />{{ data.CITY2 }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              class="list-sucu-desktop"
              style="margin-top: 5px; width: 100%"
            >
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-row style="width: 100%">
                  <v-col cols="2" sm="2" md="2" lg="2">
                    <p class="text-justify ma-0">
                      <v-icon style="color: #ae388b"
                        >mdi-calendar-arrow-left</v-icon
                      >
                    </p>
                  </v-col>
                  <v-col cols="10" sm="10" md="10" lg="10">
                    <p class="text-justify ma-0">
                      <strong>Antigüedad:</strong><br />{{ data.ERDAT }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-row style="width: 100%">
                  <v-col cols="2" sm="2" md="2" lg="2">
                    <p class="text-justify ma-0">
                      <v-icon style="color: #ae388b">mdi-cake-variant</v-icon>
                    </p>
                  </v-col>
                  <v-col cols="10" sm="10" md="10" lg="10">
                    <p class="text-justify ma-0">
                      <span
                        ><strong>Cumpleaños:</strong><br />{{
                          data?.CUSTOMER?.profile?.birthday
                        }}</span
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <br />
            <p class="text-justify mb-5 ma-0">
              <strong>Domicilio Fiscal:</strong>
            </p>
            <br />
            <p class="text-justify mb-4 ma-0">
              {{ data.ORT01 }} {{ data.POST_CODE1 }} {{ data.STREET }}
              {{ data.HOUSE_NUM1 }} {{ data.CITY2 }}
            </p>
            <br />
            <p class="text-justify mb-5 ma+5">
              <strong>Domicilio de Entrega:</strong> {{ data.ORT01 }}
              {{ data.POST_CODE1 }} {{ data.STREET }} {{ data.HOUSE_NUM1 }}
              {{ data.CITY2 }}
            </p>
            <br />
            <p class="text-justify mb-7">
              {{ destiny?.ORT01 }} {{ destiny?.PSTLZ }} {{ destiny?.STRAS }}
            </p>
            <p>
              <GmapMap
                v-if="destiny[0]?.GEOLOCATION"
                :center="destiny[0].GEOLOCATION"
                :zoom="20"
                style="width: 100%; height: 100px"
              >
                <GmapMarker
                  :position="destiny[0].GEOLOCATION"
                  @click="center = center"
                />
              </GmapMap>
            </p>

            <div v-if="fiscalCustomerdata" class="row">
              <div
                v-if="
                  fiscalCustomerdata.request_credit == 'Si' && docCustomerdata
                "
              >
                <div class="col-12 text-justify">
                  <a
                    :href="apiUrl + '/' + docCustomerdata.promissory_note"
                    class="nav-link"
                    style="color: #000"
                    target="_blank"
                  >
                    <v-icon class="text-danger h5 mt-1"
                      >mdi-file-pdf-box</v-icon
                    >
                    <strong>Pagaré: </strong>
                  </a>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div
                      v-if="docCustomerdata.promissory_note_amount"
                      class="col-md-6 col-12 text-justify"
                    >
                      <strong>Monto: </strong>
                      $
                      {{ formatPrice(docCustomerdata.promissory_note_amount) }}
                    </div>
                    <div
                      v-if="docCustomerdata.promissory_note_date"
                      class="col-md-6 col-12 text-center"
                    >
                      <strong>Fecha: </strong>
                      {{ docCustomerdata.promissory_note_date }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  docCustomerdata &&
                  fiscalCustomerdata &&
                  fiscalCustomerdata.request_credit == 'Si' &&
                  fiscalCustomerdata.line_request_credit > 50000
                "
                class="col-12 text-justify"
              >
                <strong>Aval: </strong>
                <label for="" class="text-uppercase"
                  >{{ docCustomerdata.bank_guarantee_name }}
                </label>
              </div>
            </div>

            <div style="color: red !important">
              <v-icon style="color: red">mdi-email</v-icon>
              <span>{{ emailCurrent }}</span>
            </div>

            <div style="color: blue !important">
              <a
                ><v-icon style="color: blue">mdi-cellphone</v-icon>
                <span>{{ phoneCurrent }}</span></a
              >
            </div>

            <v-layout justify-center>
              <v-switch v-model="switch1" class="green-label"
                ><template v-slot:label>
                  <span class="pr-2"> El número tiene Whatsapp </span>
                  <v-icon style="color: #25d366">mdi-whatsapp</v-icon>
                </template></v-switch
              > </v-layout
            ><br /><br />
            <v-list-item three-line>
              <v-list-item-content>
                <a
                  v-if="dataReception.length > 0"
                  title="Establecimientos"
                  style="cursor: pointer"
                  v-on:click="dialogReception = true"
                  ><v-icon style="color: #ae388b"
                    >mdi-home-clock-outline</v-icon
                  ></a
                >
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-home-clock-outline</v-icon>
                  </template>
                  <span>Sin horarios definidos</span>
                </v-tooltip>
              </v-list-item-content>
              <v-list-item-content>
                <a
                  v-if="dataContact.length > 0"
                  title="Contactos"
                  style="cursor: pointer"
                  v-on:click="dialogContact = true"
                  ><v-icon style="color: #ae388b"
                    >mdi-account-multiple</v-icon
                  ></a
                >
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-account-multiple</v-icon>
                  </template>
                  <span>Sin Contactos</span>
                </v-tooltip>
              </v-list-item-content>
              <v-list-item-content>
                <a
                  v-if="items.length > 0"
                  title="Fotos del negocio"
                  style="cursor: pointer"
                  @click="index = 0"
                  ><v-icon style="color: #ae388b">mdi-image-multiple</v-icon></a
                >
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-image-multiple</v-icon>
                  </template>
                  <span>Sin Imágenes</span>
                </v-tooltip>
              </v-list-item-content>
            </v-list-item>
            <div class="text-center">
              <router-link
                :to="{ name: 'ProfileUser' }"
                class="nav-link active"
              >
                <v-btn
                  rounded
                  block
                  style="
                    background-color: #2fd410;
                    color: #fff;
                    text-transform: none;
                    font-weight: 300;
                  "
                >
                  Editar
                </v-btn>
              </router-link>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </div>

    <CoolLightBox :items="items" :index="index" @close="index = null">
    </CoolLightBox>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  data() {
    return {
      apiUrl: process.env.VUE_APP_BASE_SERVER,
      documentos_requeridos: "",
      data: null,
      index: null,
      canalCurrent: null,
      fiscalCustomerdata: null,
      docCustomerdata: null,
      switch1: true,
      destiny: null,
      toggle: false,
      countdata: null,
      customerDestinyList: [],
      dialogContact: false,
      dialogFacade: false,
      dialogReception: false,
      dialogGallery: false,
      dataContact: [],
      dataReception: [],
      dataReceptionCurrent: [],
      galleryPrincipal: {
        id: 0,
        caption: null,
        src: null,
        thumb: null,
      },
      fachada: {
        id: 0,
        caption: null,
        src: null,
        thumb: null,
      },
      interior: {
        id: 0,
        caption: null,
        src: null,
        thumb: null,
      },
      mostrador: {
        id: 0,
        caption: null,
        src: null,
        thumb: null,
      },
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      urlServer: process.env.VUE_APP_BASE_IEL_SERVER + "/",
      dataDocument: {},
      media: [],
      items: [],
      receptionLast: "",
      isMobile: false,
      emailCurrent: "",
      phoneCurrent: "",
      imageCurrent: "",
      rfcCurrent: "",
      nameCurrent: "",
      customerCodeCurrent: "",
    };
  },
  components: {
    CoolLightBox,
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },

  mounted() {
    window.addEventListener("resize", this.checkMobile);

    if (localStorage.getItem("1us4-pv-cl13nt")) {

      this.client = localStorage.getItem("1us4-pv-cl13nt");
      this.getCustomerInformation();
      this.getDestinyCustomer();
      this.canalCurrent = JSON.parse(
        localStorage.getItem("1us4-pv-cl13nt")
      ).VTWEG;
      
      this.checkMobile();
    }
  },

  methods: {
    displayText(item) {
      console.info("disply => ", item);
      return `${item.reception.ORT01} ${item.reception.PSTLZ} ${item.reception.STRAS}`;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 600;
      console.info("checkMobile => ", this.isMobile);
    },
    changeReception(rc) {
      this.receptionLast = "";
      this.dataReceptionCurrent = [];
      this.dataReception.map((r) => {
        if (r.reception.KUNNR == rc) {
          this.receptionLast = r.reception.last;
          this.dataReceptionCurrent = r.days;
          this.documentos_requeridos = r.reception.documentos_requeridos;
        }
      });
    },
    daytext(day) {
      let dayText = "";
      switch (day) {
        case 0:
          dayText = "Lunes";
          break;
        case 1:
          dayText = "Martes";
          break;
        case 2:
          dayText = "Miercoles";
          break;
        case 3:
          dayText = "Jueves";
          break;
        case 4:
          dayText = "Viernes";
          break;
        case 5:
          dayText = "Sabado";
          break;
        case 6:
          dayText = "Domingo";
          break;
      }
      return dayText;
    },
    _getContact() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/contact-client/list", {
          client: localStorage.getItem("id-client"),
        })
        .then((response) => {
          if (response.data.result == 1) {
            this.dataContact = response.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.dialogLoading = false));
    },
    _getDocumentData() {
      this.loading = true;
      axios
        .post(
          process.env.VUE_APP_API_IEL_SERVER + "/get-data-document-customer",
          {
            client_id: (this.client = JSON.parse(
              localStorage.getItem("1us4-pv-cl13nt")
            ).KUNNR),
          }
        )
        .then((response) => {
          if (response.data.result == 1) {
            this.dataDocument = response.data.data;
            if (this.dataDocument?.facade_photo) {
              this.fachada.id = 1;
              this.fachada.caption = "Fachada";
              this.fachada.src =
                this.urlServer + this.dataDocument.facade_photo;
              this.fachada.thumb =
                this.urlServer + this.dataDocument.facade_photo;

              this.galleryPrincipal = this.fachada;
              this.media.push(this.fachada);

              this.items.push({
                description: "Fachada",
                title: "Fachada",
                src: this.urlServer + this.dataDocument.facade_photo,
              });
            }
            if (this.dataDocument?.indoor_photo) {
              this.interior.id = 2;
              this.interior.caption = "Interior";
              this.interior.src =
                this.urlServer + this.dataDocument.indoor_photo;
              this.interior.thumb =
                this.urlServer + this.dataDocument.indoor_photo;
              this.media.push(this.interior);

              this.items.push({
                description: "Interior",
                title: "Interior",
                src: this.urlServer + this.dataDocument.indoor_photo,
              });
            }
            if (this.dataDocument?.bar_photo) {
              this.mostrador.id = 3;
              this.mostrador.caption = "Mostrador/Bodega";
              this.mostrador.src = this.urlServer + this.dataDocument.bar_photo;
              this.mostrador.thumb =
                this.urlServer + this.dataDocument.bar_photo;
              this.media.push(this.mostrador);
            }

            if (this.dataDocument?.bar_photo) {
              this.items.push({
                description: "Mostrador/Bodega:",
                title: "Mostrador/Bodega:",
                src: this.urlServer + this.dataDocument.bar_photo,
              });
            }
          }
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    _getReceptionList() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/profile/reception-list", {
          client:localStorage.getItem("id-client"),
          vkorg: "IUS2",
          vtweg: "DT",
          customer: (this.client = JSON.parse(
            localStorage.getItem("1us4-pv-cl13nt")
          ).KUNNR)
        })
        .then((response) => {
          if (response.data.result == 1) {
            this.dataReception = response.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.dialogLoading = false));
    },
    getCustomerInformation() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/infoCustomerVel", {
          customer: (this.client = JSON.parse(
            localStorage.getItem("1us4-pv-cl13nt")
          ).KUNNR),
          VKORG: "IUS2",
          VTWEG: "DT",
          VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
        })
        .then((response) => {
          this.data = response.data;
          this.emailCurrent = this.data.SMTP_ADDR;
          this.phoneCurrent = this.data.TELF1;
          //this.imageCurrent = this.data.;
          this.rfcCurrent = this.data.STCD1;
          this.nameCurrent = this.data.NAME1;
          this.customerCodeCurrent = this.data.KUNNR;

          if (this.data.CUSTOMER) {
            this.emailCurrent = this.data.CUSTOMER.user?.email;
            this.phoneCurrent = this.data.CUSTOMER.profile?.telephone;
            this.imageCurrent = this.data.CUSTOMER.profile?.image_url;
            this.rfcCurrent = this.data.CUSTOMER?.rfc;
            this.nameCurrent = this.data.CUSTOMER.user?.name;

            this.docCustomerdata = this.data.CUSTOMER?.document;
            this.fiscalCustomerdata = this.data.CUSTOMER?.fiscal;
            localStorage.setItem("id-client", this.data.CUSTOMER.user_id);

            this._getContact();
            this._getDocumentData();
            this._getReceptionList();
          } else {
            this.$swal({
              title:
                "El cliente aún no tiene un registro completo en nuestra base de datos",
              icon: "error",
            });
          }
        });
    },

    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDestinyCustomer() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/destinyCustomer", {
          customer: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
          VKORG: "IUS2",
          VTWEG: "DT",
          VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
        })
        .then((response) => {
          this.customerDestinyList = response.data;
          this.destiny = this.customerDestinyList;
          this.countdata = Object.length;
          this.geolocation = this.destiny[0]?.GEOLOCATION;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.dialogLoading = false));
    },
    getItemTextDestinations(item) {
      return `${item.KUNNR} ${item.STRAS} ${item.ORT01}  ${item.PSTLZ} `;
    },
  },
  watch: {
    clientCurrent: function () {
      this.client = localStorage.getItem("1us4-pv-cl13nt");
      this.getCustomerInformation();
      this.getDestinyCustomer();
    },
  },
};
</script>
<style>
.header-dialog,
.header-dialog div {
  background: grey;
  color: white !important;
  height: auto !important;
}
.header-dialog {
  margin-bottom: 1em;
}
.dialog-contact {
  border-radius: 1em !important;
}
.dialog-contact {
}
.gallery-max {
  height: 70%;
}
.gallery-min {
  cursor: pointer;
  height: 30%;
}
.card-without-scroll {
  overflow-x: hidden;
}

.list-sucu-mobile {
  display: none !important;
}

@media (max-width: 600px) {
  .list-sucu-mobile {
    display: block !important;
    width: 100%;
  }
  .list-sucu-desktop {
    display: none !important;
  }
  .dialog-contact .col-md-2 {
    width: 20%;
  }
  .list-sucu-mobile .col-4 {
    float: left !important;
  }
}
</style>