<template>
  <v-card>
    <v-card-title
      >Solicitud | Alta cliente
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="dialogComments = true"
        >Observaciones</v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step
            :complete="step > 1"
            step="1"
            append-icon="mdi-chevron-right"
            editable
          >
            <v-badge
              bordered
              color="error"
              :content="generalErrors"
              :value="generalErrors"
            >
              Información General
            </v-badge>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="step > 2"
            step="2"
            append-icon="mdi-chevron-right"
            editable
          >
            <v-badge
              bordered
              color="error"
              :content="fiscalErrors"
              :value="fiscalErrors"
            >
              Información Fiscal y de Negocio
            </v-badge>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3" append-icon="mdi-chevron-right" editable>
            <v-badge
              bordered
              color="error"
              :content="documentErrors"
              :value="documentErrors"
            >
              Documentación Solicitada
            </v-badge>
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <DatosGenerales
              :form="form"
              :suburbs="suburbs"
              :searchPostalCode="onSearchPostalCode"
              :states="states"
              :errors="errors"
            ></DatosGenerales>
          </v-stepper-content>

          <v-stepper-content step="2">
            <FiscalData
              :form="form"
              :cfdi_uses="cfdi_uses"
              :tax_regimes="tax_regimes"
              :way_pays="way_pays"
              :payment_methods="payment_methods"
              :errors="errors"
            ></FiscalData>
          </v-stepper-content>

          <v-stepper-content step="3">
            <DocumentationRequested
              :form="form"
              :sendForm="onSendForm"
              :idRequest="idRequest"
              :errors="errors"
              :loading="loading"
              :sentManager="onSentManager"
            ></DocumentationRequested>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-dialog v-model="dialogComments">
      <Comments :comments="comments" class="mb-5"></Comments>
    </v-dialog>
  </v-card>
</template>
<script>
import axios from "axios";
import DatosGenerales from "@/components/iel/alta/DatosGenerales.vue";
import FiscalData from "@/components/iel/alta/FiscalData.vue";
import DocumentationRequested from "@/components/iel/alta/DocumentationRequested.vue";
import Comments from "@/components/iel/alta/Comments.vue";

import { eventBus } from "@/main.js";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "IELAltaCliente",

  components: { DatosGenerales, FiscalData, DocumentationRequested, Comments },

  data() {
    return {
      loading: false,
      idRequest: null,
      dialogComments: false,
      step: 1,
      suburbs: [],
      comments: [],
      form: {
        person_type: null,
        company_name: "",
        rfc: "",
        a_street: "",
        a_number: "",
        a_suburb: "",
        a_town: "",
        a_estate: "",
        state_sap_id: "",
        a_postal_code: "",
        phone: "",
        contact_name: "",
        email: "",
        agent_number: "",
        sales_office: "",
        sales_area: "",
        branch_code: "",
        supply_center: "",
        fiscal_regimen: "",
        payment_method: "",
        cfdi_use: null,
        transport_area: "",
        iva: "",
        request_credit: "",
        delivery_fiscal_addres: "",
        fiscal_rep_name: "",
        line_request_credit: null,
        month_sales: "",
        business_size: "",
        line_bussines: "",
        own_facilities: "",
        way_pay_id: "",
        line_credit_suggest: "",
        facade_photo: [],
        indoor_photo: [],
        bar_photo: [],
        evid_tax_registration: [],
        official_id: [],
        proof_recidency: [],
        proof_recidency_distinct: [],
        credit_bureau: [],
        shcp_opening: [],
        charter: [],
        power_attorney: [],
        promissory_note: [],
        proof_recidency_endors: [],
        official_id_endors: [],
        bank_reference: [],
        regulation_data_protect: false,
        credit_policies: false,
        addresses: [],
      },
      listDocuments: {},
      cfdi_uses: [],
      tax_regimes_all: [],
      tax_regimes: [],
      way_pays: [],
      payment_methods: [],
      supply_centers: [],
      ivas: [],
      states: [],
      errors: {},
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      canSendForm: "customerRequest/canSendForm",
      formState: "customerRequest/formState",
      personType: "customerRequest/personType",
      requestCredit: "customerRequest/requestCredit",
      lineRequestCredit: "customerRequest/lineRequestCredit",
      deliveryFiscalAddres: "customerRequest/deliveryFiscalAddres",
    }),
    generalErrors() {
      if (!this.touched) {
        return 0;
      }
      let numerrors = 0;
      let errorsKey = [
        "company_name",
        "rfc",
        "a_street",
        "a_number",
        "a_suburb",
        "a_town",
        "a_estate",
        "state_sap_id",
        "a_postal_code",
        "phone",
        "contact_name",
        "email",
      ];

      for (let item in errorsKey) {
        if (errorsKey[item] in this.errors) {
          numerrors++;
        }
      }
      return numerrors;
    },
    fiscalErrors() {
      if (!this.touched) {
        return 0;
      }
      let numerrors = 0;
      let errorsKey = [
        "fiscal_regimen",
        "payment_method",
        "cfdi_use",
        "request_credit",
        "delivery_fiscal_addres",
        "fiscal_rep_name",
        "line_request_credit",
        "month_sales",
        "business_size",
        "line_bussines",
        "own_facilities",
        "way_pay_id",
      ];

      for (let item in errorsKey) {
        if (errorsKey[item] in this.errors) {
          numerrors++;
        }
      }
      return numerrors;
    },
    documentErrors() {
      if (!this.touched) {
        return 0;
      }
      let numerrors = 0;
      let errorsKey = [
        "facade_photo",
        "indoor_photo",
        "bar_photo",
        "evid_tax_registration",
        "official_id",
        "proof_recidency",
        "proof_recidency_distinct",
        "regulation_data_protect",
        "credit_bureau",
        "shcp_opening",
        "charter",
        "power_attorney",
        "promissory_note",
        "proof_recidency_endors",
        "official_id_endors",
        "bank_reference",
        "credit_policies",
      ];

      for (let item in errorsKey) {
        if (errorsKey[item] in this.errors) {
          numerrors++;
        }
      }
      return numerrors;
    },
  },

  mounted() {
    eventBus.$on("IELAltaCliente::setStep", (step) => {
      this.step = step;
    });
    eventBus.$on("IELAltaCliente::changeTaxRegimens", (value) => {
      this.changeTaxRegimens(value);
    });
    eventBus.$on("IELAltaCliente::closeObservations", (value) => {
      this.dialogComments = value;
    });

    this.idRequest = this.$route.params.idRequest;

    this.loadInitialData();

    if (this.idRequest) {
      this.loadObservations(this.idRequest);
      this.loadInformation(this.idRequest);
    }
  },

  methods: {
    ...mapMutations({
      setFiscalFormField: "customerRequest/UPDATE_FISCAL_FORM_FIELD",
    }),
    validateGeneralData() {
      eventBus.$emit("DatosGenerales::validateForm");
    },
    validateFiscalData() {
      eventBus.$emit("FiscalData::validateForm");
    },
    loadInitialData() {
      axios(`${process.env.VUE_APP_API_IEL_SERVER}/request-data`)
        .then((response) => {
          this.cfdi_uses = response.data.cfdi_uses;
          this.tax_regimes_all = response.data.tax_regimes;
          this.way_pays = response.data.way_pays;
          this.payment_methods = response.data.payment_methods;
          this.states = response.data.states;
        })
        .catch((error) => {
          let msg = null;
          console.log(error.response);
          if (error.response.status == 500) {
            msg = "Error en el servidor, por favor recargue la página";
          }
          this.$swal.fire({
            title:
              msg != null ? msg : "Ocurio un error al recuperar la información",
            icon: "success",
            text: "En caso de continuar presentando el problema, comuniquese con sistemas.",
          });
        });
    },
    onSearchPostalCode() {
      eventBus.$emit("IELAltaCliente::loading", true);

      axios
        .post(`${process.env.VUE_APP_API_IEL_SERVER}/searchPostalCode`, {
          postal_code: this.form.a_postal_code,
        })
        .then((response) => {
          this.data = response.data;
          this.$set(this.form, "a_estate", response.data.a_estate);
          this.$set(this.form, "a_town", response.data.a_town);
          this.suburbs = response.data.a_suburb;
          //this.msg = response.data.mesage

          let state = this.states.find(
            (item) => item.state == response.data.a_estate
          );
          if (state) {
            this.form.state_sap_id = state.sap_id;
          }
          eventBus.$emit("IELAltaCliente::loading", false);
          // console.log(response.data[0].mesage);
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.msg = error.response.data.errors;
            this.hasError = true;
            this.snackbar = true;
            setTimeout(() => {
              this.hasError = false;
            }, 8000);
          } else {
            alert(error);
          }
        })
        .finally(() => {
          eventBus.$emit("IELAltaCliente::loading", false);
        });
    },
    onSendForm() {
      this.loading = true;
      eventBus.$emit("IELAltaCliente::loading", true);
      let url = `${process.env.VUE_APP_API_IEL_SERVER}/saveCustumerData`;

      if (this.idRequest) {
        this.updateCustomerRequest();
      } else {
        let formData = new FormData();

        for (let key in this.form) {
          if (key != "addresses") {
            formData.append(key, this.form[key]);
          }
        }

        for (let i = 0; i < this.form.addresses.length; i++) {
          for (let key in this.form.addresses[i]) {
            console.log(`addresses[${i}][${key}]`, this.form.addresses[i][key]);
            formData.append(
              `addresses[${i}][${key}]`,
              this.form.addresses[i][key]
            );
          }
        }

        formData.append("method", "create_user");
        formData.append("name", this.form.contact_name);
        formData.append("email", this.form.email);
        formData.append("user_type", this.user.employee_type);
        formData.append("type_user_id", this.user.id);
        formData.append("user_name", this.user.name);
        formData.append("user_email", this.user.email);
        formData.append("user_phone", this.user.telefono);
        formData.append("agent_number", this.user.employee_number);
        formData.append("person_type", this.personType);
        formData.append("request_credit", this.requestCredit);
        formData.append("line_request_credit", this.lineRequestCredit);
        formData.append("delivery_fiscal_addres", this.deliveryFiscalAddres);

        axios
          .post(url, formData)
          .then((response) => {
            eventBus.$emit("IELAltaCliente::loading", false);
            this.loading = false;
            this.$swal
              .fire({
                title: "Datos guardados correctamente",
                icon: "success",
                text: "ID Cliente: " + response.data[0].id,
              })
              .then(() => {
                this.resetForms();
                this.$router.push("/iel-solicitudes");
              });
          })
          .catch((error) => {
            let message = null;
            this.loading = false;
            console.log(error.response);
            if (error.response.status == 422) {
              message = error.response.data.mesasge;
              this.errors = error.response.data.errors;
            }
            this.$swal.fire({
              title:
                message != null
                  ? message
                  : "Ocurrio un error al guardar los datos de solicitud",
              icon: "warning",
              text:
                "Puede intentar de nuevo o verificar la información." + error,
            });
          });
      }
    },
    resetForms() {
      eventBus.$emit("IELAltaCliente::resetForm", false);
    },
    loadObservations(idRequest) {
      axios
        .post(`${process.env.VUE_APP_API_IEL_SERVER}/getObservationsLasts`, {
          id: idRequest,
        })
        .then((response) => {
          this.comments = response.data;
        });
    },
    loadInformation(idRequest) {
      axios
        .get(`${process.env.VUE_APP_API_IEL_SERVER}/customer-data/${idRequest}`)
        .then((response) => {
          this.setDataToStore(response.data);
          this.changeTaxRegimens(response.data.person_type);
          this.form = response.data;
          this.comments = response.data.observations;

          for (let key in response.data.fiscal) {
            this.form[key] = response.data.fiscal[key];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateCustomerRequest() {
      eventBus.$emit("IELAltaCliente::loading", true);
      let formData = new FormData();

      for (let key in this.form) {
        if (key != "document" && key != "fiscal" && key != "addresses") {
          formData.append(key, this.form[key]);
        }
      }

      for (let i = 0; i < this.form.addresses.length; i++) {
        for (let key in this.form.addresses[i]) {
          console.log(`addresses[${i}][${key}]`, this.form.addresses[i][key]);
          formData.append(
            `addresses[${i}][${key}]`,
            this.form.addresses[i][key]
          );
        }
      }

      formData.append("person_type", this.personType);
      formData.append("request_credit", this.requestCredit);
      formData.append("line_request_credit", this.lineRequestCredit);
      formData.append("custumer_id", this.idRequest);
      formData.append("portal_user_id", this.user.id);
      formData.append("user_type", this.user.employee_type);
      formData.append("type_user_id", this.user.id);
      formData.append("user_name", this.user.name);
      formData.append("user_email", this.user.email);
      formData.append("user_phone", this.user.telefono);
      formData.append("agent_number", this.user.employee_number);
      formData.append("request_credit", this.requestCredit);
      formData.append("line_request_credit", this.lineRequestCredit);
      formData.append("delivery_fiscal_addres", this.deliveryFiscalAddres);

      axios
        .post(
          `${process.env.VUE_APP_API_IEL_SERVER}/updateCustomerDetail`,
          formData
        )
        .then((response) => {
          eventBus.$emit("IELAltaCliente::loading", false);
          this.$swal
            .fire({
              title: "Datos guardados correctamente",
              icon: "success",
              text: "ID Cliente: " + response.data[0].id,
            })
            .then(() => {
              this.$router.push("/iel-solicitudes");
            });
        })
        .catch((error) => {
          let message = "Ocurrio un error";
          console.log(error);
          if (error.response.status == 422) {
            message = error.response.data.mesasge;
            this.errors = error.response.data.errors;
          }
          this.$swal.fire({
            title: message,
            icon: "warning",
            text: error,
          });
          eventBus.$emit("IELAltaCliente::loading", false);
        })
        .finally(() => (this.loading = false));
    },
    validateForms() {
      eventBus.$emit("DatosGenerales::validateForm");
      eventBus.$emit("FiscalData::validateForm");
      eventBus.$emit("DocumentationRequested::validateForm");
      console.log("validando");
    },
    changeTaxRegimens(value) {
      this.tax_regimes = this.tax_regimes_all.filter(
        (item) =>
          item.type.toLowerCase() == value || item.type.toLowerCase() == "ambos"
      );
      if (value == "moral" && this.form.rfc.length > 12) {
        this.form.rfc = this.form.rfc.slice(0, -1);
      }
    },
    onSentManager() {},
    setDataToStore(data) {
      this.$store.commit("customerRequest/updatePersonType", data.person_type);
      this.$store.commit(
        "customerRequest/updateFiscalRequestCredit",
        data.fiscal.request_credit
      );
      this.$store.commit(
        "customerRequest/updateLineRequestCredit",
        data.fiscal.line_request_credit
      );
      console.log("delivery_fiscal_addres", data.fiscal.delivery_fiscal_addres);
      this.setFiscalFormField({
        field: "delivery_fiscal_addres",
        value: data.fiscal.delivery_fiscal_addres,
      });
    },
  },
};
</script>
