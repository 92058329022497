<template>
  <v-text-field
    :label="label"
    outlined
    :readonly="type == 3"
    dense
    :error-messages="errors"
    @keydown="checkNumber"
    @input="handleInput"
    :value="formattedNumber"
  ></v-text-field>
</template>

<script>
export default {
  name: "TextInputMoney",

  props: ["label", "type", "errors", "value"],
  data() {
    return {
      content: this.value,
      formattedNumber: "",
    };
  },
  methods: {
    handleInput(inputvalue) {
      let value = this.removeMask(inputvalue);
      this.formattedNumber = Number(value).toLocaleString();
      this.$emit("input", value);
    },
    checkNumber(event) {
      if (
        ![
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "Backspace",
          "Tab",
        ].includes(event.key)
      ) {
        return event.preventDefault();
      }
    },
    removeMask(str) {
      return str.replace(/\D+/g, "");
    },
  },
};
</script>
