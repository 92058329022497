<template>
  <div>
    <v-card
      class="mx-auto rounded-lg elevation-4 mb-4 ml4"
      outlined
      :style="'border: 4px solid #B7970C; background-color:transparent;'"
      v-if="bonus.length > 0"
    >
      <v-list-item-content>
        <div class="text-gold" style="color: #b7970c; text-align: center">
          <v-icon style="color: #b7970c !important">mdi-chart-line</v-icon>
          INDICADORES TRIMESTRALES PARA BONO
        </div>
      </v-list-item-content>
    </v-card>
    <v-card
      v-if="bonus.length > 0"
      class="mx-auto rounded-xl elevation-5 ma-5"
      outlined
    >
      <div class="ma-4 mt-10" v-if="headers_bono_ind.length > 0">
        <v-data-table
          :headers="headers_bono_ind"
          :items="bonus"
          hide-default-footer
          class="elevation-0"
          locale="esp"
          style="border-bottom: none"
        >
          <template v-slot:item.PERIODO1="{ item }">
            <div v-if="item.TITULO == 'Pagos del Trimestre (sin IVA)'">
              <div  v-if="item.PERIODO1 > 0">
                <a class="text-black" @click="indicatorsPaymentDetails(1)"  > <u> $&nbsp;&nbsp;{{ format(item.PERIODO1) }}</u></a>
              </div> 
              <div v-else>
                $&nbsp;&nbsp;{{ format(item.PERIODO1) }}
              </div> 
            </div>
            <div
              v-else-if="item.TITULO == 'Prom. Pond. de días de pago (#docs)'"
            >
              {{ docs(item.PERIODO1) }}
            </div>
            <div v-else>
              $ &nbsp;&nbsp;{{ format(item?.PERIODO1) }} /
              {{ porc(item?.PORC1) }}%
            </div>
          </template>
          <template v-slot:item.PERIODO2="{ item }">
            <div v-if="item.TITULO == 'Pagos del Trimestre (sin IVA)'">
              <div  v-if="item.PERIODO2 > 0">
                <a class="text-black" @click="indicatorsPaymentDetails(2)"> <u> $&nbsp;&nbsp;{{ format(item.PERIODO2) }}</u></a>
              </div>
              <div v-else>
                $&nbsp;&nbsp;{{ format(item.PERIODO2) }}
              </div>
            </div>
            <div
              v-else-if="item.TITULO == 'Prom. Pond. de días de pago (#docs)'"
            >
              {{ docs(item.PERIODO2) }}
            </div>
            <div v-else>
              $ &nbsp;&nbsp;{{ format(item.PERIODO2) }} /
              {{ porc(item.PORC2) }}%
            </div>
          </template>
          <template v-slot:item.PERIODO3="{ item }">
            <div v-if="item.TITULO == 'Pagos del Trimestre (sin IVA)'">
              <div  v-if="item.PERIODO3 > 0">
                <a class="text-black" @click="indicatorsPaymentDetails(3)"  > <u> $&nbsp;&nbsp;{{ format(item.PERIODO3) }}</u></a>
              </div> 
              <div v-else>
                $&nbsp;&nbsp;{{ format(item.PERIODO3) }}
              </div> 
            </div>
            <div
              v-else-if="item.TITULO == 'Prom. Pond. de días de pago (#docs)'"
            >
              {{ docs(item.PERIODO3) }}
            </div>
            <div v-else>
              $ &nbsp;&nbsp;{{ format(item.PERIODO3) }} /
              {{ porc(item.PORC3) }}%
            </div>
          </template>
          <template v-slot:item.PERIODO4="{ item }">
            <div v-if="item.TITULO == 'Pagos del Trimestre (sin IVA)'">
              <div  v-if="item.PERIODO4 > 0">
                <a class="text-black" @click="indicatorsPaymentDetails(4)"  > <u> $&nbsp;&nbsp;{{ format(item.PERIODO4) }}</u></a>
              </div> 
              <div v-else>
                $&nbsp;&nbsp;{{ format(item.PERIODO4) }}
              </div>
            </div>
            <div
              v-else-if="item.TITULO == 'Prom. Pond. de días de pago (#docs)'"
            >
              {{ docs(item.PERIODO4) }}
            </div>
            <div v-else>
              $ &nbsp;&nbsp;{{ format(item.PERIODO4) }} /
              {{ porc(item.PORC4) }}%
            </div>
          </template>
        </v-data-table>
      </div>
      <div class="mt-5">
        <div class="ml-15 mr-15">
          <v-select
            :items="select_bono_ind"
            v-model="title"
            label="Seleccionar"
            outlined
            small-chips
            class=".v-select__selection"
            @change="getDataChart($event)"
          ></v-select>
        </div>
      </div>
      <div class="ma-0 mt-n5">
        <h5 style="font-weight: 700; text-align: center">
          Trimestre Actual <br />
          % {{ title }}
        </h5>
        <pie-chart
          v-if="loaded"
          :chartdata="chartdata1"
          style="height: 230px"
        ></pie-chart>
      </div>
      <div class="ma-4">
        <v-row>
          <v-col md="4" style="text-align: center">
            <label class="text-chart-sub">{{ this.per_2 }}</label>
            <pie-chart
              v-if="loaded"
              :chartdata="chartdata2"
              style="height: 150px"
            ></pie-chart>
          </v-col>
          <v-col md="4" style="text-align: center">
            <label class="text-chart-sub">{{ this.per_3 }}</label>
            <pie-chart
              v-if="loaded"
              :chartdata="chartdata3"
              style="height: 150px"
            ></pie-chart>
          </v-col>
          <v-col md="4" style="text-align: center">
            <label class="text-chart-sub">{{ this.per_4 }}</label>
            <pie-chart
              v-if="loaded"
              :chartdata="chartdata4"
              style="height: 150px"
            ></pie-chart>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-dialog v-model="modalPaymentDetails" :persistent="loading" width="auto">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Pagos del Trimestre (sin IVA)
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <p><b>Detalle de trimestre</b></p>
              <v-data-table dense  :headers="paymentDetailsHeader" :items="paymentDetails" :items-per-page="5" :search="search"
                :loading="loading"   loading-text="Cargando... Por favor espere" no-data-text="No hay datos disponibles"> 
                <template v-slot:[`item.IMPXIVA`]="{ item }">
                  ${{ format(item.IMPXIVA) }}
                </template>
                <template v-slot:[`item.WRBTR`]="{ item }">
                  ${{ format(item.WRBTR) }}
                </template>
                <template v-slot:[`item.IMPTENC`]="{ item }">
                  ${{ format(item.IMPTENC) }}
                </template>
                <template v-slot:[`item.SKFBT`]="{ item }">
                  ${{ format(item.SKFBT) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="modalPaymentDetails = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import PieChart from "@/components/PieChart.vue";
export default {
  props: {
    clientCurrent: {
      type: null,
      default: null,
    },
  },
  components: {
    PieChart,
  },
  data() {
    return {
      title: "Pagos IUSAIEL + SPEI",
      bonus: [],
      chartdata1: null,
      chartdata2: null,
      chartdata3: null,
      chartdata4: null,
      per_1: null,
      per_2: null,
      per_3: null,
      per_4: null,
      loaded: false,
      isVisible: false,
      headers_bono_ind: [],
      select_bono_ind: [
        "Pagos IUSAIEL + SPEI",
        "Pagos vía IUSAIEL",
        "Pagos vía SPEI",
      ],
      loading:false,
      modalPaymentDetails:false,
      paymentDetails:[],
      trims:[],
      paymentDetailsHeader:[
        { text: "Periodo", align: "center", value: "PERIODO" },
        { text: "Número de documento contable", align: "center", value: "BELNR" },
        { text: "Clase de documento", align: "center", value: "BLART" },
        { text: "Fecha de contabilización en el doc.", align: "center", value: "BUDAT" },
        { text: "Clave de moneda", align: "center", value: "WAERS" },
        { text: "Importe en la moneda del doc.", align: "center", value: "IMPXIVA" }, 
        { text: "Número de factura", align: "center", value: "REBZG" },
        { text: "Importe con derecho a descuento", align: "center", value: "SKFBT" }, 
        { text: "Días transcurridos", align: "center", value: "DIAST" }, 
        { text: "DA Pago", align: "center", value: "DPAGO" }, 
        { text: "Importe N/C DPP", align: "center", value: "IMPTENC" }, 
      ],
      search:"",
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  async mounted() {
    let self = this;
    if (localStorage.getItem("1us4-pv-cl13nt")) {
      self.getBonus();
      self.loaded = false;
      self.getDataChart(this.title);
    }
  },

  methods: {
    getPeriodInString(period) {
      switch (period) {
        case 'Ene-Feb-Mar':  period = 1; break; 
        case 'Abr-May-Jun':  period = 2; break; 
        case 'Jul-Ago-Sep':  period = 3; break;  
        default: period = 4; break;
      }
      return period;
    },
    indicatorsPaymentDetails(period){
      this.loading=true;
      this.modalPaymentDetails=true;
      this.paymentDetails=[];
      const per = this.trims[period-1][period];
      const year = 20+per.slice(-2)
      const trim =  this.getPeriodInString(per.slice(0,-3))  
      const params = {
          customer_code: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
          trim:trim,
          year:year
      } 
      axios.post(process.env.VUE_APP_API_IEL_SERVER + "/indicatorsPayment/details",params)
        .then((response) => {
          this.paymentDetails = ( response.data.items == 1) ?  [response.data.data] : response.data.data;
          this.loading=false; 
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    getBonus() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/indicatorsPayment", {
          client_code: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
        })
        .then((response) => {
          var headerBonus = response.data.header;
          this.bonus = response.data.data;
          if( headerBonus.length ){

            this.per_1 = headerBonus[0].PERIODO1;
            this.per_2 = headerBonus[0].PERIODO2;
            this.per_3 = headerBonus[0].PERIODO3;
            this.per_4 = headerBonus[0].PERIODO4;
            this.trims = [
                    {1: headerBonus[0].PERIODO1},
                    {2: headerBonus[0].PERIODO2},
                    {3: headerBonus[0].PERIODO3},
                    {4: headerBonus[0].PERIODO4},
                ];
            this.headers_bono_ind = [
              {
                text: headerBonus[0].TITULO,
                align: "start",
                value: "TITULO",
                class: "border-bottom: none;",
                sortable: false,
              },
              { text: headerBonus[0].PERIODO1, sortable: false, value: "PERIODO1" },
              { text: headerBonus[0].PERIODO2, sortable: false, value: "PERIODO2" },
              { text: headerBonus[0].PERIODO3, sortable: false, value: "PERIODO3" },
              { text: headerBonus[0].PERIODO4, sortable: false, value: "PERIODO4" },
            ]; 
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    format(value) {
      let result = Number(value).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    porc(value) {
      let result = Number(value).toFixed(0);
      return result;
    },
    docs(value) {
      let result = Number(value).toFixed(0);
      return result;
    },
    getDataChart(event) {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/bonusChart", {
          client: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
          concept: event,
        })
        .then((response) => {
          console.log(response.data);
          this.loaded = true;
          
          if( response.data.length > 0 ){

            this.chartdata1 = {
            labels: ["Pagos Fuera de Linea: ", ""],
            datasets: [
              {
                backgroundColor: [
                  "rgba(64, 134, 255, 0.8)",
                  "rgba(220 ,220 ,220, 0.8)",
                ],
                data: [response.data[0].PORC1, response.data[0].rest_1],
                porcentaje: [response.data[0].PORC1, response.data[0].rest_1],
                tension: 0.1,
              },
            ],
          };

          (this.chartdata2 = {
            labels: ["Pagos Fuera de Linea: ", ""],
            datasets: [
              {
                backgroundColor: [
                  "rgba(128, 0, 128, 0.8)",
                  "rgba(220 ,220 ,220, 0.8)",
                ],
                data: [response.data[0].PORC2, response.data[0].rest_2],
                porcentaje: [response.data[0].PORC2, response.data[0].rest_2],
                fill: false,
                tension: 0.1,
              },
            ],
          }),
            (this.chartdata3 = {
              labels: ["Pagos Fuera de Linea: ", ""],
              datasets: [
                {
                  backgroundColor: [
                    "rgba(128, 0, 128, 0.8)",
                    "rgba(220 ,220 ,220, 0.8)",
                  ],
                  data: [response.data[0].PORC3, response.data[0].rest_3],
                  porcentaje: [response.data[0].PORC3, response.data[0].rest_3],
                  fill: false,
                  tension: 0.1,
                },
              ],
            }),
            (this.chartdata4 = {
              labels: ["Pagos Fuera de Linea: ", ""],
              datasets: [
                {
                  backgroundColor: [
                    "rgba(128, 0, 128, 0.8)",
                    "rgba(220 ,220 ,220, 0.8)",
                  ],
                  data: [response.data[0].PORC4, response.data[0].rest_4],
                  porcentaje: [response.data[0].PORC4, response.data[0].rest_4],
                  fill: false,
                  tension: 0.1,
                },
              ],
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  watch: {
    clientCurrent: function () {
      let self = this;
      self.getBonus();
      self.loaded = false;
      self.getDataChart(this.title);
    },
  },
};
</script>

<style></style>
