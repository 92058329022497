<template>
    <div class="text-center">
        <h4 class="mt-6">Resumen de la Semana</h4>
        <span class="span__1-red">{{semana.intervalo}} / semana {{semana.semana}}</span>        

        <div>
            <v-simple-table class="mt-5" >
                <template v-slot:default>
                    <thead>
                        <tr class="resume__thead--orange">
                        <th scope="col" class="text-center">#</th>
                        <th scope="col" class="text-center">Frecuencia <br>visita</th>
                        <th scope="col" class="text-center">Dia(s) <br> Visita</th>
                        <th scope="col" class="text-center">FNETA <br> Trimestre 4 / 2023</th>
                        <th scope="col" class="text-center">Cliente</th>
                        <th scope="col" class="text-center">Nombre del Cliente</th>                        
                        <th scope="col" class="text-center">PVPL</th>                        
                        <th scope="col" class="text-center" v-for="(date, index) in dateArray" :key="index">
                            {{date.date | formatDate}}
                            <table style="min-width: 120px; margin:0 auto">
                                <tbody>
                                    <tr>
                                        <td style="width: 50%">P</td>
                                        <td style="width: 50%">R</td>
                                    </tr>
                                </tbody>
                            </table>                      
                        </th>                                       
                        </tr>
                    </thead>
                    <tbody>
                    <tr class="resume__tbody--align"
                        v-for="item in paginatedClientes"
                        :key="item.name"
                    >
                            <td>{{ item.id }}</td>
                            <td>{{(item.frequency)?item.frequency:'-'}}</td>
                            <td>{{(item.day)?item.day:'-'}}</td>
                            <td>${{item.FNETA}}</td>
                            <td>{{ item.KUNNR }}</td>
                            <td>{{item.NAME1}}</td>                            
                            <td></td>                                                     
                            <td v-for="(date, index) in dateArray" :key="index" >
                                <table style="min-width: 120px;  margin:0 auto">
                                    <tbody>
                                        <tr>
                                            <td class="text-center" style="width: 50%">
                                                <v-icon large color="green mdi-calendar-account-outline" v-if="(date.day == item.day) && entraEnRango(item.created_at, item.frequency)">
                                                    mdi-calendar-account-outline
                                                </v-icon> 
                                            </td>
                                            <td class="text-center" style="width: 50%"></td>
                                        </tr>
                                    </tbody>
                                </table>   
                            </td>
                        </tr>  
                    </tbody>
                </template>
            </v-simple-table>
            <v-pagination v-model="currentPage" :length="totalPages" />
        </div>
        

        <table class="mt-5" >
            <thead>
                <th>
                    <td>Diccionario:</td>
                </th>
            </thead>            
            <tbody>
                <tr class="text-left">
                    <td class="pr-3"><b>P</b> = Visita Programada</td>
                    <td class="pr-3"><b>R</b> = Pedido Realizado en Miles</td>  
                </tr>
                <!-- <tr class="text-left">                                        
                    <td class="pr-3"><b>VPP</b> = Vista Programada con Pedido</td>                        
                    <td class="pr-3"><b>PESP</b> = Pedido Especial</td>                                               
                </tr> 
                <tr class="text-left">     
                    <td class="pr-3"><b>PFP</b> = Pedido Fuera de Programa</td>
                    <td class="pr-3"><b>CSP</b> = Clientes Sin Pedido</td> 
                     
                </tr>                -->
            </tbody>
        </table>
    </div>
</template>

<script>
 /* eslint-disable */
import moment from "moment"
// import axios from 'axios'
export default {
    props: ['semana', 'clientes'],
    data(){
        return {
            dateArray: [],
            currentPage: 1,
            itemsPerPage: 10,
            frequency: {
                'semanal': 1,
                'quincenal': 2
            },
            startDate: '',
            endDate: ''
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.clientes.length / this.itemsPerPage);
        },
        paginatedClientes() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.clientes.slice(startIndex, endIndex);
        },
    },
    watch: {
        semana: {
            immediate: true,
            handler(newVal) {
                this.startDate = newVal.inicio
                this.endDate = newVal.fin
                this.generateDateArray()
            }
        }
    },
    methods: {        
        generateDateArray() {
            let start = moment(this.startDate);
            let end = moment(this.endDate);
            let dateArray = [];
            while (start <= end) {
                let dateObj = {
                    date: start.format('YYYY-MM-DD'),
                    day: start.format('dddd')
                };

                dateArray.push(dateObj);

                start = start.add(1, 'day');
            }
            this.dateArray = dateArray;
        },
        entraEnRango(craeted_at, frequency) {  
            const fechaInicio = moment(craeted_at)       
            const fechaRangoInicio = moment(this.startDate);   
            const fechaRangoFin = moment(this.endDate);

            if (fechaInicio.format('YYYY-MM-DD') > fechaRangoInicio.format('YYYY-MM-DD') && fechaInicio.format('YYYY-MM-DD') > fechaRangoFin.format('YYYY-MM-DD')) {
                return false;
            }

            const diffWeeks = fechaRangoFin.diff(fechaInicio, 'weeks');
            return diffWeeks >= 0 && diffWeeks % this.frequency[frequency] == 0;
        },
        formatoFecha(value){
          return moment(value).format('YYYY-MM-DD')
        },
        formatoDiaSemana(value){
            return moment(value).format('dd')
        }
    }
}
</script>

<style>

</style>