<template>
  <v-container fluid>
    <v-tabs v-model="tab" background-color="accent" dark grow>
      <v-tab><v-icon>mdi-numeric-1-circle</v-icon> Carga de archivo </v-tab>
      <v-tab :disabled="!isLoaded">
        <v-icon>mdi-numeric-2-circle</v-icon> Pedido
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card :loading="loading">
          <v-card-title>Carga de información</v-card-title>
          <v-card-text>
            <v-form>
              <p>
                Seleccione su archivo con extensión csv para continuar con la
                carga
              </p>
              <v-row>
                <v-col cols="12" md="8">
                  <v-file-input
                    label="Archivo CSV"
                    outlined
                    v-model="file"
                    dense
                    accept=".csv"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn color="primary" @click="sendFile">Cargar pedido</v-btn>
                </v-col>
                <v-col cols="12">
                  <a target="_blank" :href="`${url}/assets/pedidocsv.csv`">
                    <v-icon x-large>mdi-file-delimited-outline</v-icon>
                    Descarga formato CSV
                  </a>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card class="mt-4" :disabled="!isLoaded" :loading="loading">
          <v-card-title>Datos cargados</v-card-title>
          <v-card-text>
            <h2>Datos válidos</h2>

            <v-data-table
              dense
              :headers="headers"
              :items="data.datos_material"
              item-key="name"
              class="elevation-1"
            >
              <template v-slot:[`item.precio_con_descuento`]="{ item }">
                $ {{ formatNumber(item.precio_con_descuento) }}
              </template>
              <template v-slot:[`item.importe_total`]="{ item }">
                $ {{ formatNumber(item.u_pedidas * item.precio_con_descuento) }}
              </template>
            </v-data-table>
            <h2 class="mt-5">Datos con error</h2>
            <v-data-table
              dense
              :headers="headers_errors"
              :items="data.datos_error"
              item-key="dataError"
              class="elevation-1"
            >
            </v-data-table>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="success" @click="continueToOrder"
              >Continuar
              <v-icon>mdi-menu-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card color="basil" flat>
          <v-card-text>
            <order-csv
              :idorder="data.orden_compra"
              :dataorder="dataorder"
            ></order-csv>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import OrderCsv from "@/views/ordercsv/OrderCsv.vue";
import { eventBus } from "@/main";
import general from "@/plugins/general.js";

export default {
  name: "OrderView",
  mixins: [general],
  components: { OrderCsv },
  data() {
    return {
      tab: 0,
      url: process.env.VUE_APP_BASE_IEL_SERVER,
      file: [],
      isLoaded: false,
      loading: false,
      headers: [
        { text: "Código", value: "codigo_material" },
        { text: "Nombre", value: "nombre_material" },
        { text: "Cantidad", value: "u_pedidas" },
        { text: "Empaque", value: "empaque" },
        { text: "Unidad medida", value: "unidad_medida" },
        { text: "Promedio mensual de compra", value: "PMCOM" },
        { text: "Días solicitados de inventario", value: "DSINV" },
        { text: "Precio lista", value: "precio_lista" },
        { text: "Precio descuento", value: "precio_con_descuento" },
        { text: "Importe total", value: "importe_total" },
      ],
      headers_errors: [
        { text: "Índice", value: "id_rec" },
        { text: "Código material", value: "codigo_material" },
        { text: "Cantidad", value: "u_pedidas" },
        { text: "Estatus", value: "validacion" },
        { text: "Detalle", value: "mensaje_error" },
      ],
      data: {},
      dataorder: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    eventBus.$on("OrderCsv::orderCreated", () => {
      this.tab = 0;
      this.file = [];
      this.data = {};
      this.dataorder = [];
    });
  },
  methods: {
    ...mapMutations({
      setOrderId: "ordercsv/SET_ORDER_ID",
      setCard: "ordercsv/SET_CART",
    }),
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    sendFile() {
      this.loading = true;

      if(this.file == [] || this.file.length <= 0) {
        this.loading = false;
        this.$swal({
          title: "Seleccione el archivo CSV",
          icon: "warning",
          text: "Debe seleccionar el archivo CSV para cargar el pedido"
        });

        return;
      }

      let customer = this.getCurrentClient();

      if (!customer || Object.keys(customer).length <= 0) {
        this.loading = false;
        this.$swal({
          title: "Seleccione el cliente",
          icon: "warning",
          text: "En la parte superior seleccione el cliente destino de la lista"
        });

        return;
      }
      const formData = new FormData();
      formData.append("file", this.file);
      axios
        .post(`${process.env.VUE_APP_API_IEL_SERVER}/uploadFile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loading = false;
          this.data = response.data;
          this.getItems(this.data);
        })
        .catch((error) => {
          console.error(error);
          this.$swal({
            title: "Error al procesar el archivo. Verifique formato.",
            icon: "error",
            type: "error",
          });
          this.loading = false;
        });
    },
    getItems(data) {
      this.loading = true;
      let customer = this.getCurrentClient();
      axios
        .post(`${process.env.VUE_APP_API_IEL_SERVER}/getItems`, {
          pedido: data,
          customer: customer.KUNNR,
          VKORG: this.VKORG,
          VTWEG: this.VTWEG,
          VKBUR: this.VKBUR,
        })
        .then((response) => {
          this.data = response.data;
          this.setOrderId(response.data.orden_compra);
          this.setCard(response.data.datos_material);
          this.loading = false;
          this.isLoaded = true;
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            title: "Error al procesar el archivo. Verifique formato.",
            icon: "error",
            type: "error",
          });
          console.error(error);
        });
    },
    formatNumber(value) {
      const f = parseFloat(value);
      return f.toFixed(2);
    },
    roundOff(num, places) {
      const x = Math.pow(num, places);
      const y = Math.round(num * x) / x;
      return y;
    },
    continueToOrder() {
      this.tab = 1;
      this.dataorder = this.data.datos_material;
      eventBus.$emit("OrderCsv::addOrderId", {
        orderId: this.data.orden_compra,
      });
    },
    getCurrentClient() {
      let customer = JSON.parse(localStorage.getItem("1us4-pv-cl13nt"));

      if (!customer || Object.keys(customer).length <= 0) {
        this.$swal({
          title: "Por favor asegurate de seleccionar el cliente.",
          icon: "error",
          type: "error",
        });
      }
      return customer;
    },
  },
};
</script>
