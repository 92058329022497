<template>
    <div class="content-profile-user" id="contenedor">
        <v-container fluid class="grey lighten-5">
            <v-col class="">
                <v-card class="mx-auto rounded-lg elevation-4 ma-3 ml-5 mr-5" outlined
                    :style="'border: 4px solid black; background-color:transparent;' ">
                    <v-list-item-content>
                        <div class="text-general-module" style="text-align: center;">
                            <v-icon color="black">mdi-account-circle</v-icon>
                            Cuenta
                        </div>
                    </v-list-item-content>
                </v-card>
                <v-card class="mx-auto rounded-xl elevation-5 ma-4 ml-4 mr-4" outlined>
                    <div class="mb-4 mt-15 ">
                        <v-card class=" align-center justify-center elevation-0">
                            <v-dialog v-model="dialog" width="500">
                                <template v-slot:activator="{ on, attrs }">
                                    <input type="file" ref="file" id="image" name="image" style="display: none"
                                        @change="onFileSelected">
                                    <div class="d-flex justify-center" style="text-align: center;">
                                        <span @click="$refs.file.click()" v-bind="attrs" v-on="on">
                                            <span class="text-profile-module" v-if="isHidden && isHiddenProfile">
                                                Editar
                                                <v-icon color="black">
                                                    mdi-square-edit-outline
                                                </v-icon><br>
                                                <label for="" class="mb-2 text-table-profile ml-10 mr-10">Tipo archivo: (.jpg .png) <v-icon>mdi-image</v-icon></label>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="justify-center" style="text-align: center;" v-bind:style="{ display: showPhoto ? 'block' : 'none' }">
                                        <v-avatar color="black" class="avatar-tag mb-3" :size="avatarSize">
                                            <img :src="urlServer + data[0].image_url">
                                        </v-avatar>
                                    </div>
                                </template>

                                <v-card>
                                    <v-card height="50vh" v-if="img">
                                        <cropper class="cropper" :src="img" :stencil-props="{ aspectRatio: 1/1}"
                                            @change="change">
                                        </cropper>
                                    </v-card>
                                    <v-card v-else height="50vh"></v-card>
                                    <v-card-actions>
                                        <v-btn color="primary" text @click="dialog = false">
                                            CANCELAR
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="onUpload()">
                                            OK
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-card>
                    </div>
                    <div class="mt-10 ma-4 mb-15">
                        <div class="text-justify text-general-module ma-4 ml-15" v-if="!isHidden">
                            <span class="ml-4 mr-4">DATOS PERSONALES</span>
                            <v-btn depressed v-on:click="selectEdit(1)">
                                Editar Datos
                                <v-icon style="margin-left: 0.5em;">
                                    mdi-square-edit-outline
                                </v-icon>
                            </v-btn>
                        </div>
                        <div class="ma-4 ml-10 mb-4" v-if="!isHidden">
                            <v-simple-table class="table-profile mb-4 ml-10 mr-10">
                                <tbody>
                                    <tr>
                                        <td>Nombre(s)</td>
                                        <td class="text-table-profile">{{data[0].name}}</td>
                                        <td>Fecha de Nacimiento</td>
                                        <td class="text-table-profile">{{data[0].birthday}}</td>
                                    </tr>
                                    <tr>
                                        <td>Teléfono</td>
                                        <td class="text-table-profile">{{data[0].telephone}}</td>
                                        <td>
                                            <span class="pr-2">
                                                ¿El número tiene
                                            </span>
                                            <v-icon style="color: #25D366">mdi-whatsapp</v-icon> ?
                                        </td>
                                        <td class="text-table-profile">
                                            <v-layout class="justify-center">
                                                <v-switch :disabled="true" v-model="switch1" class="green-label">
                                                    <template v-slot:label></template>
                                                </v-switch>
                                            </v-layout>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>E-mail</td>
                                        <td class="text-table-profile">{{data[0].email}}</td>
                                        <td>¿Recibir info de Marketing?</td>
                                        <td class="text-table-profile">
                                            <v-layout class="justify-center">
                                                <v-switch :disabled="true" v-model="switch2" class="green-label">
                                                    <template v-slot:label></template>
                                                </v-switch>
                                            </v-layout>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>

                        <div class="text-justify text-general-module ma-4 ml-15" v-if="!isHidden">
                            <span class="ml-4 mr-4">CONTACTOS</span>
                            <v-btn depressed v-on:click="selectEdit(2)">
                                Agregar
                                <v-icon style="margin-left: 0.5em;">
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </div>
                        <div class="ma-4 ml-10 mb-4" v-if="!isHidden">
                            <v-simple-table class="table-profile mb-4 ml-10 mr-10">
                                <tbody>
                                    <tr>
                                        <td class="text-table-profile">Nombre(s)</td>
                                        <td class="text-table-profile">Correo</td>
                                        <td class="text-table-profile">Teléfono</td>
                                        <td class="text-table-profile">Fecha de Creación</td>
                                        <td class="text-table-profile">Acciones</td>
                                    </tr>
                                    <template v-if="dataContact.length > 0">
                                        <tr v-for="c in dataContact" :key="c.id">
                                            <td style="text-transform: capitalize;">
                                                <v-icon v-if="c.main == 1" style="color:#F7D70A;margin-right: 0.5em;">mdi-star</v-icon>{{c.name+ ' ' + c.last_name}}
                                            </td>
                                            <td>{{c.email}}</td>
                                            <td>{{c.phone}}</td>
                                            <td>{{ formatDate( c.created_at )}}</td>
                                            <td>
                                                <v-btn depressed v-on:click="editContact(c)">
                                                    <v-icon>mdi-square-edit-outline</v-icon>
                                                </v-btn>
                                                <v-btn depressed v-on:click="deleteContact(c)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </v-simple-table>
                        </div>

                        <div class="text-justify text-general-module ma-4 ml-15" v-if="!isHidden">
                            <span class="ml-4 mr-4">HORARIOS DE RECEPCIÓN</span>
                        </div>
                        <div class="ma-4 ml-10 mb-4" v-if="!isHidden">
                            <v-simple-table class="mb-4 ml-10 mr-10">
                                <tbody>
                                    <tr>
                                        <td class="text-table-profile">Establecimiento</td>
                                        <td class="text-table-profile">Horarios</td>
                                        <td class="text-table-profile">Acciones</td>
                                    </tr>
                                    <template v-if="dataReception.length > 0">
                                        <tr v-for="r in dataReception" :key="r.reception.LZONE">
                                            <td>
                                                {{r.reception.ORT01+' '+r.reception.PSTLZ+' '+r.reception.STRAS}} <br>
                                                <b>Documentos Requeridos:</b><br>
                                                <span v-if="r.reception.documentos_requeridos == null">Sin Documentos Requeridos</span>
                                                <span v-else>{{r.reception.documentos_requeridos}}</span>
                                            </td>
                                            <td>
                                                <template v-if="r.days.length == 0">
                                                    <span style="font-size: .875rem;">No ha cargado los horarios</span>
                                                </template>
                                                <template v-else>
                                                    <v-simple-table class="table-profile mb-4 ml-10 mr-10">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-table-profile">Día</td>
                                                                <td class="text-table-profile">Horario</td>
                                                            </tr>
                                                            <tr v-for="d in r.days" :key="d.id">
                                                                <td>{{ daytext( d.day )}}</td>
                                                                <td>
                                                                    <template v-if="d.start == null">
                                                                        <span>Sin recepción</span>
                                                                    </template>
                                                                    <template v-else>
                                                                        Recepción de: <span>{{ d.start }} a {{ d.end }}</span>
                                                                    </template>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </v-simple-table>
                                                </template>
                                            </td>
                                            <td>
                                                <v-btn depressed v-on:click="editReception(r)">
                                                    <v-icon>mdi-square-edit-outline</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="3">
                                                <h5 style="color:red;text-align:center;">Sin información de destinatarios de mercancía por parte de SAP, comunicarse con el área de crédito para dar de alta.</h5>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </v-simple-table>
                        </div>

                        <v-row v-if="!isHidden" class="justify-text ml-10 mr-10" justify="center">
                            <div class="text-justify text-general-module ma-4 ml-15">
                                <span class="ml-4 mr-4">FOTOS DEL NEGOCIO</span>
                                <v-btn depressed v-on:click="selectEdit(4)">
                                    Editar Fotos
                                    <v-icon style="margin-left: 0.5em;">
                                        mdi-square-edit-outline
                                    </v-icon>
                                </v-btn>
                            </div>
                            <v-row dense>
                                <v-col cols="12" md="4" align="center">
                                    <label style="font-weight:bold" class="text-table-profile ml-10 mr-10">Fachada:</label><br>
                                    <a v-if="dataDocument?.facade_photo" @click="index = 0"><img class="img-fachada" :src="urlServer+dataDocument?.facade_photo"></a>
                                    <v-icon v-if="!dataDocument?.facade_photo">mdi-image</v-icon><span v-if="!dataDocument?.facade_photo">(Sin Imagen)</span>
                                </v-col>
                                <v-col cols="12" md="4" align="center">
                                    <label style="font-weight:bold" class="text-table-profile ml-10 mr-10">Interior:</label><br>
                                    <a v-if="dataDocument?.indoor_photo" @click="index = 1"><img class="img-fachada" :src="urlServer+dataDocument?.indoor_photo"></a>
                                    <v-icon v-if="!dataDocument?.indoor_photo">mdi-image</v-icon><span v-if="!dataDocument?.indoor_photo">(Sin Imagen)</span>
                                </v-col>
                                <v-col cols="12" md="4" align="center">
                                    <label style="font-weight:bold" class="text-table-profile ml-10 mr-10">Mostrador/Bodega:</label><br>
                                    <a v-if="dataDocument?.bar_photo" @click="index = 2"><img class="img-fachada" :src="urlServer+dataDocument?.bar_photo"></a>
                                    <v-icon v-if="!dataDocument?.bar_photo">mdi-image</v-icon><span v-if="!dataDocument?.bar_photo">(Sin Imagen)</span>
                                </v-col>
                            </v-row>
                        </v-row>

                        <!--v-if="!isHidden"-->
                        <v-row v-if="isHidden && isHiddenProfile" class="justify-text ml-10 mr-10" justify="center">
                            <v-form ref="form">

                                <div class="text-justify text-general-module ma-4">
                                    <span class="ml-4 mr-4">DATOS PERSONALES</span>
                                </div>

                                <v-row dense>
                                    <v-col cols="12" md="6" align="left">
                                        <label for="" class="text-table-profile ml-10 mr-10">Nombre (s)</label>
                                        <v-text-field class="ml-10 mr-10" v-model="data[0].name" label="Nombre" solo
                                            required>
                                        </v-text-field>

                                        <label for="" class="mb-2 text-table-profile ml-10 mr-10">Teléfono</label>
                                        <v-text-field class="ml-10 mr-10" solo placeholder="Ingrese Teléfono"
                                            v-model="data[0].telephone" type="number" maxlength="10" @input="validateText" :value="max" required></v-text-field>

                                            <label for="" class="mb-2 text-table-profile ml-10 mr-10">Email</label>
                                        <v-text-field class="ml-10 mr-10" solo placeholder="Ingrese Email"
                                            v-model="data[0].email" type="email" required></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" align="left">
                                        <label for="" class="mb-2 text-table-profile ml-10 mr-10">Fecha de
                                            nacimiento</label>
                                        <v-text-field class="ml-10 mr-10" solo placeholder="Ingrese DD/MM/AAAA" type="date"
                                            required v-model="data[0].birthday"></v-text-field>
                                        <v-container class="px-0 ml-10 mr-10" fluid>
                                            <v-switch v-model="switch1">
                                                <template v-slot:label>
                                                    ¿El número tiene WhatsApp? &nbsp;
                                                    <v-icon> mdi-whatsapp </v-icon>
                                                </template>
                                            </v-switch>
                                        </v-container>
                                        <v-container class="px-0 ml-10 mr-10" fluid>
                                            <v-switch v-model="switch2">
                                                <template v-slot:label>
                                                    ¿Recibir info de Marketing? &nbsp;
                                                </template>
                                            </v-switch>
                                        </v-container>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="4" offset="4">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-btn :disabled="loadingEditData" depressed color="success" class="justify-text" large @click="editData()">
                                                    Confirmar
                                                    <v-icon style="margin-left: 0.5em;">
                                                        mdi-check-bold
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-btn depressed color="error" class="justify-text" large
                                                    v-on:click="isHidden = !isHidden">
                                                    Cancelar
                                                    <v-icon style="margin-left: 0.5em;">
                                                        mdi-window-close
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-row>

                        <v-row v-if="isHidden && isHiddenContact" class="justify-text ml-10 mr-10" justify="center">
                            <v-form ref="form">

                                <div class="text-justify text-general-module ma-4">
                                    <span class="ml-4 mr-4">AGREGAR CONTACTO</span>
                                </div>

                                <v-row>
                                    <v-col cols="12" md="6" align="left">
                                        <label for="" class="text-table-profile ml-10 mr-10">Nombre(s)</label>
                                        <v-text-field class="ml-10 mr-10" v-model="dataContactCurrent.name" label="Nombre(s)" solo
                                            required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" align="left">
                                        <label for="" class="text-table-profile ml-10 mr-10">Apellido(s)</label>
                                        <v-text-field class="ml-10 mr-10" v-model="dataContactCurrent.lastName" label="Apellido(s)" solo
                                            required>
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="6" align="left">
                                        <label for="" class="text-table-profile ml-10 mr-10">Correo</label>
                                        <v-text-field class="ml-10 mr-10" v-model="dataContactCurrent.email" label="Correo" solo
                                            required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" align="left">
                                        <label for="" class="text-table-profile ml-10 mr-10">Telefono</label>
                                        <v-text-field class="ml-10 mr-10" v-model="dataContactCurrent.phone" label="Telefono" solo
                                            required>
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="6" align="left">
                                        <v-checkbox
                                        class="ml-10 mr-10"
                                        v-model="dataContactCurrent.main"
                                        checked="checked"
                                        label="Es contacto principal"
                                        value="1"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="4" offset="4">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-btn :disabled="dataContactCurrent.sendFormContact" depressed color="success" class="justify-text" large @click="saveContact()">
                                                    <template v-if="!dataContactCurrent.id">Crear Contacto</template>
                                                    <template v-else>Modificar Contacto</template>
                                                    <v-icon style="margin-left: 0.5em;">
                                                        mdi-check-bold
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-btn depressed color="error" class="justify-text" large
                                                    v-on:click="resetShowsForm()">
                                                    Cancelar
                                                    <v-icon style="margin-left: 0.5em;">
                                                        mdi-window-close
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-row>

                        <v-row v-if="isHidden && isHiddenReception" class="justify-text ml-10 mr-10" justify="center">
                            <v-form ref="form">

                                <div class="text-justify text-general-module ma-4">
                                    <span class="ml-4 mr-4">Recepción del establecimiento:<u>{{ dataReceptionCurrent.reception.name }}</u></span>
                                </div>

                                <v-row dense>

                                    <div class="ma-4 ml-10 mb-4">
                                        <v-simple-table class="table-profile mb-4 ml-10 mr-10">
                                            <tbody>
                                                <tr>
                                                    <td class="text-table-profile">Día</td>
                                                    <td class="text-table-profile">Inicio</td>
                                                    <td class="text-table-profile">Fin</td>
                                                </tr>
                                                <tr v-for="(d, index)  in dataReceptionCurrent.day" :key="d.id">
                                                    <td>{{ daytext( index )}}</td>
                                                    <td>
                                                        <v-text-field class="ml-10 mr-10" v-model="dataReceptionCurrent.day[index].start" type="time" solo></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field class="ml-10 mr-10" v-model="dataReceptionCurrent.day[index].end" type="time" solo></v-text-field>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </div>
                                </v-row>

                                <v-row>
                                    <v-col cols="10" md="10" offset="2">
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <label for="" class="text-table-profile ml-10 mr-10">Documentos requeridos</label>
                                                <v-text-field class="ml-10 mr-10" v-model="documentosRequeridos" label="Documentos requeridos" solo>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="4" offset="4">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-btn :disabled="dataReceptionCurrent.sendFormReception" depressed color="success" class="justify-text" large @click="saveReception()">
                                                    Actualizar
                                                    <v-icon style="margin-left: 0.5em;">
                                                        mdi-check-bold
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-btn depressed color="error" class="justify-text" large
                                                    v-on:click="resetShowsForm()">
                                                    Cancelar
                                                    <v-icon style="margin-left: 0.5em;">
                                                        mdi-window-close
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-row>

                        <v-row v-if="isHidden && isHiddenPhoto" class="justify-text ml-10 mr-10" justify="center">
                            <v-form ref="form">

                                <div class="text-justify text-general-module ma-4">
                                    <span class="ml-4 mr-4">FOTOS DEL NEGOCIO</span>
                                </div>
                                <v-row dense>
                                    <v-col cols="12" md="6" align="left">
                                        <label for="" class="mb-2 text-table-profile ml-10 mr-10">Fachada <br> Tipo archivo: (.jpg .png) <v-icon>mdi-image</v-icon></label>
                                        <v-file-input class="ml-10 mr-10" solo placeholder="Fachada"
                                        v-model="form.facade_photo"></v-file-input>
                                        <a class="mb-2 text-table-profile ml-10 mr-10" v-if="dataDocument?.facade_photo" :href="urlServer+dataDocument?.facade_photo" target="_blank"><img class="img-fachada" :src="urlServer+dataDocument?.facade_photo"></a>
                                    </v-col>
                                    <v-col cols="12" md="6" align="left">
                                        <label for="" class="mb-2 text-table-profile ml-10 mr-10">Interior <br> Tipo archivo: (.jpg .png) <v-icon>mdi-image</v-icon></label>
                                        <v-file-input class="ml-10 mr-10" solo placeholder="Interior"
                                        v-model="form.indoor_photo"></v-file-input>
                                        <a class="mb-2 text-table-profile ml-10 mr-10" v-if="dataDocument?.indoor_photo" :href="urlServer+dataDocument?.indoor_photo" target="_blank"><img class="img-fachada" :src="urlServer+dataDocument?.indoor_photo"></a>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12" md="6" align="left">
                                        <label for="" class="mb-2 text-table-profile ml-10 mr-10">Mostrador/Bodega: <br> Tipo archivo: (.jpg .png) <v-icon>mdi-image</v-icon></label>
                                        <v-file-input class="ml-10 mr-10" solo placeholder="Mostrador/Bodega:"
                                        v-model="form.bar_photo"></v-file-input>
                                        <a class="mb-2 text-table-profile ml-10 mr-10" v-if="dataDocument?.bar_photo" :href="urlServer+dataDocument?.bar_photo" target="_blank"><img class="img-fachada" :src="urlServer+dataDocument?.bar_photo"></a>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="4" offset="4">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-btn :disabled="loadingEditData" depressed color="success" class="justify-text" large @click="editData()">
                                                    Confirmar
                                                    <v-icon style="margin-left: 0.5em;">
                                                        mdi-check-bold
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-btn depressed color="error" class="justify-text" large
                                                    v-on:click="resetShowsForm()">
                                                    Cancelar
                                                    <v-icon style="margin-left: 0.5em;">
                                                        mdi-window-close
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-row>
                    </div>
                </v-card>
            </v-col>

            <CoolLightBox 
                :items="items" 
                :index="index"
                @close="index = null">
            </CoolLightBox>
        </v-container>
    </div>
</template>
<script>

import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import axios from "axios";
import { mapGetters } from "vuex";

export default {
    name: 'ProfileUser',
    components: {
        CoolLightBox,
        Cropper
    },
    data() {
        return {
            switch1:false,
            switch2:false,
            max:10,
            items: [],
            documentosRequeridos: "",
            index: null,
            data: [{
                id: "",
                name: "",
                lastName: "",
                email: "",
                telephone: "",
                main: 0,
                whassap: false
            }],
            showPhoto:true,
            dataContactCurrent: {
                id: "",
                name: "",
                lastName: "",
                email: "",
                phone: "",
                main: 0,
                sendFormContact: false
            },
            dataReceptionCurrent: {
                reception:{
                    lzone: "",
                    name: "",
                    documentos_requeridos: ""
                },
                day: [
                    {
                        id: "",
                        start: "",
                        end: "",
                    },
                    {
                        id: "",
                        start: "",
                        end: "",
                    },
                    {
                        id: "",
                        start: "",
                        end: "",
                    },
                    {
                        id: "",
                        start: "",
                        end: "",
                    },
                    {
                        id: "",
                        start: "",
                        end: "",
                    },
                    {
                        id: "",
                        start: "",
                        end: "",
                    },
                    {
                        id: "",
                        start: "",
                        end: "",
                    }
                ],
                sendFormReception: false
            },
            dataContact: {},
            dataReception: {},
            dataDocument: {},
            dialog: false,
            image_loading: false,
            selectedFile: null,
            avatarStatus: true,
            avatarSize: 120,
            img: null,
            coordinates: null,
            isHidden: false,
            isHiddenProfile: false,
            isHiddenPhoto: false,
            isHiddenReception: false,
            isHiddenContact: false,
            urlServer:process.env.VUE_APP_BASE_IEL_SERVER + '/',
            form: {
                facade_photo: [],
                indoor_photo: [],
                bar_photo: []
            },
            loadingEditData:false,
            loadingEditDataText:"CONFIRMAR",
        }
    },
    mounted() {
        let self = this;
        if (localStorage.getItem("1us4-pv-cl13nt")) {
            
            self.client = localStorage.getItem("1us4-pv-cl13nt");
            self.getCustomerInformation();
        }
    },
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            user: "auth/user",
        })
    },
    methods: {
        formatDate(fechaStr){
            var fecha = new Date(fechaStr);

            // Obtener los componentes de la fecha y hora
            var year = fecha.getFullYear();
            var month = ("0" + (fecha.getMonth() + 1)).slice(-2); // Se agrega 1 al mes ya que en JavaScript los meses empiezan en 0
            var day = ("0" + fecha.getDate()).slice(-2);
            var hours = ("0" + fecha.getHours()).slice(-2);
            var minutes = ("0" + fecha.getMinutes()).slice(-2);
            var seconds = ("0" + fecha.getSeconds()).slice(-2);

            // Formatear la fecha en el formato deseado
            var fechaFormateada = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
            
            return fechaFormateada;
        },
        validateText() {
            
            if (this.data[0].telephone.length > 10) {
                
                this.max = parseInt(Number(this.data[0].telephone.slice(0, 10)));
                console.info( "A => ", this.max );
                this.data[0].telephone = null;
                console.info( "B => ", this.data[0].telephone );
                this.data[0].telephone = this.max;
                console.info( "C => ", this.data[0].telephone );
                return false;
            }
        },
        getCustomerInformation() {
            axios
            .post(process.env.VUE_APP_API_IEL_SERVER + "/infoCustomerVel", {
            customer: (this.client = JSON.parse(
                localStorage.getItem("1us4-pv-cl13nt")
            ).KUNNR),
            VKORG: "IUS2",
            VTWEG: "DT",
            VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
            })
            .then((response) => {
                
                if( response.data.CUSTOMER ){
                    localStorage.setItem( "id-client", response.data.CUSTOMER.user_id );
                    this.userInformation( response.data.CUSTOMER.user_id );
                }
                else{
                    this.$swal({
                        title: "El cliente aún no tiene un registro completo en nuestra base de datos",
                        icon: "error",
                    });
                }
            });
        },
        daytext( day ){
            let dayText = '';
            switch( day ){
                case 0:
                    dayText = 'Lunes';
                    break;
                case 1:
                    dayText = 'Martes';
                    break;
                case 2:
                    dayText = 'Miercoles';
                    break;
                case 3:
                    dayText = 'Jueves';
                    break;
                case 4:
                    dayText = 'Viernes';
                    break;
                case 5:
                    dayText = 'Sabado';
                    break;
                case 6:
                    dayText = 'Domingo';
                    break;
            }
            return dayText;
        },
        deleteContact(contact){

            var swalVar = this.$swal;
            var contactText = this._formatTextTransformCapitalize( contact.name+ ' ' + contact.last_name );

            this.$swal({
                title: "Estas seguro de eliminar el contacto " + contactText,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: 'Si, Elimnarlo',
                cancelButtonText: 'No'
            }).then(function ( result ) {
                console.info( result );
                if(result.isConfirmed) {
                    
                    axios
                    .post(process.env.VUE_APP_API_IEL_SERVER + "/contact-client/delete", {
                        client: localStorage.getItem('id-client'),
                        contact: contact.id
                    })
                    .then((response) => {
                        
                        if( response.data.result == 1 ){
                            
                            swalVar({
                                title: response.data.message,
                                icon: "success",
                                type: "succes"
                            }).then(function () {
                                location.reload();
                            });
                        }
                        else{

                            swalVar({
                                title: response.data.message,
                                icon: "error",
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => (this.dialogLoading = false));
                }
            });
        },
        editContact(contact){
            
            this.dataContactCurrent = {
                id: contact.id,
                name: contact.name,
                lastName: contact.last_name,
                email: contact.email,
                phone: contact.phone,
                main: contact.main,
                sendFormContact: false
            };
            
            this.isHiddenProfile = false;
            this.isHiddenContact = true;
            this.isHiddenPhoto = false;
            this.isHiddenReception = false;
            this.isHidden = true;
        },
        editReception(receptionCurrent){
            this.showPhoto = false;            

            this.dataReceptionCurrent.reception.lzone = receptionCurrent.reception.LZONE;
            this.dataReceptionCurrent.reception.name = receptionCurrent.reception.STRAS + ' ' + receptionCurrent.reception.ORT01;
            this.dataReceptionCurrent.reception.documentos_requeridos = receptionCurrent.reception.documentos_requeridos;
            this.documentosRequeridos = receptionCurrent.reception.documentos_requeridos;
            this.dataReceptionCurrent.day.map((d, i)=>{
                let dayCurrent = [];
                
                dayCurrent = receptionCurrent.days.filter((d)=>{
                    return d.day == i;
                });
                if( dayCurrent.length > 0 ){
                    this.dataReceptionCurrent.day[i] = dayCurrent[0];
                }
            });

            console.info( this.dataReceptionCurrent );
            this.isHiddenProfile = false;
            this.isHiddenContact = false;
            this.isHiddenPhoto = false;
            this.isHiddenReception = true;
            this.isHidden = true;
        },
        userInformation(userId) {
            
            this.loading = true;
            axios.post(process.env.VUE_APP_API_IEL_SERVER + "/userInformation", {
                user_id: userId,
            }).then((response) => {
                this.data = response.data;
                this._getContactList();
                this._getDocumentData();
                this._getReceptionList();
                console.log(response);
                console.log(response.data[0].name);
                this.network = true;

                if (response.data[0].whatsapp == '1') {
                    this.switch1 = true;
                }

                if (response.data[0].marketing_messages == '1') {
                    this.switch2 = true;
                }

                if (response.data[0].whatsapp == '0') {
                    this.switch1 = false;
                }

                if (response.data[0].marketing_messages == '0') {
                    this.switch2 = false;
                }
            })
            .catch((e) => {
                console.log(e);
            });
        },
        change({ coordinates }) {
            this.coordinates = coordinates
        },
        onFileSelected(event) {
            try {
                this.selectedFile = event.target.files[0]
                this.img = URL.createObjectURL(this.selectedFile)
            } catch {
                this.img = null
            }
        },
        onUpload() {
            const fd = new FormData()
            this.image_loading = true
            fd.append('image', this.selectedFile, this.selectedFile.name)
            fd.append('height', this.coordinates.height)
            fd.append('width', this.coordinates.width)
            fd.append('left', this.coordinates.left)
            fd.append('top', this.coordinates.top)
            fd.append('user_id', localStorage.getItem('id-client'))

            axios.post(process.env.VUE_APP_API_IEL_SERVER + '/editImage', fd, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((res) => {
                
                this.data[0].image_url = res.data.data;

                this.$swal({
                    title: "Imagen actualizada",
                    icon: "success",
                    type: "succes"
                }).then(function () {
                    location.reload();
                }
                );
            }).catch((err) => {
                console.log(err)
            })
            this.dialog = false
            this.$forceUpdate()
        },
        editData() {

            if (
                this.data[0].name == "" ||
                this.data[0].telephone == "" ||
                this.data[0].birthday == ""
            ) {
                this.$swal({
                    title: "Los campos no pueden ir vacios.",
                    icon: "error",
                });
            } else {
                this.loadingEditData = true;
                let ed = new FormData()
                ed.append('name', this.data[0].name)
                ed.append('telephone', this.data[0].telephone)
                ed.append('email', this.data[0].email)
                ed.append('birthday', this.data[0].birthday)
                ed.append('indoor_photo', this.form.indoor_photo)
                ed.append('facade_photo', this.form.facade_photo)
                ed.append('bar_photo', this.form.bar_photo)
                ed.append('user_id', localStorage.getItem( 'id-client' ))
                ed.append('custumer_code', JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR)

                if (this.switch1 == true) {
                    ed.append('switch1', 1)
                } else {
                    ed.append('switch1', 0)
                }

                if (this.switch2 == true) {
                    ed.append('switch2', 1)
                } else {
                    ed.append('switch2', 0)
                }
                
                axios.post(process.env.VUE_APP_API_IEL_SERVER + '/editProfileDataVel', ed).then((res) => {
                    console.log(res.data);
                    localStorage.setItem( 'id-client', res.data );
                    this.$swal({
                        title: "Actualizado",
                        icon: "success",
                        type: "succes"
                    }).then(function () {
                        location.reload();
                    });
                }).catch((err) => {
                    this.loadingEditData = false;
                    console.log(err)
                })
            }
        },
        _getContactList() {
            axios
            .post(process.env.VUE_APP_API_IEL_SERVER + "/contact-client/list", {
                client: localStorage.getItem('id-client')
            })
            .then((response) => {
                
                if( response.data.result == 1 ){
                    this.dataContact = response.data.data;
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => (this.dialogLoading = false));
        },
        _getDocumentData(){
            this.loading = true;            
            axios.post(process.env.VUE_APP_API_IEL_SERVER + "/get-data-document-customer", {
                client_id: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
            }).then((response) => {
                if( response.data.result == 1 ){
                    this.dataDocument = response.data.data;
                    this.items = [];
                    if(this.dataDocument?.facade_photo){
                        this.items.push({
                            description: "Fachada",
                            title: "Fachada",
                            src: this.urlServer+this.dataDocument.facade_photo
                        });
                    }
                    if(this.dataDocument?.indoor_photo){
                        this.items.push({
                            description: "Interior",
                            title: "Interior",
                            src: this.urlServer+this.dataDocument.indoor_photo
                        });
                    }
                    if(this.dataDocument?.bar_photo){
                        this.items.push({
                            description: "Mostrador/Bodega:",
                            title: "Mostrador/Bodega:",
                            src: this.urlServer+this.dataDocument.bar_photo
                        });
                    }
                    console.info( "items => ", this.items );
                }
                this.loading = false;
            })
            .catch((e) => {
                console.log(e);
            });
        },
        _getReceptionList() {
            axios
            .post(process.env.VUE_APP_API_IEL_SERVER + "/profile/reception-list", {
                customer: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
                vkorg: "IUS2",
                vtweg: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).VTWEG,
                client: localStorage.getItem('id-client')
            })
            .then((response) => {
                
                if( response.data.result == 1 ){
                    this.dataReception = response.data.data;
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => (this.dialogLoading = false));
        },
        _formatTextTransformCapitalize(text){
            
            // Separa el texto en palabras individuales
            var words = text.split(' ');

            // Itera sobre cada palabra y capitaliza la primera letra
            for (var i = 0; i < words.length; i++) {
                var word = words[i];

                // Verifica si la palabra no está vacía
                if (word.length > 0) {
                // Capitaliza la primera letra y concatena con el resto de la palabra
                words[i] = word.charAt(0).toUpperCase() + word.slice(1);
                }
            }

            // Une las palabras nuevamente en un solo texto
            var capitalizedText = words.join(' ');

            return capitalizedText;
        },
        resetShowsForm(){
            this.isHidden = false;
            this.showPhoto = true;
        },
        saveContact(){
            this.dataContactCurrent.sendFormContact = true;
            if (
                this.dataContactCurrent.name == "" ||
                this.dataContactCurrent.lastName == "" ||
                this.dataContactCurrent.phone == "" ||
                this.dataContactCurrent.email == ""
            ) {
                this.dataContactCurrent.sendFormContact = false;
                this.$swal({
                    title: "Los campos no pueden ir vacios.",
                    icon: "error",
                });
            } else {
                //  accion de editar
                let url = "";
                if(this.dataContactCurrent.id){

                    url = process.env.VUE_APP_API_IEL_SERVER +'/contact-client/update/'+this.dataContactCurrent.id;
                }
                else{

                    url = process.env.VUE_APP_API_IEL_SERVER +'/contact-client/save';
                }
                
                let ed = new FormData();
                ed.append('name', this.dataContactCurrent.name);
                ed.append('last_name', this.dataContactCurrent.lastName);
                ed.append('phone', this.dataContactCurrent.phone);
                ed.append('email', this.dataContactCurrent.email);
                ed.append('main', this.dataContactCurrent.main);
                ed.append('client_id', localStorage.getItem('id-client'));
                
                axios.post( url, ed)
                .then((response) => {
                    
                    if( response.data.result == 1 ){

                        this.$swal({
                            title: response.data.message,
                            icon: "success",
                            type: "succes"
                        }).then(function () {
                            location.reload();
                        });
                    }
                    else{

                        this.$swal({
                            title: response.data.message,
                            icon: "error",
                        });
                        this.dataContactCurrent.sendFormContact = false;
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => (this.dialogLoading = false));
            }
        },
        saveReception(){

            this.dataReceptionCurrent.sendFormReception = true;
            
            let ed = new FormData();
            ed.append('lzone', this.dataReceptionCurrent.reception.lzone);
            ed.append('client', localStorage.getItem('id-client'));
            ed.append('documentos_requeridos', this.documentosRequeridos );
            
            for( let d = 0; d < this.dataReceptionCurrent.day.length; d++ ){

                if(
                    ( this.dataReceptionCurrent.day[d].start && !this.dataReceptionCurrent.day[d].end ) ||
                    ( !this.dataReceptionCurrent.day[d].start && this.dataReceptionCurrent.day[d].end )
                ){

                    this.$swal({
                        title: "El dia debe tener hora inicio y fin",
                        icon: "error",
                    });
                    this.dataContactCurrent.sendFormReception = false;
                    return false;
                }

                ed.append('day[]', this.dataReceptionCurrent.day[d].id );
                ed.append('start[]', this.dataReceptionCurrent.day[d].start );
                ed.append('end[]', this.dataReceptionCurrent.day[d].end );
            }
            
            axios.post( process.env.VUE_APP_API_IEL_SERVER + '/profile/reception-save', ed)
            .then((response) => {
                
                if( response.data.result == 1 ){

                    this.$swal({
                        title: response.data.message,
                        icon: "success",
                        type: "succes"
                    }).then(function () {
                        location.reload();
                    });
                }
                else{

                    this.$swal({
                        title: response.data.message,
                        icon: "error",
                    });
                    this.dataContactCurrent.sendFormReception = false;
                }
            })
            .catch((error) => {
                console.error(error);
                this.$swal({
                    title: error,
                    icon: "error",
                });
                this.dataContactCurrent.sendFormReception = false;
            })
            .finally(() => (this.dialogLoading = false));
        },
        selectEdit(type){
            
            if( type == 1){
                this.showPhoto = true;
                this.isHiddenProfile = true;
                this.isHiddenPhoto = false;
                this.isHiddenReception = false;
                this.isHiddenContact = false;
            }
            else if( type == 2){
                this.dataContactCurrent = {
                    id: "",
                    name: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    main: 0,
                    sendFormContact: false
                }
                this.showPhoto = false;
                this.isHiddenProfile = false;
                this.isHiddenContact = true;
                this.isHiddenPhoto = false;
                this.isHiddenReception = false;
            }
            else if( type == 3){
                this.showPhoto = false;
                this.isHiddenProfile = false;
                this.isHiddenPhoto = false;
                this.isHiddenReception = true;
                this.isHiddenContact = false;
            }
            else if( type == 4){
                this.showPhoto = false;
                this.isHiddenProfile = false;
                this.isHiddenReception = false;
                this.isHiddenContact = false;
                this.isHiddenPhoto = true;
            }
            this.isHidden = true;
        },
        select_facade_photo(event) {
            this.form.facade_photo = event.target.files[0];
        },
        select_indoor_photo(event) {
            this.form.indoor_photo = event.target.files[0];
        },
        select_bar_photo(event) {
            this.form.bar_photo = event.target.files[0];
        },
    },
    watch: {
        clientCurrent: function () {
            window.location.reload();
            this.getCustomerInformation();
        },
    },
}
</script>
<style>
 .img-fachada{
    border-radius: 1em;
    height:15em;
    width:15em;
 }
 :root {
    --primary-color: "#3668BC";
    --secondary-color: "#3668BC";
    --accent-color: "#B7970C";
    --error-color: "#E20028";
}

.background-grey {
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 18%,
    rgba(255, 255, 255, 1) 50%,
    rgba(210, 210, 210, 1) 100%
  );
}

.background-gradient-card {
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 18%,
    rgba(255, 255, 255, 1) 75%,
    rgba(224, 222, 222, 1) 100%
  );
}

.background-gradient-img-card {
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 18%,
    rgba(255, 255, 255, 1) 89%,
    rgba(224, 222, 222, 1) 100%
  );
}

.login-header {
  color: #ff000a;
  font-weight: 500;
}

.login-title {
  font-size: 22px;
  font-weight: 600;
  color: #000 !important;
}

.navbar-brand img {
  width: 56px;
  height: 56px;
}

.navbar-brand {
  padding: 0px;
  margin: 0px;
}

nav {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}

.icon {
  color: #dc3545;
  margin-right: 12px;
}

.blue-label label {
  color: #1086D4 !important;
  font-weight: bold;
}

.red-label label {
  color: red !important;
  font-weight: bold;
}

.gold-label label {
  color: #daa520 !important;
  font-weight: bold;
}

.green-label label {
  color: #25d366 !important;
  font-weight: bold;
}

.switch-center {
  display: flex;
  justify-content: center;
}

.border-title.v-card {
  border-width: 20px !important;
  color: green !important;
}

.button-style button {
  background-color: #2fd410;
  color: #fff;
  text-transform: none;
  font-weight: 800;
}

.text-big-num {
  font-size: 40px;
  color: black !important;
}

.text-general {
  font-size: 20px;
  font-weight: bold;
  color: gray !important;
}

.text-desp {
  font-size: 20px;
  font-weight: bold;
  color: #1086D4 !important;
}

.text-niv {
  font-size: 20px;
  font-weight: bold;
  color: red !important;
}

.text-gold {
  font-size: 20px;
  font-weight: bold;
  color: #daa520 !important;
}

.boder-side {
  border-left: 1px solid #cccccc !important;
}

.boder-side-small {
  border-left: 1px solid #f2f2f2 !important;
}

.black-label label {
  color: rgba(0, 0, 0) !important;
  font-weight: bold;
}

.text-header-anounce {
  color: black;
  font-size: 30px;
  font-weight: bold;
  margin-left: 5px;
  vertical-align: bottom;
  margin-right: 5px;
}

.text-header-anounce-subtitle {
  color: black;
  font-size: 25px;
  font-weight: lighter;
  margin-left: 5px;
  vertical-align: bottom;
  margin-right: 5px;
}

.text-header-red {
  color: red;
  font-size: 30px;
  font-weight: bold;
  margin-left: 5px;
  vertical-align: bottom;
  margin-right: 5px;
}

.icon-red {
  color: red;
}

.text-light-gray {
  color: gray;
  font-size: 18px;
  font-weight: lighter;
}

.text-dark-bold {
  color: #000;
  font-size: 20px;
  font-weight: bolder;
}

.text-dark-card {
  color: #000;
  font-size: 18px;
  font-weight: bolder;
}

.text-dark-lider {
  color: #000;
  font-size: 15px;
  font-weight: bolder;
}
.text-dark-decrip {
  color: #000;
  font-size: 14px;
}

.INN {
  color: #fff;
  background: #0c8a08;
}

.ST {
  color: #fff;
  background: #0400ff;
}

.Disponible {
  color: #0c8a08 !important;
}

.SemiDisponible {
  color: #ffd104 !important;
}

.Parcial {
  color: #ffd104 !important;
}

.SinExistencia {
  color: #ff000a !important;
}

.imageCarItem {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), S 0 3px 6px rgba(0, 0, 0, 0.23);
}

.imageCarItem {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), S 0 3px 6px rgba(0, 0, 0, 0.23);
}
.text-ped {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #000 !important;
}

.text-type-ped{
  background-color: red !important;
  font-size: 20px !important;
  font-weight: bold !important;
  color: #fff !important;
}

.text-ped-links {
  font-size: 10px;
  font-weight: bold;
  color: #000 !important;
  padding: 10px;
}
.pedidos-card {
  background: rgb(238, 238, 238);
  background: linear-gradient(
    184deg,
    rgba(238, 238, 238, 1) 0%,
    rgba(238, 238, 238, 1) 9%,
    rgba(255, 255, 255, 1) 19%
  );
}

.pedidos-card-sugerencias {
  background: rgb(238, 238, 238);
  background: linear-gradient(
    184deg,
    rgba(238, 238, 238, 1) 0%,
    rgba(238, 238, 238, 1) 9%,
    rgba(255, 255, 255, 1) 19%
  );
  padding: 10px;
}

.my-border {
  border-bottom: 2px solid white;
}

.text-chart-sub {
  font-size: 17px;
  font-weight: bold;
  color: #000 !important;
}

.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  color: #404040 !important;
}

.text-general-module {
  font-size: 20px;
  font-weight: bold;
  color: black !important;
}

.text-profile-module {
  font-size: 20px;
  color: grey !important;
}

.text-table-profile {
  font-size: 15px;
  font-weight: bold;
  color: black !important;
}

.text-quoter-module {
  font-size: 20px;
  font-weight: bold;
  color: green !important;
}
.content-profile-user .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
</style>