<template>
  <div class="container-fluid grey lighten-5 pt-4">
    <div
      class="alert alert-danger"
      role="alert"
      v-if="hasError"
      style="font-weight: 500"
    >
      {{ msg }}
    </div>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      top
      color="deep-purple accent-4"
    ></v-progress-linear>
    <br />
    <v-card
      class="mx-auto rounded-lg elevation-4 mb-4"
      outlined
      :style="'border: 4px solid red;'"
    >
      <v-list-item-content>
        <div class="text-niv" style="text-align: center">
          <v-icon style="color: red !important">mdi-calendar-check</v-icon>
          NIVELES DE SERVICIO
        </div>
      </v-list-item-content>
    </v-card>
    <v-card v-if="dataFillRate.length > 0">
      <!-- <h2 class="h2">Fill Rate</h2> -->
      <div class="card-body text-lg-center">
        <v-data-table
          :headers="headers_fill_rate"
          :items="dataFillRate"
          :items-per-page="10"
          item-key="DESCR"
          class="elevation-1"
          locale="esp"
          hide-default-footer
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
          }"
        ></v-data-table>
      </div>
    </v-card>

    <v-progress-linear
      :active="loadingOrderDeliveryTime"
      :indeterminate="loadingOrderDeliveryTime"
      top
      color="deep-purple accent-4"
    ></v-progress-linear>
    <br />
    <v-card
      class="mx-auto rounded-lg elevation-4 mb-4"
      outlined
      :style="'border: 4px solid red;'"
    >
      <v-list-item-content>
        <div class="text-niv" style="text-align: center">
          <v-icon style="color: red !important">mdi-calendar-check</v-icon>
          TIEMPO DE ENTREGA DE PEDIDOS EN LOS ÚLTIMOS 3 MESES
        </div>
      </v-list-item-content>
    </v-card>
    <v-card v-if="dataOrderDeliveryTime.length > 0">
      <div class="card-body text-lg-center">
        <!-- <h2 class="h2">Tiempo de Entrega de Pedidos en los últimos 3 meses</h2> -->
        <v-data-table
          :headers="headers_order_delivery_time"
          :items="dataOrderDeliveryTime"
          :items-per-page="10"
          item-key="DESCR"
          class="elevation-1"
          locale="esp"
          hide-default-footer
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
          }"
        ></v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "FillRate",
  components: {},
  props: {
    clientCurrent: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      dataFillRate: [],
      dataOrderDeliveryTime: [],
      totals: [],
      loading: true,
      loadingOrderDeliveryTime: true,
      msg: null,
      hasError: false,
      timeout: 8000,
      headers_fill_rate: [
        { text: "PERIODO", value: "SPMON" },
        { text: "CANT ACUMULADA", value: "KWMENG" },
        { text: "CANT FACTURADA", value: "FKIMG" },
        { text: "% CANTIDAD", value: "PORC" },
        { text: "IMP POS", value: "NETWR" },
        { text: "% LINEAS", value: "PORC1" },
        { text: "LINEAS PEDIDAS", value: "LINEASP" },
        { text: "LINEAS FACTURADAS", value: "LINEASF" },
        { text: "PROM DIAS", value: "PROM" },
      ],
      headers_order_delivery_time: [
        { text: "Pedidos Totales", align: "center", value: "PED_TOTALES" },
        {
          text: "promedio de días de entrega",
          align: "center",
          value: "PED_PRODENT",
        },
        {
          text: "Entregados en 1 día hábil",
          align: "center",
          value: "PED_ENT1DIA",
        },
        {
          text: "Entregados en 2 día hábil",
          align: "center",
          value: "PED_ENT2DIA",
        },
        {
          text: "Entregados en 3 día hábil",
          align: "center",
          value: "PED_ENT3DIA",
        },
      ],
      form: {
        client: "",
        channel: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    submitFillRate() {
      this.form.client = JSON.parse(
        localStorage.getItem("1us4-pv-cl13nt")
      ).KUNNR;
      this.loading = true;

      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/fillRate", this.form)
        .then((response) => {
          this.dataFillRate = response.data.dataFillRate;
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.msg = error.response.data.errors;
            this.hasError = true;
            setTimeout(() => {
              this.hasError = false;
            }, 8000);
          } else {
            console.warn(error);
          }
        })
        .finally(() => (this.loading = false));
    },
    submitOrderDeliveryTime() {
      this.form.client = JSON.parse(
        localStorage.getItem("1us4-pv-cl13nt")
      ).KUNNR;
      this.form.channel = JSON.parse(
        localStorage.getItem("1us4-pv-cl13nt")
      ).VTWEG;
      this.loadingOrderDeliveryTime = true;

      axios
        .post(
          process.env.VUE_APP_API_IEL_SERVER + "/orderDeliveryTime",
          this.form
        )
        .then((response) => {
          console.log(response);
          this.dataOrderDeliveryTime = response.data.dataOrderDeliveryTime;
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.msg = error.response.data.errors;
            this.hasError = true;
            setTimeout(() => {
              this.hasError = false;
            }, 8000);
          } else {
            console.warn(error);
          }
        })
        .finally(() => (this.loadingOrderDeliveryTime = false));
    },
  },
  mounted() {
    if( localStorage.getItem("1us4-pv-cl13nt") ){
      this.submitFillRate();
      this.submitOrderDeliveryTime();
    }
  },
  watch: {
    clientCurrent: function () {
      this.submitFillRate();
      this.submitOrderDeliveryTime();
    },
  },
};
</script>

<style scoped>
.label {
  font-weight: 700;
}
</style>
