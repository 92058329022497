<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col>
          <v-card
            class="mx-auto rounded-lg elevation-4 ma-3 mb-7"
            outlined
            :style="'border: 4px solid green; background-color:transparent;'"
          >
            <v-list-item-content>
              <div
                class="text-quoter-module"
                style="color: green; text-align: center"
              >
                <v-icon color="green">mdi-cart-arrow-down</v-icon>
                Historial Cotizaciones
              </div>
            </v-list-item-content>
          </v-card>
          <v-card
            cols="12"
            class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-7"
          >
            <v-card-title>
              <v-btn color="success" size="x-large" to="/quoter-cart">
                <v-icon light>mdi-cart-plus</v-icon>
                Crear Cotización
              </v-btn>
            </v-card-title>
            <v-row>
              <v-col md="6"></v-col>
              <v-col md="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <br />
            <v-data-table
              dense
              :headers="headers"
              :items-per-page="10"
              :items="data"
              :search="search"
              item-key="id"
              no-data-text="No hay datos disponibles"
            >
              <template v-slot:[`item.name`]="{ item }">
                <a @click="getHistoryQuoters(item)">{{ item.name }}</a>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <span>{{ getFormattedDate(item.created_at) }}</span>
              </template>
              <template v-slot:[`item.estatus`]="{ item }">
                <span v-if="item.estatus == 2">Guardado</span>
              </template>
              <template v-slot:[`item.validation`]="{ item }">
                <span>{{ getFormattedDate(item.validation) }}</span>
              </template>
              <template v-slot:[`item.quoter_type`]="{ item }">
                <span v-if="item.quoter_type == '1'">Cliente</span>
                <span v-if="item.quoter_type == '2'">Cliente/Cliente</span>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="margin-left: 5px"
                      class="ma-1 elevation-0"
                      rounded
                      color="error"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="createPDF(item)"
                    >
                      <v-icon> mdi-file-pdf-box </v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar Cotización PDF</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.id != ''"
                      style="margin-left: 5px"
                      class="ma-1 elevation-0"
                      rounded
                      color="secondary"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="senDEmail(item)"
                      :disabled="item.quoter_type == '2'"
                    >
                      <v-icon> mdi-email-arrow-right-outline </v-icon>
                    </v-btn>
                  </template>
                  <span>Enviar por Correo</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="margin-left: 5px"
                      class="ma-1 elevation-0"
                      rounded
                      color="warning"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="QuoterCartEdit(item.id)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar Cotización</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="margin-left: 5px"
                      class="ma-1 elevation-0"
                      color="success"
                      rounded
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="generateOrder(item)"
                    >
                      <v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Generar Pedido</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="margin-left: 5px"
                      class="ma-1 elevation-0"
                      dark
                      rounded
                      color="red darken-2"
                      small
                      @click="deleteItem(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon style="color: #fff !important">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar Cotización</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
          <v-card>
            <v-card-title> Historial </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6"></v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <br />
              <v-data-table
                dense
                :headers="headers_2"
                :items-per-page="10"
                :items="data1"
                :search="search"
                item-key="id"
                no-data-text="No hay datos disponibles"
              >
                <template v-slot:[`item.created_at`]="{ item }">
                  <span>{{ getFormattedDate(item.created_at) }}</span>
                </template>
                <template v-slot:[`item.estatus`]="{ item }">
                  <span
                    v-if="item.estatus == 2 && item.validation >= new Date()"
                    >Guardado</span
                  >
                  <span v-if="item.estatus == 3">Genero Pedido</span>
                </template>
                <template v-slot:[`item.validation`]="{ item }">
                  <span>{{ getFormattedDate(item.validation) }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Dialog loading -->
      <v-row justify="center">
        <v-dialog v-model="loading" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Cargando
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <loading-bar :capturedDialogLoading="dialogLoading"></loading-bar>
      <!-- Dialog loading -->
      <!-- Dialog loading -->
      <v-dialog v-model="dialog_del" persistent width="auto">
        <v-card>
          <v-card-title class="text-h5">
            ¿ Realmente desea eliminar esta Cotización ?
          </v-card-title>
          <v-card-text></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="text" @click="dialog_del = false">
              Cancelar
            </v-btn>
            <v-btn color="error" variant="text" @click="deleteOrderQuoter()">
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog loading -->
      <!-- /. Dialog -->
      <v-dialog v-model="dialog_email" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Enviar por Correo
          </v-card-title>
          <v-card-text>
            <p></p>
            <h6 style="text-align: left; font-weight: 700">
              Folio: {{ folio }}
            </h6>
            <p></p>
            <v-row>
              <v-col md="12">
                <div class="form-group">
                  <label>Ingresar Email:</label>
                  <v-text-field solo v-model="emai_user">{{
                    emai_user
                  }}</v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" text @click="close2()"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="sendEmailXMLPDF()">
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- /. Dialog -->
      <!-- /. Dialog -->
      <v-dialog v-model="dialog_hist" width="auto" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Historial
          </v-card-title>
          <v-card-text>
            <p></p>
            <h6 style="text-align: left; font-weight: 700">
              Número de Cotización: {{ folio }}
            </h6>
            <p></p>
            <v-row>
              <v-col md="12">
                <br />
                <v-data-table
                  dense
                  :headers="headers_3"
                  :items-per-page="10"
                  :items="data2"
                  :search="search"
                  item-key="id"
                  no-data-text="No hay datos disponibles"
                >
                  <template v-slot:[`item.created_at`]="{ item }">
                    <span>{{ getFormattedDate(item.created_at) }}</span>
                  </template>
                  <template v-slot:[`item.validation`]="{ item }">
                    <span>{{ getFormattedDate(item.validation) }}</span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close3()"> Cerrar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- /. Dialog -->
    </template>
  </v-container>
</template>
<script>
import axios from "axios";
import LoadingBar from "./componets/LoadingBar.vue";
import { mapGetters } from "vuex";
import { eventBus } from "@/main.js";
import moment from "moment";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  components: {
    LoadingBar,
  },
  data: () => ({
    id_order: null,
    dialog_del: false,
    dialog_email: false,
    dialog_hist: false,
    selected: [],
    orderShop: [],
    order_array: [],
    disabledCount: 0,
    data: [],
    data1: [],
    data2: [],
    dialog: false,
    search: "",
    search1: "",
    message_order: "",
    buton_order: false,
    emai_user: null,
    show: false,
    folio: null,
    dialogLoading: false,
    orderQuery: [],
    quoter_name: null,
    client: "",
    client_info: "",
    clients: [],
    headers: [
      { text: "Fecha", align: "start", value: "created_at" },
      { text: "Número Cotización", align: "start", value: "name" },
      { text: "Número Partidas", sortable: false, value: "materials" },
      { text: "Importe Total", sortable: false, value: "total" },
      { text: "Estatus", sortable: false, value: "estatus" },
      { text: "Vigencia", sortable: false, value: "validation" },
      { text: "Tipo Cotización", sortable: false, value: "quoter_type" },
      { text: "Acciones", sortable: false, value: "id" },
    ],

    headers_2: [
      { text: "Fecha", align: "start", value: "created_at" },
      { text: "Numero Cotización", align: "start", value: "name" },
      { text: "Orden de Compra", align: "start", value: "order_name" },
      { text: "Pedido", align: "start", value: "nu_pedido" },
      { text: "Número Partidas", sortable: false, value: "materials" },
      { text: "Importe Total", sortable: false, value: "total" },
      { text: "Estatus", sortable: false, value: "estatus" },
      { text: "Vigencia", sortable: false, value: "validation" },
    ],

    headers_3: [
      { text: "Fecha", align: "start", value: "created_at" },
      { text: "Número Cotización", align: "start", value: "name" },
      { text: "Número Partidas", sortable: false, value: "materials" },
      { text: "Importe Total", sortable: false, value: "total" },
      { text: "Vigencia", sortable: false, value: "validation" },
    ],

    loading: false,
    pedidoSelect: null,
    pedidoInfo: null,
    pedidoImporte: null,
    item: {},
  }),
  created() {
    eventBus.$on("change-client", (data) => {
      console.log(
        "change-client => ",
        data,
        " client => ",
        this.client,
        " storage => ",
        JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR
      );
      this.p = 1;
      this._validateClient();
    });
  },
  mounted() {
    this._validateClient();

    this.getPedidoHistory();
    this.getPedidoHistoryLast();
    //  this.emai_user = this.user.email;
  },
  methods: {
    _validateClient() {
      if (localStorage.getItem("1us4-pv-cl13nt")) {
        this.client = JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR;
        this.client_info = JSON.parse(localStorage.getItem("1us4-pv-cl13nt"));
        localStorage.setItem("1us4-p0rt4l-v3nd3nd0r-cl13n7", this.client);
        this.clientCurrent = true;
        this._infoClient(this.client_info);
        this.getPedidoHistory();
        this.getPedidoHistoryLast();
      }
    },
    getFormattedDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getPedidoHistory() {
      this.clientCurrent = true;
      let type = "VE";
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/quoterHistory", {
          customer: this.client,
          user_id: this.user.id,
          type: type,
        })
        .then((response) => {
          this.data = response.data;
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    getPedidoHistoryLast() {
      this.clientCurrent = true;
      let type = "VE";
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/quoterHistoryRead", {
          customer: this.client,
          user_id: this.user.id,
          type: type,
        })
        .then((response) => {
          this.data1 = response.data;
        })
        .catch((error) => {
          //(error);
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    _infoClient(client) {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/infoCustomer", {
          customer: client.KUNNR,
          VKORG: client.VKORG,
          VTWEG: client.VTWEG,
          VKBUR: client.VKBUR,
        })
        .then((response) => {
          this.emai_user = response.data.SMTP_ADDR;
        })
        .catch((error) => {
          console.warn(error);
        })
        .finally(() => (this.loading = false));
    },
    QuoterCartEdit(data) {
      this.orderQuery.push({
        order_q: data,
      });

      //this.$router.push("quoter-cart-edit/" + data);
      this.$router.push({
        name: "QuoterCartEdit",
        query: { data: data },
      });
    },
    deleteItem(item) {
      console.log(item);
      this.id_order = item.id;
      this.dialog_del = true;
    },
    deleteOrderQuoter() {
      //alert(this.id_order);
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/deleteOrderQuoter", {
          order_id: this.id_order,
        })
        .then((response) => {
          this.data = response.data;
          this.dialog_del = false;
          this.$swal({
            title: "Exitoso",
            icon: "success",
            type: "succes",
          }).then(function () {
            //this.getRecordatoriosHistory();
            location.reload();
          });
        })
        .catch((error) => {
          this.dialog_del = false;
          //alert(error);
          console.log(error);
        });
    },
    createPDF(item) {
      this.dialogLoading = true;
      axios
        .post(
          process.env.VUE_APP_API_IEL_SERVER + "/quoterPdf",
          {
            order_id: item.id,
            customer: this.client,
            VKORG: "IUS2",
            VTWEG: "DT",
            VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
            quoter_type: item.quoter_type,
            customer_customer: item.customer_customer,
            customer_seller: item.customer_seller,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          //this.data = response.data;
          console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "cotizacion.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally(
          () => ((this.dialogLoading = false), (this.submitted = false))
        );
    },
    senDEmail(item) {
      console.log(item);
      this.id_order = item.id;
      this.folio = item.name;
      this.quoter_type = item.quoter_type;
      this.dialog_email = true;
    },
    close2() {
      this.dialog_email = false;
    },
    close3() {
      this.dialog_hist = false;
    },
    getHistoryQuoters(item) {
      console.log(item);
      this.id_order = item.id;
      this.folio = item.name;
      this.quoter_name = item.name;
      this.dialog_hist = true;
      this.getHistoryQuoterEdit();
    },
    getHistoryQuoterEdit() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/getHistoryQuoterEdit", {
          customer: this.client,
          quoter_name: this.quoter_name,
        })
        .then((response) => {
          this.data2 = response.data;
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    sendEmailXMLPDF() {
      this.dialogLoading = true;
      this.dialog_email = false;
      console.log(this.emai_user);
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/quoterPDFEmail", {
          email: this.emai_user,
          order_id: this.id_order,
          customer: this.client,
          VKORG: "IUS2",
          VTWEG: "DT",
          VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
          quoter_type: this.quoter_type,
        })
        .then((response) => {
          console.log(response.data);

          this.message = response.data;

          this.$swal({
            title: response.data.message,
            icon: "success",
            type: "succes",
          }).then(function () {
            //this.getRecordatoriosHistory();
          });
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally(
          () => ((this.dialogLoading = false), (this.submitted = false))
        );
    },
    generateOrder(item) {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/createOrder", {
          order_id: item.id,
          customer: item.customer_id,
          user_id: item.user_id,
          type: item.type,
          destiny: item.destiny,
          total: item.total,
        })
        .then((response) => {
          console.log(response.data);
          this.$router.push({
            name: "QuoterCartShopping",
          });
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
        })
        .finally();
    },
  },
  destroyed() {
    eventBus.$off("change-client");
  },
};
</script>
