<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col>
          <v-card
            cols="12"
            class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-7"
          >
            <v-card-title>HISTORIAL PEDIDO</v-card-title>
            <v-row>
              <v-col md="6">
                <v-row>
                  <v-col md="4">
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="start_date"
                          label="Fecha Inicial"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="start_date"
                        @input="closeDateMenu1"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="4">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="finish_date"
                          label="Fecha final"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="finish_date"
                        @input="closeDateMenu2"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="4">
                    <v-btn
                      :loading="loading3"
                      :disabled="loading3"
                      depressed
                      color="success"
                      block
                      @click="selectClient"
                    >
                      Ejecutar &nbsp;<v-icon>
                        mdi-tarrow-right-bold-circle-outline</v-icon
                      >
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="6">
                <v-row>
                  <v-col md="4"></v-col>
                  <v-col md="7">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <br />
            <v-data-table
              dense
              :sort-by="['fecha']"
              :headers="headers"
              :items-per-page="10"
              :items="data"
              :search="search"
              item-key="id"
              :sort-desc="[true]"
              no-data-text="No hay datos disponibles"
            >
              <template v-slot:[`item.pedido`]="{ item }">
                <a @click="detallePedido(item)" style="color: blue">
                  {{ item.pedido }}
                </a>
              </template>

              <template v-slot:[`item.fecha_pedido`]="{ item }">
                <!--<v-tooltip bottom v-if="item.fecha_pedido != '0000-00-00'" >
              <template v-slot:activator="{ on, attrs }">
                <v-icon  v-bind="attrs" v-on="on">mdi-factory
                </v-icon>
              </template>
              <span>{{item.fecha_pedido}}</span>
            </v-tooltip>-->
                <v-tooltip bottom v-if="item.fecha_picking != '0000-00-00'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"
                      >mdi-package-variant-closed-check
                    </v-icon>
                  </template>
                  <span>{{ item.fecha_picking }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.fecha_factura != '0000-00-00'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-receipt-text </v-icon>
                  </template>
                  <span>{{ item.fecha_factura }}</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  v-if="item.fecha_actual_inicio_trans != '0000-00-00'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-truck </v-icon>
                  </template>
                  <span
                    >{{ item.fecha_actual_inicio_trans }} -
                    {{ item.fecha_actual_fin_trans }}</span
                  >
                </v-tooltip>
                <v-tooltip
                  bottom
                  v-if="item.fecha_acuse_factura != '0000-00-00'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-account </v-icon>
                  </template>
                  <span>{{ item.fecha_acuse_factura }}</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.pdf_xml`]="{ item }">
                <a
                  v-if="item.uuid != ''"
                  @click="getPDFXMLInvoice(item.pdf_xml, 1)"
                >
                  <v-icon dense large color="red"> mdi-file-pdf-box </v-icon></a
                >
                <a
                  href="#"
                  v-if="item.uuid != ''"
                  @click="getPDFXMLInvoice(item.pdf_xml, 2)"
                >
                  <v-icon dense large color="blue">
                    mdi-file-xml-box
                  </v-icon></a
                >
                <a href="#" v-if="item.uuid != ''" @click="senDEmail(item)">
                  <v-icon dense large color="blue">
                    mdi-email-arrow-right-outline
                  </v-icon></a
                >
                <p href="#" v-if="item.uuid == '' && item.pdf_xml != ''">
                  <v-icon dense color="red"> mdi-alert </v-icon
                  ><span> {{ item.denrfbsk }}</span>
                </p>
              </template>
              <template v-slot:[`item.pay_complement`]="{ item }">
                <a
                  v-if="item.pay_complement != ''"
                  @click="getPDFXMLInvoice(item.pay_complement, 1)"
                >
                  <v-icon dense large color="red"> mdi-file-pdf-box </v-icon></a
                >
                <a
                  href="#"
                  v-if="item.pay_complement != ''"
                  @click="getPDFXMLInvoice(item.pay_complement, 2)"
                >
                  <v-icon dense large color="blue">
                    mdi-file-xml-box
                  </v-icon></a
                >
                <a
                  href="#"
                  v-if="item.pay_complement != ''"
                  @click="senDEmailComp(item)"
                >
                  <v-icon dense large color="blue">
                    mdi-email-arrow-right-outline
                  </v-icon></a
                >
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <br />
      <v-card
        cols="12"
        class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-15"
      >
        <v-card-title>HISTORIAL BACKORDER</v-card-title>
        <v-alert
          icon="mdi-close-octagon"
          prominent
          type="info"
          border="right"
          v-if="selected && selected.length > 0"
          style="text-align: center"
        >
          <v-row>
            <v-col class="grow">
              Orden de compra seleccionada:
              <span style="font-weight: 700">{{
                selected[0].orden_compra
              }}</span
              >.
              <p></p>
              BackOrder seleccionados:
              <span style="font-weight: 700">{{ selected.length }}</span>
              <p></p>
              <span
                style="font-weight: bolder; color: #000080; font-size: 20px"
                >{{ message_order }}</span
              >
            </v-col>
            <v-col style="text-align: center">
              <v-btn :disabled="buton_order" @click="createOrder()"
                >Ir a Pedido</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
        <v-row>
          <v-col md="6"></v-col>
          <v-col md="6">
            <v-text-field
              v-model="search1"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <br />
        <v-data-table
          dense
          v-model="selected"
          :headers="headers1"
          :items-per-page="10"
          :items="data1"
          :search="search1"
          item-key="id"
          no-data-text="No hay datos disponibles"
          show-select
        >
          <template v-slot:[`header.data-table-select`]="{ item }">{{
            item
          }}</template>
          <template
            v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :value="isSelected"
              :readonly="item.estatus_disponible != 'AMARILLO'"
              :disabled="item.estatus_disponible == 'AMARILLO'"
              @input="select($event)"
              @click="
                item.estatus_disponible == 'VERDE'
                  ? validate(item, isSelected)
                  : 0
              "
              hide-details
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.estatus_disponible`]="{ item }">
            <v-btn
              class="ma-1 elevation-0"
              v-bind:color="
                item.estatus_disponible === 'AMARILLO' ? 'warning' : 'success'
              "
              fab
              x-small
              outlined
            >
              <v-icon v-if="item.estatus_disponible === 'VERDE'"
                >mdi-check-bold</v-icon
              >
              <v-icon v-if="item.estatus_disponible === 'AMARILLO'"
                >mdi-progress-helper</v-icon
              >
            </v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn fab x-small color="error" @click="deleteItem(item)">
              <v-icon dense> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <!-- Dialog -->
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="indigo">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Pedido: {{ pedidoSelect }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <p></p>
            <template>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th class="text-left">CODIGO</th>
                    <th class="text-left">DESCRIPCION</th>
                    <th class="text-left">UNIDADES</th>
                    <th class="text-left">EMPAQUE</th>
                    <th class="text-left">VENTA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in pedidoInfo" :key="item.id">
                    <td class="text-left">{{ item.MATERIAL }}</td>
                    <td class="text-left">{{ item.SHORT_TEXT }}</td>
                    <td class="text-left">{{ item.REQ_QTY }}</td>
                    <td class="text-left">{{ item.S_UNIT_DLV }}</td>
                    <td class="text-left">$ {{ format(item.NETVA) }}</td>
                  </tr>
                  <tr>
                    <td colspan="3" style="text-align: right">
                      <b>Importe</b>
                    </td>
                    <td style="background-color: #3bafda">
                      <b>$ {{ format(pedidoImporte) }}</b>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </template>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- /. Dialog -->
      <!-- Dialog loading -->
      <v-row justify="center">
        <v-dialog v-model="loading" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Cargando
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- /. Dialog -->
      <v-dialog v-model="dialog_del" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Eliminar BackOrder
          </v-card-title>
          <v-card-text>
            <p></p>
            <h6 style="text-align: left; font-weight: 700">
              Orden de compra: {{ purchase_order }}
            </h6>
            <p></p>
            <v-row>
              <v-col md="12">
                <div class="form-group">
                  <label>Motivo de rechazo: </label>
                  <v-select
                    :items="select_errase_op"
                    v-model="comments"
                    label="Motivo de rechazo"
                    solo
                  ></v-select>
                </div>
                <p></p>
                <div class="form-group">
                  <label>2do. Motivo de rechazo:</label>
                  <v-textarea
                    solo
                    name="input-5-4"
                    label="......"
                    v-model="comments_0"
                  ></v-textarea>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" text @click="close()"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="deleteReminder()">
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- /. Dialog -->
      <v-dialog v-model="dialog_email" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Enviar por Correo
          </v-card-title>
          <v-card-text>
            <p></p>
            <h6 style="text-align: left; font-weight: 700">
              Factura: {{ invoice }}
            </h6>
            <p></p>
            <v-row>
              <v-col md="12">
                <div class="form-group">
                  <label>Ingresar Email:</label>
                  <v-text-field solo v-model="emai_user">{{
                    emai_user
                  }}</v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" text @click="close2()"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="sendEmailXMLPDF(invoice)">
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { eventBus } from "@/main.js";
export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  data: () => ({
    id_order: null,
    comments: "A1 = Canc. Rec. Por Falt. Inventario",
    comments_0: null,
    purchase_order: null,
    invoice: null,
    dialog_del: false,
    dialog_email: false,
    selected: [],
    orderShop: [],
    order_array: [],
    disabledCount: 0,
    data: [],
    data1: [],
    dialog: false,
    search: "",
    search1: "",
    message_order: "",
    buton_order: false,
    emai_user: null,
    start_date: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .substr(0, 10),
    finish_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    loading3: false,
    headers: [
      { text: "Fecha", align: "start", value: "fecha" },
      { text: "Consec", align: "start", value: "consec" },
      { text: "Orden de compra", value: "orden" },
      { text: "Pedido", sortable: false, value: "pedido" },
      { text: "Entrega", sortable: false, value: "entrega" },
      { text: "Factura", sortable: false, value: "factura" },
      { text: "Estatus", sortable: false, value: "estatus" },
      { text: "Tracking", sortable: false, value: "fecha_pedido" },
      { text: "PDF/XML", sortable: false, value: "pdf_xml" },
      { text: "Complemento de Pago", sortable: false, value: "pay_complement" },
    ],
    headers1: [
      { text: "Fecha", align: "start", value: "fecha" },
      { text: "ID BackOrder", align: "start", value: "id" },
      { text: "Orden Compra", align: "start", value: "orden_compra" },
      { text: "Código", value: "codigo" },
      { text: "Descripción", sortable: false, value: "nombre" },
      { text: "Medida", sortable: false, value: "medida" },
      { text: "Cantidad", sortable: false, value: "cantidad_pedida" },
      { text: "Estatus", sortable: false, value: "estatus_disponible" },
      { text: "", value: "actions", sortable: false },
    ],
    select_errase_op: [
      "A1 = Canc. Rec. Por Falt. Inventario",
      "A2 = Canc. Rec. Por Tiempo de Entrega",
      "A3 = Canc. Rec. Error del Cte.",
      "A4 = Canc. Rec. Por Error de Captura",
      "A5 = Pedido de Reemplazo",
      "A6 = Previa Cancelación x Lim. Cred.",
    ],
    loading: false,
    pedidoSelect: null,
    pedidoInfo: null,
    pedidoImporte: null,
    item: {},
    client: null,
    clientCurrent: false,
    cliente_zona_array: [],
  }),
  watch: {
    selected(value) {
      this.orderShop = [];
      for (let i = 0; i < value.length; i++) {
        this.orderShop.push({
          fecha: value[i].fecha,
          id: value[i].id,
          orden_compra: value[i].orden_compra,
          codigo: value[i].codigo,
          nombre: value[i].nombre,
          medida: value[i].medida,
          cantidad_pedida: value[i].cantidad_pedida,
        });
      }
    },
  },
  created() {
    eventBus.$on("change-client", (data) => {
      this.client = data;
      this.clientCurrent = true;
      this.selectClient();
    });
  },
  mounted() {
    if (localStorage.getItem("1us4-pv-cl13nt")) {
      this.client = JSON.parse(localStorage.getItem("1us4-pv-cl13nt"));
      this.clientCurrent = true;
      this.selectClient();
    }
  },
  methods: {
    selectClient() {
      this.clientCurrent = true;
      this.loading3 = true;
      let start_date = this.start_date;
      let finish_date = this.finish_date;
      this._infoClient(this.client);

      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/getPedidoHistory", {
          idCliente: this.client.KUNNR,
          VTWEG: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).VTWEG,
          date_start: start_date,
          date_end: finish_date
        })
        .then((response) => {
          this.data = response.data;
          this.loading3 = false;
        })
        .catch((error) => {
          console.warn(error);
          this.loading3 = false;
        })
        .finally(() => (this.loading = false));
      this.getRecordatoriosHistory();
    },
    _infoClient(client) {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/infoCustomer", {
          customer: client.KUNNR,
          VKORG: "IUS2",
          VTWEG: client.VTWEG,
          VKBUR: client.VKBUR,
        })
        .then((response) => {
          this.emai_user = response.data.SMTP_ADDR;
        })
        .catch((error) => {
          console.warn(error);
        })
        .finally(() => (this.loading = false));
    },
    getRecordatoriosHistory() {
      this.loading = true;
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/getRecordatoriosHistory", {
          idCliente: this.client.KUNNR,
          customer: this.client.KUNNR,
          VTWEG: this.client.VTWEG,
        })
        .then((response) => {
          this.data1 = response.data;
        })
        .catch((error) => {
          console.warn(error);
        })
        .finally(() => (this.loading = false));
    },
    detallePedido(item) {
      this.pedidoSelect = item.pedido;
      this.dialog = true;
      this.loading = true;
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/getInfoPedido", {
          id_pedido: item.pedido,
          cliente: this.client.KUNNR,
        })
        .then((response) => {
          this.loading = false;
          this.pedidoInfo = response.data.prueba_Pedido;
          this.pedidoImporte = response.data.importe;
          this.pedidoInfo.pop();
        })
        .catch((error) => {
          this.loading = false;
          console.warn(error);
        })
        .finally(() => (this.loading = false));
    },
    closeDateMenu1() {
      this.menu1 = false;
      this.start_date = new Date(
        this.start_date - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      console.log(this.start_date);
    },
    closeDateMenu2() {
      this.menu2 = false;
      this.finish_date = new Date(
        this.finish_date - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
    },
    deleteItem(item) {
      console.log(item);
      this.purchase_order = item.orden_compra;
      this.id_order = item.id;
      this.dialog_del = true;
    },
    close() {
      this.dialog_del = false;
    },
    senDEmail(item) {
      console.log(item);
      this.invoice = item.factura;
      //this.id_order = item.id;
      this.dialog_email = true;
    },
    senDEmailComp(item) {
      console.log(item);
      this.invoice = item.pay_complement;
      //this.id_order = item.id;
      this.dialog_email = true;
    },
    close2() {
      this.dialog_email = false;
    },
    deleteReminder() {
      this.dialog_del = false;
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/deleteReminder", {
          customer: this.client.KUNNR,
          id_order: this.id_order,
          comments: this.comments,
          comments_0: this.comments_0,
        })
        .then((response) => {
          this.loading = false;
          //this.getRecordatoriosHistory();
          console.log(response.data);

          this.$swal({
            title: response.data.FNMSG,
            icon: "success",
            type: "succes",
          }).then(function () {
            //this.getRecordatoriosHistory();
            location.reload();
          });
        })
        .catch((error) => {
          this.loading = false;
          console.warn(error);
        })
        .finally(() => (this.loading = false));
    },
    validate(item, isSelected) {
      //console.log(isSelected);

      if (isSelected != true) {
        this.order_array.push({
          orden_compra: item.orden_compra,
        });
      } else {
        this.order_array.splice(item.orden_compra, 1);
      }

      const result = this.order_array.map((w) => w.orden_compra);
      console.log(result);
      result.every((val, i, arr) => val === arr[0]); // true
      console.log(result.every((val, i, arr) => val === arr[0]));

      if (result.every((val, i, arr) => val === arr[0]) == true) {
        console.log("Si son iguales puedes pasar");
        this.message_order = "";
        this.buton_order = false;
      } else {
        this.buton_order = true;
        this.message_order =
          "Para crear un pedido es necesario que la orden de compra seleccionada sea la misma.";
        console.log("No son iguales NO puedes pasar");
      }
      //console.log(item.orden_compra);
      //SI LO DE ADENTRO SON INGUALES PASA
      //SI NO SON IGUALES NO PASA
    },
    createOrder() {
      this.$router.push({
        name: "ShoppingCartM",
        query: {
          data: this.orderShop,
        },
      });
    },
    format(value) {
      const result = Number(value).toFixed(2);
      console.log(result);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getPDFXMLInvoice(invoice, type) {
      this.dialog_email = false;
      console.log(invoice);
      console.log(this.emai_user);
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/InvoicePDFXML", {
          invoice: invoice,
          type: type,
        })
        .then((response) => {
          this.url = response.data;
          window.open(this.url, "_blank");
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    sendEmailXMLPDF(invoice) {
      console.log(invoice);
      this.dialog_email = false;
      console.log(invoice);
      console.log(this.emai_user);
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/InvoicePDFXMLEmail", {
          invoice: invoice,
          email: this.emai_user,
        })
        .then((response) => {
          this.message = response.data;

          this.$swal({
            title: response.data.message,
            icon: "success",
            type: "succes",
          }).then(function () {
            //this.getRecordatoriosHistory();
          });
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
  beforeDestroy() {
    eventBus.$off("change-client", null);
  },
};
// quitar entrega
// Motivo
// Estatus CAPTURADO, FACTURADO , EMBARCADO, RECIBIDO CLIENTE
// QUITAR MOTIVO  PONER CAPTURADO (FACTURADO).
</script>
