<template>
  <!-- .auth -->
  <main class="auth">
    <header
      id="auth-header"
      class="auth-header"
      style="background: #ff000a; color: #ffffff"
    >
      <h1>
        <div class="row" style="text-align: center">
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <h1>PORTAL DEL VENDEDOR</h1>
          </div>
        </div>
        <span class="sr-only">Sign In</span>
      </h1>
      <!-- <p>Don't have a account? <a href="auth-signup.html">Create One</a></p> -->
    </header>
    <!-- form -->
    <form class="auth-form" @submit.prevent="submit">
      <v-alert text dense outlined v-if="hasError" type="error">
        {{ msg }}
      </v-alert>
      <!-- .form-group -->
      <div class="form-group">
        <div class="form-label-group">
          <input
            type="text"
            id="email"
            v-model="form.employee_number"
            class="form-control"
            placeholder="Employee Number..."
            ref="em"
            required=""
          />
          <label for="email">Employee Number...</label>
        </div>
      </div>
      <!-- /.form-group -->
      <!-- .form-group -->
      <div class="form-group">
        <div class="form-label-group">
          <input
            type="password"
            id="password"
            v-model="form.password"
            class="form-control"
            placeholder="Password"
            required=""
          />
          <label for="password">Password</label>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-12">
          <vue-recaptcha
            :sitekey="catpcha"
            @verify="onVerify"
            @expired="onExpired">
          </vue-recaptcha>
        </div>
      </div>
      <p></p>
      <div class="form-group">
        <button
          type="submit"
          class="btn btn-primary btn-user btn-block"
          style="color: #ffffff; background: #ff000a">
          {{ procesando ? "Validando..." : "Ingresar" }}
        </button>
      </div>
    </form>
    <footer class="text-center auth-footer">
      <!-- Propiedad de Grupo IUSA, S.A. de C.V. -->
      <p>V 1.4.5</p>
      <!-- 01022023 -->
    </footer>
  </main>
  <!-- /.auth -->
</template>

<script>
import { mapActions } from "vuex";
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "SignIn",
  components: {
    VueRecaptcha,
  },
  mounted() {
    this.$refs.em.focus();
  },
  data() {
    return {
      catpcha: process.env.VUE_APP_GOOGLE_RECATPCHA,
      procesando: false,
      hasError: false,
      recaptcha: null,
      msg: null,
      form: {
        employee_number: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      signOut: "auth/signOut",
    }),
    onVerify: function (response) {
      this.recaptcha = response;
    },
    onExpired: function () {
      this.recaptcha = null;
    },
    submit() {
      if (this.recaptcha != null) {
        this.procesando = true;
        this.signIn(this.form)
          .then(() => {
            if (localStorage.getItem("estatus") == "1") {
              if (localStorage.getItem("type") == 2) {
                this.$router.replace({
                  name: "dashboardAdmin",
                });
              } else if (localStorage.getItem("type") == 3) {
                this.$router.replace({
                  name: "dashboardCoordinador",
                });
              } else if (localStorage.getItem("type") == 4) {
                this.$router.replace({
                  name: "reporte",
                });
              } else if (localStorage.getItem("type") == 5) {
                this.$router.replace({
                  name: "reporte",
                });
              } else {
                this.$router.replace({
                  name: "dashboard",
                });
              }
            } else {
              this.signOut();
              this.hasError = true;
              this.msg = "Cuenta inhabilitada temporalmente";
            }
          })
          .catch(() => {
            this.msg = "Usuario y/o contraseña incorrectos";
            this.procesando = false;
            this.hasError = true;
            this.form.password = null;
          });
      } else {
        this.hasError = true;
        this.msg = "El campo reCAPTCHA es obligatorio";
      }
    },
  },
};
</script>
