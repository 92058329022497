<template>
  <div id="contenedor">
    <v-container fluid class="white lighten-5">
      <v-alert
        icon="mdi-close-octagon"
        prominent
        type="error"
        border="right"
        v-if="infoCurrent && infoCurrent.AUFSD === '1'"
      >
        <v-row align="center">
          <v-col class="grow">
            <span style="font-weight: 700; font-size: 18px"
              >Estimado cliente usted tiene un bloqueo de partidas vencidas,
              favor de realizar su pago en línea
              <router-link :to="{ name: 'AccountStatus' }" style="color: #fff">
                aquí.
              </router-link></span
            >
          </v-col>
        </v-row>
      </v-alert>

      <v-row dense>
        <v-col md="12" v-if="infoCurrent">
          <v-card class="mx-auto" outlined :style="'border: 0px'">
            <v-list-item class="ma-0">
              <v-col md="4">
                <strong>Bienvenido, </strong>
                <span>{{ infoCurrent.NAME1 }}</span
                ><br />
                <span style="color: #979797"> A {{ currentDate }} </span>
                <br />
                <br />
                <v-btn
                  type="submit"
                  rounded
                  style="
                    background-color: #28a745;
                    color: #fff;
                    text-transform: none;
                    font-weight: 500;
                  "
                  @click="getCoverPDF"
                >
                  Descargar Caratula
                </v-btn>
              </v-col>
              <v-col md="8" class="text-justify boder-side-small">
                <br />
                <p>
                  <v-icon style="color: red" size="45" :dense="true"
                    >mdi-shield-lock-outline</v-icon
                  >
                  <span class="text-header-anounce">Seguro y</span>
                  <v-icon style="color: red" size="45" :dense="true"
                    >mdi-cash-multiple</v-icon
                  >
                  <span class="text-header-anounce">fácil de pagar.</span>
                  <span class="text-header-red"
                    >¡Vive la experiencia IUSA!</span
                  >
                </p>
              </v-col>
            </v-list-item>
            <v-col class="mt-2">
              <v-row>
                <v-col cols="12" md="2" class="mt-4">
                  <v-btn icon style="color: #000 !important">
                    <v-icon>mdi-filter-outline</v-icon>
                  </v-btn>
                  <strong>Filtro X Grupo:</strong>
                </v-col>
                <v-col md="2">
                  <v-checkbox
                    id="Desempeño"
                    v-model="menu"
                    label="Desempeño"
                    class="blue-label"
                    color="#0000FF"
                    value="blue"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col md="2">
                  <v-checkbox
                    id="Servicio"
                    v-model="menu"
                    label="Nivel de Servicio"
                    class="red-label"
                    color="red"
                    value="red"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col md="2">
                  <v-checkbox
                    id="Bonos"
                    v-model="menu"
                    label="Bonos"
                    class="gold-label"
                    color="#B7970C"
                    value="yellow"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
      <loading-bar :capturedDialogLoading="dialogLoading"></loading-bar>
      <div id="cards">
        <v-row>
          <v-col cols="12" md="3">
            <CustomerInformation :clientCurrent="client"></CustomerInformation>
            <ContactDesk></ContactDesk>
            <v-card class="mx-auto rounded-lg elevation-5 ma-5" outlined>
              <v-list-item-content class="text-justify ml-5">
                <div class="mb-n3">
                  <strong>SÍGUENOS</strong>
                </div>
              </v-list-item-content>
              <v-list-item three-line>
                <v-list-item-content>
                  <a
                    ><v-icon style="color: red" size="35"
                      >mdi-facebook
                    </v-icon></a
                  >
                </v-list-item-content>
                <v-list-item-content>
                  <a
                    ><v-icon style="color: red" size="35"
                      >mdi-youtube</v-icon
                    ></a
                  >
                </v-list-item-content>
                <v-list-item-content>
                  <a
                    ><v-icon style="color: red" size="35"
                      >mdi-instagram</v-icon
                    ></a
                  >
                </v-list-item-content>
                <v-list-item-content>
                  <a
                    ><v-icon style="color: red" size="35"
                      >mdi-twitter</v-icon
                    ></a
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col v-if="infoCurrent" cols="12" md="9">
            <v-row>
              <v-col cols="12">
                <div class="Cartera" v-if="menu.includes('blue')">
                  <v-card
                    class="mx-auto rounded-lg elevation-4 mb-4"
                    outlined
                    :style="'border: 4px solid #0000FF;'"
                  >
                    <v-list-item-content>
                      <div
                        class="text-primary text-desp"
                        style="text-align: center"
                      >
                        <v-icon style="color: #0000ff !important"
                          >mdi-wallet-plus-outline</v-icon
                        >
                        CARTERA Y LINEA DE CREDITO:
                      </div>
                    </v-list-item-content>
                  </v-card>
                  <v-card class="mx-auto rounded-xl elevation-5 ma-5" outlined>
                    <v-list-item three-line>
                      <v-list-item-title class="text-h5 mb-2">
                        Límite de crédito
                        <span
                          ><strong>${{ infoCurrent.KLIMK }}</strong></span
                        >
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-progress-linear
                        class="rounded-lg"
                        :value="range"
                        color="blue"
                        height="25"
                      >
                        <strong>{{ range }}%</strong>
                      </v-progress-linear>
                    </v-list-item>
                    <v-row>
                      <v-col md="6">
                        <div class="text-center ma-3">
                          <p>
                            S. corte <strong>${{ infoCurrent.SKFOR }}</strong>
                          </p>
                        </div>
                      </v-col>
                      <v-col md="6">
                        <div class="text-center ma-3">
                          <p>
                            S. disponible
                            <strong>${{ infoCurrent.CREDD }}</strong>
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-list-item three-line>
                      <v-col md="8">
                        <div class="text-justify ma-1">
                          Plazo: <span> 30 Días</span>
                          <br />
                          Monto Vencido: <span> $0.00</span>
                          <br />
                          Partidas Vencidas: <span> 0</span>
                          <br />
                          Notas de crédito pendientes de aplicar:
                          <span> $0</span>
                          <br />
                          Pagos pendientes de aplicar: <span>$0</span>
                          <br />
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="text-center mb-4">
                          <p class="text-big-num">$0.00</p>
                          <p>Monto Total</p>
                        </div>
                      </v-col>
                    </v-list-item>
                    <router-link
                      :to="{ name: 'AccountStatus' }"
                      class="nav-link active"
                    >
                      <v-list-item class="text-center mb-4">
                        <v-btn
                          type="submit"
                          rounded
                          block
                          style="
                            background-color: #28a745;
                            color: #fff;
                            text-transform: none;
                            font-weight: 500;
                          "
                        >
                          Estado de Cuenta
                        </v-btn>
                      </v-list-item>
                    </router-link>
                  </v-card>
                </div>
                <div class="IndicatorPayment" v-if="menu.includes('yellow')">
                  <IndicatorsPayment :clientCurrent="client" />
                </div>
                <div class="QuarterlyBonus" v-if="menu.includes('yellow')">
                  <QuarterlyBonus :clientCurrent="client" />
                </div>
                <div
                  class="TotalAdditionalBenefits"
                  v-if="menu.includes('yellow')"
                >
                  <TotalAdditionalBenefits :clientCurrent="client" />
                </div>
              </v-col>
              <v-if v-if="menu.includes('blue') && menu.length == 1">
                <v-col cols="12">
                  <div class="HistoryShopping">
                    <HistoryShopping :clientCurrent="client" />
                  </div>
                </v-col>
              </v-if>
              <v-if v-if="menu.includes('red') && menu.length == 1">
                <v-col cols="12">
                  <div class="FillRate">
                    <FillRate :clientCurrent="client" />
                  </div>
                </v-col>
              </v-if>
            </v-row>
          </v-col>

          <v-col v-if="infoCurrent" cols="12">
            <div
              class="HistoryShopping"
              v-if="menu.includes('blue') && menu.length > 1"
            >
              <HistoryShopping :clientCurrent="client" />
            </div>
            <div
              class="FillRate"
              v-if="menu.includes('red') && menu.length > 1"
            >
              <FillRate :clientCurrent="client" />
            </div>
          </v-col>
        </v-row>
      </div>
      <v-row class="ma-2">
        <v-list-item>
          <p class="text-header-anounce">
            Nuestras Marcas<span class="text-header-anounce-subtitle"
              >/ Calidad, innovación y experiencia nos respaldan</span
            >
          </p>
        </v-list-item>
        <v-col md="12">
          <v-card class="mx-auto" outlined :style="'border: 0px;'">
            <v-slide-group class="pa-4" active-class="success" show-arrows>
              <v-slide-item v-for="(item, i) in brand" :key="i">
                <v-card class="ma+5" style="height: auto">
                  <v-img
                    class="white--text align-end"
                    :src="item.src"
                    style="
                      display: block;
                      height: auto;
                      margin: auto;
                      width: 160px;
                    "
                  >
                  </v-img>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import ContactDesk from "@/views/mi-cartera/components/ContactDesk.vue";
import FillRate from "@/views/mi-cartera/components/FillRate.vue";
import HistoryShopping from "@/views/mi-cartera/components/HistoryShopping.vue";
import IndicatorsPayment from "@/views/mi-cartera/components/IndicatorsPayment.vue";
import QuarterlyBonus from "@/views/mi-cartera/components/QuarterlyBonus.vue";
import CustomerInformation from "@/views/mi-cartera/components/auth/CustomerInformation.vue";
import LoadingBar from "@/views/mi-cartera/components/LoadingBar.vue";

import TotalAdditionalBenefits from "@/views/total_benefits/TotalAdditionalBenefits.vue";

import { eventBus } from "@/main.js";

export default {
  components: {
    ContactDesk,
    FillRate,
    HistoryShopping,
    IndicatorsPayment,
    QuarterlyBonus,
    CustomerInformation,
    TotalAdditionalBenefits,
    LoadingBar,
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  data() {
    return {
      menu: ["red", "blue", "yellow"],
      infoCurrent: null,
      bonus: null,
      client: {},
      range: 0,
      currentDate: "",
      selected: null,
      dialogLoading: false,
      brand: [
        {
          src: "https://www.iusa.com.mx/assets/logos/marcas/iusa.jpg",
        },
        {
          src: "https://www.iusa.com.mx/assets/logos/marcas/iusacobre.jpg",
        },
        {
          src: "https://www.iusa.com.mx/assets/logos/marcas/iusaconduit.jpg",
        },
        {
          src: "https://www.iusa.com.mx/assets/logos/marcas/iusaflow.jpg",
        },
        {
          src: "https://www.iusa.com.mx/assets/logos/marcas/iusagas.jpg",
        },
        {
          src: "https://www.iusa.com.mx/assets/logos/marcas/iusaplus.jpg",
        },
        {
          src: "https://www.iusa.com.mx/assets/logos/marcas/iusapvc.jpg",
        },
        {
          src: "https://www.iusa.com.mx/assets/logos/marcas/iusatek.jpg",
        },
        {
          src: "https://www.iusa.com.mx/assets/logos/marcas/iusasol.jpg",
        },
        {
          src: "https://www.iusa.com.mx/assets/logos/marcas/enerhit.jpg",
        },
        {
          src: "https://www.iusa.com.mx/assets/logos/marcas/kronius.jpg",
        },
        {
          src: "https://www.iusa.com.mx/assets/logos/marcas/precimex.jpg",
        },
      ],
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    };
  },
  created() {
    var self = this;
    eventBus.$on("change-client", () => {
      if (localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7")) {
        console.info("eventBus()");
        self.client = localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7");
        self.getCustomerInformation();
      }
    });
  },
  async mounted() {
    this._date();
    if (localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-cl13n7")) {
      this.client = JSON.parse(localStorage.getItem("1us4-pv-cl13nt"));
      this.getCustomerInformation();
    }
  },
  methods: {
    getCustomerInformation() {
      var self = this;
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/infoCustomer", {
          customer: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
          VKORG: "IUS2",
          VTWEG: "DT",
          VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
        })
        .then((response) => {
          self.infoCurrent = response.data;
          self.saldo = self.infoCurrent.SKFOR?.replace(/,/g, "");
          self.limite = self.infoCurrent.KLIMK?.replace(/,/g, "");
          self.range = (parseInt(self.saldo) * 100) / parseInt(self.limite);
          self.range = parseInt(self.range);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCoverPDF() {
      this.dialogLoading = true;
      axios
        .post(
          process.env.VUE_APP_API_IEL_SERVER + "/get-cover-info",
          {
            order_id: 96,
            customer: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
            VKORG: "IUS2",
            VTWEG: "DT",
            VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
          },
          { responseType: "blob" }
        )
        .then((response) => {
          //this.data = response.data;
          console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "caratula.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
          this.dialogLoading = false;
        })
        .finally(
          () => ((this.dialogLoading = false), (this.submitted = false))
        );
    },
    _date() {
      var current_date = new Date();
      this.currentDate =
        current_date.getDate() +
        " " +
        this.months[current_date.getMonth()] +
        " " +
        current_date.getFullYear() +
        " " +
        current_date.toLocaleTimeString() +
        " hrs.";
    },
  },
  destroyed() {
    eventBus.$off("change-client");
  },
};
</script> 
<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.d-image {
  width: 100%;
}
</style>
