<template>
  <v-row justify="center">
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "LoadingBar",
  props: {
    capturedDialogLoading: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    dialogLoading: false,
  }),
  methods: {},
  watch: {
    capturedDialogLoading: function (val) {
      this.dialogLoading = val;
    },
  },
};
</script>
