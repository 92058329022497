<template>
  <div>
    <v-card
      class="mx-auto rounded-lg elevation-4 mb-4 ms-sm-4"
      outlined
      :style="'border: 4px solid #B7970C; background-color:transparent;'"
    >
      <v-list-item-content>
        <div class="text-gold" color="#B7970C">
          <v-icon style="color: #b7970c !important">mdi-chart-timeline</v-icon>
          BONO TRIMESTRAL LOGRADO
        </div>
      </v-list-item-content>
    </v-card>
    <v-card class="mx-auto rounded-xl elevation-5 ma-5 ma-sm-3" outlined>
      <div class="ma-4 mt-10">
        <v-data-table
          :headers="headers_bono_ind"
          :items="bonus"
          hide-default-footer
          class="elevation-0"
          style="border-bottom: none"
        >
          <template v-slot:[`item.PERIODO1`]="{ item }">
            $ &nbsp;&nbsp;{{ format(item.PERIODO1) }}
          </template>
          <template v-slot:[`item.PERIODO2`]="{ item }">
            <a href="/account-status" v-if="item.PERIODO2 > 0"
              >$ &nbsp;&nbsp;{{ format(item.PERIODO2) }}</a
            >
            <span v-else>$ &nbsp;&nbsp;{{ format(item.PERIODO2) }}</span>
          </template>
          <template v-slot:[`item.PERIODO3`]="{ item }">
            $ &nbsp;&nbsp;{{ format(item.PERIODO3) }}
          </template>
          <template v-slot:[`item.PERIODO4`]="{ item }">
            $ &nbsp;&nbsp;{{ format(item.PERIODO4) }}
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    clientCurrent: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      bonus: [],
      headers_bono_ind: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  async mounted() {
    let self = this;
    if (localStorage.getItem("1us4-pv-cl13nt")) {
      self.getBonus();
    }
  },
  methods: {
    getBonus() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/indicatorsBonos", {
          client_code: JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR,
        })
        .then((response) => {
          var headerBonus = response.data.header;
          this.bonus = response.data.data;
          this.headers_bono_ind = [
            {
              text: "Concepto",
              align: "start",
              value: "TITULO",
              class: "border-bottom: none;",
              sortable: false,
            },
            {
              text: headerBonus[0].PERIODO1,
              sortable: false,
              value: "PERIODO1",
            },
            {
              text: headerBonus[0].PERIODO2,
              sortable: false,
              value: "PERIODO2",
            },
            {
              text: headerBonus[0].PERIODO3,
              sortable: false,
              value: "PERIODO3",
            },
            {
              text: headerBonus[0].PERIODO4,
              sortable: false,
              value: "PERIODO4",
            },
          ];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    format(value) {
      const result = Number(value).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  watch: {
    clientCurrent: function () {
      let self = this;
      self.getBonus();
    },
  },
};
</script>

<style></style>
