<template>    
    <v-window v-model="onboarding" show-arrows reverse>
        <v-window-item v-for="(groups, index) in groupedWeeks" :key="`card-${index}`">
            <div class="m-5">
                <div class="row slider__fechas">
                    <div class="col-3 slider__bloq px-5" v-for="(group, index2) in groups" :key="index2">                        
                        <div @click="sendWeek(group)" :class="{ active: activeWeek === group.week }" class="week-bloq">
                            {{group['interval']}}
                            <p class="mb-0">Semana {{group.week}}</p>
                        </div>
                    </div>                    
                </div>
            </div>
        </v-window-item>
    </v-window> 
</template>

<script>
import moment from "moment"
export default {
    data(){
        return{
            onboarding: 1, 
            groupedWeeks: [],
            activeWeek: null,
            currentDate: moment().format('YYYY-MM-DD')
        }
    },
    mounted(){
        this.calculateWeeks()
    },
    methods:{
        calculateWeeks() {
            this.groupedWeeks = [];
            const start = moment().startOf('isoWeek').startOf('day').month(0).date(1);
            const end = moment(start).add(2, 'year').add(1, 'day'); // Agregar 1 día a la fecha final
            const days = end.diff(start, 'days');
            const weeks = [];
            let weekNumber = 1;
            let year = start.year();
            for (let i = 0; i < days; i += 7) {
                const weekStart = moment(start).add(i, 'days');
                const weekEnd = moment.min(moment(weekStart).add(6, 'days'), end);
                const weekInterval = `${weekStart.format('DD MMM')} - ${weekEnd.format('DD MMM')}`;
                if (weekStart.year() > year) {
                    year = weekStart.year();
                    weekNumber = 1;
                } else if (weekStart.month() === 0 && weekStart.date() <= 7) {
                    // Ajustar número de semana para la primera semana del año
                    weekNumber = 1;
                }
                const week = {
                    week: weekNumber,
                    interval: weekInterval,
                    weekStart: moment(weekStart).format('YYYY-MM-DD'),
                    weekEnd: moment(weekEnd).format('YYYY-MM-DD'),
                    year: year
                };
                weeks.push(week);
                weekNumber++;
            }
            const dateRange = `${start.format('D/M/YYYY')} - ${moment(end).subtract(1, 'day').format('D/M/YYYY')}`;
            this.$emit('dateRange', dateRange);
            for (let i = 0; i < weeks.length; i += 4) {
                this.groupedWeeks.push(weeks.slice(i, i + 4));
            }

            // Verifica en qué grupo se encuentra la fecha actual
            for (let i = 0; i < this.groupedWeeks.length; i++) {
                const group = this.groupedWeeks[i];
                for (const week of group) {
                    if (this.currentDate >= week.weekStart  && this.currentDate <=  week.weekEnd) {
                        this.onboarding = i;
                        this.sendWeek(week)                        
                    }
                }
            }

        },
        sendWeek(group){
            this.activeWeek = group.week
            this.$emit('sendWeek', {inicio: group.weekStart, fin: group.weekEnd, intervalo: group.interval, semana: group.week})
        }
    }
}
</script>

<style scoped>
    .active {
        color: red;        
    }
    .week-bloq{
        cursor: pointer;
        text-align: center;
    }
</style>