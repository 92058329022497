<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col>
          <v-card
            cols="12"
            class="pa-xs-2 pa-md-2 pa-lg-2 mt-xs-0 mt-md-0 mt-lg-0 mb-7"
          >
            <v-card-title>MATERIALES DESCONTINUADOS</v-card-title>
            <v-row>
              <v-col md="6">
                <v-row>
                  <v-col md="4">
                    <v-btn
                      :loading="loading3"
                      :disabled="loading3"
                      depressed
                      color="success"
                      block
                      @click="excelDownload()"
                    >
                      Descargar Excel
                    </v-btn>
                  </v-col>
                  <v-col md="4">
                    <v-btn
                      :loading="loading4"
                      :disabled="loading4"
                      depressed
                      color="primary"
                      block
                      @click="openDialogEmail()"
                    >
                      Enviar Correo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="6">
                <v-row>
                  <v-col md="4"></v-col>
                  <v-col md="7">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <br />
            <v-data-table
              dense
              :headers="headers"
              :items-per-page="10"
              :items="data"
              :search="search"
              :sort-desc="[true]"
              item-key="mateiral_code"
              no-data-text="No hay datos disponibles"
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  class="ma-1 elevation-0"
                  color="success"
                  fab
                  x-small
                  outlined
                  @click="openDialog(item)"
                >
                  <v-icon> mdi-cart-plus </v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.units`]="{ item }">
                <v-text-field v-model="item.units"></v-text-field>
              </template>
            </v-data-table>
            <br />
            <v-row class="mb-5">
              <v-col md="6"> </v-col>
              <v-col md="6" class="text-right"> </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!-- Dialog loading -->
      <v-row justify="center">
        <v-dialog v-model="loading" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Cargando
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- /. Dialog -->
      <v-dialog v-model="dialog" width="700" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Agregar Material al Carrito
          </v-card-title>
          <v-card-text>
            <p></p>
            <div class="col-md-12" v-if="hasError">
              <v-alert text dense outlined type="error">
                {{ warnerror }}
              </v-alert>
            </div>
            <p></p>
            <v-row>
              <v-col md="4">
                <label>Material:</label>
                <v-text-field solo readonly v-model="m_code">{{
                  m_code
                }}</v-text-field>
              </v-col>
              <v-col md="4">
                <div class="form-group">
                  <label>Empaque:</label>
                  <v-text-field solo readonly v-model="pack">{{
                    pack
                  }}</v-text-field>
                </div>
              </v-col>
              <v-col md="4">
                <div class="form-group">
                  <label>Cantidad Confirmada:</label>
                  <v-text-field solo readonly v-model="confirm_quantity">{{
                    confirm_quantity
                  }}</v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" text @click="closeDialog()"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="hasError"
              color="success"
              text
              @click="sendtoCart()"
            >
              Enviar a Carrito
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- /. Dialog -->
      <v-dialog v-model="dialog_email" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Enviar por Correo
          </v-card-title>
          <v-card-text>
            <p></p>
            <h6 style="text-align: left; font-weight: 700">
              Materiales Descontinuados
            </h6>
            <p></p>
            <v-row>
              <v-col md="12">
                <div class="form-group">
                  <label>Ingresar Email:</label>
                  <v-text-field solo v-model="email_user">{{
                    email_user
                  }}</v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" text @click="close2()"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="sendEmail()"> Enviar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { eventBus } from "@/main.js";
export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  data: () => ({
    id_order: null,
    data: [],
    loading: false,
    search: "",
    dialog: false,
    m_code: null,
    pack: null,
    quantity: null,
    confirm_quantity: null,
    units: null,
    warnerror: null,
    hasError: false,
    loading3: false,
    loading4: false,
    dialog_email: false,
    email_user: null,
    client: null,
    headers: [
      { text: "Código", align: "start", value: "mateiral_code" },
      { text: "Descripción", align: "start", value: "description" },
      { text: "Centro", value: "center" },
      { text: "Existencia", sortable: false, value: "stock" },
      { text: "Importe", sortable: false, value: "amount" },
      { text: "Cantidad", sortable: false, value: "units" },
      {
        text: "Agregar a Carrito",
        align: "center",
        sortable: false,
        value: "action",
      },
    ],
  }),
  watch: {
    selected(value) {
      this.orderShop = [];
      for (let i = 0; i < value.length; i++) {
        this.orderShop.push({
          fecha: value[i].fecha,
          id: value[i].id,
          orden_compra: value[i].orden_compra,
          codigo: value[i].codigo,
          nombre: value[i].nombre,
          medida: value[i].medida,
          cantidad_pedida: value[i].cantidad_pedida,
        });
      }
    },
  },
  created() {
    eventBus.$on("change-client", (data) => {
      console.log(
        "change-client => ",
        data,
        " client => ",
        this.client,
        " storage => ",
        JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR
      );
      this.p = 1;
      this._validateClient();
    });
  },
  mounted() {
    this._validateClient();
    let self = this;
    self.getDiscontinuedMat();
    this.email_user = this.user.email;
    this._infoClient(this.client);
    //console.log(self.orderShop);
  },
  methods: {
    _validateClient() {
      if (localStorage.getItem("1us4-pv-cl13nt")) {
        this.client = JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR;
        localStorage.setItem("1us4-p0rt4l-v3nd3nd0r-cl13n7", this.client);
        this.clientCurrent = true;
      }
    },
    _infoClient(client) {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/infoCustomer", {
          customer: client,
          VKORG: "IUS2",
          VTWEG: "DT",
          VKBUR: "IU44",
        })
        .then((response) => {
          this.email_user = response.data.SMTP_ADDR;
        })
        .catch((error) => {
          console.warn(error);
        })
        .finally(() => (this.loading = false));
    },
    getDiscontinuedMat() {
      this.loading = true;
      this.loading3 = true;
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/get-discontinued-mat", {
          VTWEG: "DT",
        })
        .then((response) => {
          this.loading = true;
          this.data = response.data;
          this.loading = false;
          this.loading3 = false;
        })
        .catch((error) => {
          //alert(error);
          console.log(error);
          this.loading = false;
          this.loading3 = false;
        })
        .finally();
    },
    openDialog(item) {
      console.log(item);
      this.invoice = item.pay_complement;
      this.units = item.units;
      //this.id_order = itto em.id;
      this.materialInfoDT(this.units, item);
      this.dialog = true;
    },
    materialInfoDT(uni, item) {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/getMaterialInfo", {
          code: item.mateiral_code,
          units: uni,
          customer: this.client,
          VKORG: "IUS2",
          VTWEG: "DT",
          VKBUR: "IU44",
        })
        .then((response) => {
          this.material_info = response.data;
          this.m_code = this.material_info.codigo_material;
          this.pack = this.material_info.empaque;
          this.quantity = this.material_info.u_pedidas;
          this.confirm_quantity = this.material_info.u_confirm;
        })
        .catch((error) => {
          console.log("Error:" + error.response.data[0]);
          this.hasError = true;
          this.warnerror = error.response.data.error_code;
        })
        .finally();
    },
    closeDialog() {
      this.hasError = false;
      (this.m_code = null),
        (this.pack = null),
        (this.quantity = null),
        (this.confirm_quantity = null),
        (this.dialog = false);
    },
    sendtoCart() {
      let type = "VE";
      //let type = "VE"; // ENCASO DE PORTAL VENDEDOR ENVIAR ESTA VARIABLE
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/getOrderID", {
          customer: this.client,
          user_id: this.user.id,
          type: type,
        })
        .then((response) => {
          this.order_id = response.data;

          console.log("getOrderID");
          console.log(this.order_id);
          this.getCartMaterials(this.order_id, this.m_code);
        })
        .catch((error) => {
          ///alert(error);
          console.log(error);
        })
        .finally();
    },

    getCartMaterials(order_id, m_code) {
      //////////////
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/get-cart-materials", {
          order_id: order_id,
          material_code: m_code,
        })
        .then((response) => {
          console.log("get-cart-materials");
          console.log(response.data);
          this.saveNewMaterialOrder(order_id, this.material_info);
        })
        .catch((error) => {
          ///alert(error);
          if (error.response.status == 404) {
            //alert(error.response.data.error_code);
            this.$swal({
              title: error.response.data.error_code,
              icon: "error",
              type: "error",
            }).then((result) => {
              console.log(result);
              // location.reload();
              this.hasError = false;
              (this.m_code = null),
                (this.pack = null),
                (this.quantity = null),
                (this.confirm_quantity = null),
                (this.dialog = false);
            });
          } else {
            //alert(error);
            console.log(error);
          }
        })
        .finally();
      /////////////
    },
    saveNewMaterialOrder(order_id, material_info) {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/saveNewMaterialOrder", {
          order_id: order_id,
          item_cart: material_info,
        })
        .then((response) => {
          console.log(response);

          this.$swal({
            title: "Se agrego correctamente",
            icon: "success",
            type: "success",
          }).then((result) => {
            console.log(result);
            // location.reload();
            this.hasError = false;
            (this.m_code = null),
              (this.pack = null),
              (this.quantity = null),
              (this.confirm_quantity = null),
              (this.dialog = false);
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.dialogLoading = false));
    },
    excelDownload() {
      this.loading3 = true;
      axios
        .post(
          process.env.VUE_APP_API_IEL_SERVER + "/export-material-desc-exc",
          {
            VTWEG: "DT",
          },
          { responseType: "blob" }
        )
        .then((response) => {
          console.log(response.data);
          const current = new Date();
          const date = `${current.getDate()}/${
            current.getMonth() + 1
          }/${current.getFullYear()}`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "materiales_descontinuados" + "_" + date + ".xlsx"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.loading3 = false;
        })
        .catch((error) => {
          ///alert(error);
          console.log(error);
          this.loading3 = false;
        })
        .finally();
    },
    openDialogEmail() {
      this.dialog_email = true;
    },
    close2() {
      this.dialog_email = false;
    },
    sendEmail() {
      this.loading = true;
      axios
        .post(
          process.env.VUE_APP_API_IEL_SERVER +
            "/export-material-desc-exc-email",
          {
            VTWEG: "DT",
            email: this.email_user,
          }
        )
        .then((response) => {
          console.log(response.data);
          this.$swal({
            title: response.data.message,
            icon: "success",
            type: "succes",
          }).then((result) => {
            //this.getRecordatoriosHistory();
            console.log(result);
            this.dialog_email = false;
            this.loading = false;
          });
        })
        .catch((error) => {
          ///alert(error);
          console.log(error);
          this.loading = false;
        })
        .finally();
    },
  },
};
</script>
