<template>
  <v-container fluid class="grey lighten-5">
    <div class="text-center ma-2">
      <v-snackbar v-model="errormsg" :timeout="timeout">
        Cerciorase que haya buena conexión a internet. Re intente de nuevo.
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="errormsg = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-card
      class="mx-auto rounded-lg elevation-4 ma-3 ml-5 mr-5 mb-7"
      outlined
      :style="'border: 4px solid black; background-color:transparent;'"
    >
      <v-list-item-content>
        <div class="text-general-module" style="text-align: center">
          <v-icon color="black">mdi-cart</v-icon>
          Crear Pedido
        </div>
      </v-list-item-content>
    </v-card>
    <v-card>
      <customer-info
        :catchTotal="total"
        :warnLock="catchRequestLock"
        :catchLimit="catchPercent"
        :catchAviableCredit="catchAviable"
        :catchErrorConnection="catchConnection"
      ></customer-info>
      <p></p>
      <div class="col-12" style="text-align: left">
        <span class="text-ped">Captura de Pedido</span>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-md-4">
            <div class="">
              <v-text-field
                class="text-ped"
                v-model="order"
                label="Ingrese Orden de Compra"
                required
                variant="underlined"
                type="input"
              ></v-text-field>
            </div>
            <div>
              <v-autocomplete
                v-model="orderType"
                :items="orderItemsList"
                :item-text="getItemTextOrderType"
                item-value="type"
                eturn-object
                dense
                label="Tipo de Pedido"
                rounded
                color="#212529"
                class=".v-select__selection"
                style="
                  background-color: red !important;
                  font-size: 18px !important;
                  font-weight: bold !important;
                "
              >
              </v-autocomplete>
            </div>
          </div>
          <div class="col-md-6">
            <customer-destiny
              :warnDestiny="cacthDestiny"
              :catchErrorConnection="catchConnection"
            ></customer-destiny>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-3">
            <h2
              class="text-ped"
              style="text-align: center"
              @click="dialogCatalogo = true"
            >
              Mas de 4,200 productos
            </h2>
          </div>
          <div class="col-md-3">
            <v-switch>
              <template v-slot:label>
                <p class="text-ped">Pedido Programado</p>
              </template>
            </v-switch>
          </div>
        </div>
      </div>
      <div class="col-12" style="text-align: end; font-size: 12px">
        <a @click="dialogPromociones = true">
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Promociones
        </a>
        <a @click="dialogLiquidaciones = true">
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon
          >Liquidaciones
        </a>
        <a @click="dialogPedidos = true">
          <v-icon class="text-ped-links">mdi-book-open-variant</v-icon>Mis
          pedidos
        </a>
        <!--<a>
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Instrucciones de captura
        </a>
        <a>
          <v-icon class="text-ped-links">mdi-bookmark-plus</v-icon>Información del pedido
        </a>-->
      </div>
      <div class="card-body" ref="errorMaterial">
        <div class="row">
          <div class="col-md-12" v-if="hasError">
            <v-alert text dense outlined type="error">
              {{ hasError }}
            </v-alert>
          </div>
          <div class="col-md-8" v-if="this.client">
            <material-into-cart
              :warnMaterial="catchMaterialItem"
              :warnError="catchErrorMaterial"
              :warnDialogLoading="catchDialogLoading"
              :newMaterial="sendMaterialNew"
            ></material-into-cart>
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
      <br />
      <list-cart
        :capturedMaterialItem="materialItem"
        :warnTotal="catchTotal"
        :warnError="catchErrorMaterial"
        :warnDialogLoading="catchDialogLoading"
        :warnCart="catchCart"
        :capturedIdOrder="order_id"
        :catchErrorConnection="catchConnection"
      ></list-cart>
      <v-row v-if="cart.length != 0" class="justify-end">
        <v-col md="2" class="align-self-end">
          <label
            >Subtotal <strong>$ {{ formatPrice(subtotalPrint) }}</strong></label
          ><br />
          <label
            >IVA 16% <strong>$ {{ formatPrice(iva) }}</strong></label
          ><br />
          <label
            >Total
            <v-card-title style="color: #000; font-weight: 800"
              >$ {{ formatPrice(total) }}
            </v-card-title></label
          ><br />
          <v-btn
            class="ma-1 elevation-1"
            rounded
            :disabled="
              cart.length == 0 ||
              requestLock == 1 ||
              submitted ||
              porcentaje > 100 ||
              total > aviable ||
              porcentaje < 0
            "
            @click="checkBackOrder"
            color="success"
          >
            <span
              style="font-size: 12px"
              v-if="
                cart.length == 0 ||
                requestLock == 1 ||
                submitted ||
                porcentaje > 100 ||
                total > aviable ||
                porcentaje < 0
              "
              ><v-icon>mdi-cancel</v-icon>Compra Bloqueada</span
            >
            <span v-else><v-icon>mdi-check</v-icon>Comprar Ahora</span>
          </v-btn>
        </v-col>
      </v-row>
      <br />
      <div class="col-12" style="text-align: initial; font-size: 12px">
        <a>
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Cargar
          archivo
        </a>
        <!--<a>
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Convertir a pedido apoyo a
          marca
        </a>
        <a>
          <v-icon class="text-ped-links">mdi-book-open-variant</v-icon>Convertir a pedido
          especial
        </a>
        <a>
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Convertir a Pedido de
          Tienda Nueva
        </a>-->
        <a>
          <v-icon class="text-ped-links">mdi-bookmark-plus</v-icon>Datos
          Generales
        </a>
        <!-- <a>
          <v-icon class="text-ped-links">mdi-bookmark-check</v-icon>Generar
          archivo
        </a>-->
      </div>
      <loading-bar :capturedDialogLoading="dialogLoading"></loading-bar>
    </v-card>
    <br />
    <v-row>
      <v-col sm="12">
        <!--<div class="col-12" style="text-align: left">
          <span class="text-ped">La mejor sugerencia</span> / en base a tus
          compras.
        </div>
        <v-slide-group active-class="success" show-arrows>
          <v-slide-item
            v-for="(item, i) in items_product"
            :key="i"
            style="margin: 10px !important"
          >
            <v-card
              class="mx-auto pedidos-card-sugerencias"
              max-width="280"
              style="text-align: left"
            >
              <v-card-text>
                <strong>{{ item.name_product }}</strong>
              </v-card-text>
              <v-card-subtitle> SKU: {{ item.sku }} </v-card-subtitle>
              <v-img :src="item.src" width="180px"></v-img>
              <v-card-subtitle style="text-align: center">
                Empaque de 1 pza
              </v-card-subtitle>
              <v-card-title>
                <span style="text-align: center"> A {{ item.price }}</span>
              </v-card-title>
              <v-card-text>
                <v-btn
                  color="primary"
                  rounded
                  block
                  style="background: #079FFF color: #fff !important"
                >
                  <v-icon style="color: #fff !important">mdi-plus</v-icon
                  >Agregar al Carrito
                </v-btn>
              </v-card-text>
              <v-card-text>
                <v-btn
                  style="background: #2fd410 !important; color: #fff !important"
                  rounded
                  block
                >
                  <v-icon style="color: #fff !important">mdi-check</v-icon
                  >Comprar Ahora
                </v-btn>
              </v-card-text>
            </v-card>
          </v-slide-item>
        </v-slide-group>-->
      </v-col>
    </v-row>
    <v-dialog v-model="dialogBackOrder" persistent scrollable>
      <v-card>
        <v-card-title>Confirmar BackOrder</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p><b>Seleccionar los backorders confirmados</b></p>
          <v-row v-for="(item, i) in cart" :key="i">
            <v-checkbox v-model="item.reminder_check">
              <template v-slot:label>
                <div>
                  {{ item.codigo_material }}-{{ item.nombre_material }}
                  <strong>Backorder: {{ item.recordatorios }}</strong>
                </div>
              </template>
            </v-checkbox>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!--<button class="btn btn-danger" @click="dialogBackOrder = false">
             Cancelar
           </button>
           <button class="btn btn-success" @click="orderGenerate">
             Aceptar junto con Pedido
           </button>-->
          <v-btn color="error" @click="dialogBackOrder = false">
            Cancelar
          </v-btn>
          <v-btn color="success" @click="orderGenerate" :disabled="submitted">
            Enviar Pedido
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog loading -->
    <!--<v-row justify="center">
      <v-dialog
        v-model="dialogPromociones"
        hide-overlay
        width="600"
        transition="dialog-bottom-transition"
        justify="center"
      >
        <v-card justify="center">
          <div class="text-ped" style="text-align: center">PROMOCIONES</div>
          <div class="col-12 row">
            <table class="table">
              <thead>
                <tr
                  style="font-size: 10px; background-color: rgb(238, 238, 238)"
                >
                  <th colspan="2">Producto</th>
                  <th>Distribuidor</th>
                  <th>Promoción</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, i) in items_product"
                  :key="i"
                  style="font-size: 12px"
                >
                  <td>
                    <v-img :src="item.src" width="50" class="imageCarItem" />
                  </td>
                  <td style="text-align: start">
                    {{ item.sku }}-{{ item.name_product }}
                  </td>
                  <td>
                    {{ item.price }}
                  </td>
                  <td>
                    {{ item.less }}
                  </td>
                  <td>
                    <v-btn
                      color="primary"
                      rounded
                      block
                      style="background: #079FFF color: #fff !important"
                    >
                      <v-icon style="color: #fff !important">mdi-cart</v-icon
                      >Add
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialogPromociones = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>-->
    <!-- /. Dialog -->
    <!-- Dialog loading -->
    <!--<v-row justify="center">
      <v-dialog
        v-model="dialogLiquidaciones"
        hide-overlay
        width="600"
        transition="dialog-bottom-transition"
        justify="center"
      >
        <v-card justify="center">
          <div class="text-ped" style="text-align: center">LIQUIDACIONES</div>
          <div class="col-12 row">
            <table class="table">
              <thead>
                <tr
                  style="font-size: 10px; background-color: rgb(238, 238, 238)"
                >
                  <th colspan="2">Producto</th>
                  <th>Distribuidor</th>
                  <th>Liquidación</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, i) in items_product"
                  :key="i"
                  style="font-size: 12px"
                >
                  <td>
                    <v-img :src="item.src" width="50" class="imageCarItem" />
                  </td>
                  <td style="text-align: start">
                    {{ item.sku }}-{{ item.name_product }}
                  </td>
                  <td>
                    {{ item.price }}
                  </td>
                  <td>
                    {{ item.less }}
                  </td>
                  <td>
                    <v-btn
                      color="primary"
                      rounded
                      block
                      style="background: #079FFF color: #fff !important"
                    >
                      <v-icon style="color: #fff !important">mdi-cart</v-icon
                      >Add
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialogLiquidaciones = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>-->
    <!-- /. Dialog -->
    <!-- Dialog Catalogo -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogCatalogo"
        hide-overlay
        width="800"
        transition="dialog-bottom-transition"
        justify="center"
      >
        <v-card justify="center">
          <div class="text-ped" style="text-align: center">CATALOGO</div>
          <div class="col-12 row">
            <v-text-field
              v-model="material_code_search"
              label="Buscar nombre / SKU"
              append-icon="mdi-magnify"
              style="padding: 15px"
            ></v-text-field>
            <v-data-table
              :headers="headersCatalogo"
              :items="catalogo_list"
              :items-per-page="5"
              :search="material_code_search"
              class="elevation-1"
            >
              <template v-slot:[`item.MATNR`]="{ item }">
                {{ parseInt(item.MATNR) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  color="primary"
                  rounded
                  block
                  @click="newMaterialSearch(parseInt(item.MATNR))"
                  style="background: #079FFF color: #fff !important"
                >
                  <v-icon style="color: #fff !important">mdi-cart</v-icon>Add
                </v-btn>
              </template>
            </v-data-table>
          </div>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialogCatalogo = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- /. Dialog -->
    <!-- Dialog Pedidos -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogPedidos"
        hide-overlay
        width="800"
        transition="dialog-bottom-transition"
        justify="center"
      >
        <v-card justify="center">
          <div class="text-ped" style="text-align: center">
            Ordenes de Compra Abiertas
          </div>
          <!--<div class="text-ped" style="text-align: initial">
            <v-btn
              style="margin-left: 10px"
              dark
              rounded
              color="primary"
              elevation="2"
              small
              @click="generateNewOrder()"
            >
              <v-icon style="color: #fff !important">mdi-plus</v-icon> Crear
              Nuevo Pedido
            </v-btn>
          </div>-->

          <div class="col-12 row">
            <v-data-table
              :headers="headersPedido"
              :items="orders_list"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:[`item.name`]="{ item }">
                <!--<a @click="loadOrder(item)">{{ item.name }}</a>-->
                <p>{{ item.name }}</p>
              </template>
              <template v-slot:[`item.total`]="{ item }">
                {{ formatPrice(item.total) }}
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <p v-if="item.type == 'CL'">Cliente</p>
                <p v-if="item.type == 'VE'">Vendedor</p>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  style="margin-left: 10px"
                  dark
                  rounded
                  color="red darken-2"
                  elevation="2"
                  small
                  @click="deleteOrder(item)"
                >
                  <v-icon style="color: #fff !important">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialogPedidos = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- /. Dialog -->
  </v-container>
</template>

<script>
import axios from "axios";
import LoadingBar from "./componets/LoadingBar.vue";
import CustomerInfo from "./componets/CustomerInfo.vue";
import MaterialIntoCart from "./componets/MaterialIntoCart.vue";
import ListCart from "./componets/ListCart.vue";
import CustomerDestiny from "./componets/CustomerDestiny.vue";
import { mapGetters } from "vuex";
import { eventBus } from "@/main.js";

export default {
  name: "ShoppingCart",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  components: {
    CustomerInfo,
    MaterialIntoCart,
    ListCart,
    LoadingBar,
    CustomerDestiny,
  },
  data: () => ({
    material_code_search: "",
    submitted: false,
    hasError: null,
    materialItem: {},
    subtotal: 0,
    subtotalPrint: 0,
    iva: 0,
    total: 0,
    total2: "",
    porcentaje: 0,
    aviable: 0,
    dialogLoading: false,
    customerDestiny: {},
    order: "",
    cart: [],
    client: "",
    clients: [],
    clientCurrent: false,
    requestLock: 0,
    dialogBackOrder: false,
    order_response: "",
    dialogPromociones: false,
    dialogLiquidaciones: false,
    dialogCatalogo: false,
    sendMaterialNew: "",
    dialogPedidos: false,
    order_id: null,
    orderItemsList: [],
    orderType: null,
    errormsg: false,
    timeout: 6000,
    p: 1,
    headersCatalogo: [
      {
        text: "Material",
        align: "start",
        sortable: false,
        value: "MATNR",
      },
      { text: "Descripción", value: "MAKTX" },
      { text: "actions", value: "actions" },
    ],
    catalogo_list: [],
    headersPedido: [
      { text: "ID", value: "id" },
      {
        text: "Orden de compra",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Num. Partidas", value: "materials" },
      { text: "Importe Total", value: "total" },
      { text: "Usuario Creador", value: "type" },
      { text: "", value: "actions" },
    ],
    orders_list: [],
    items_product: [
      {
        src: "https://dev.iusaenlinea.com/img/267925_1.jpg",
        href: "https://iusa.com.mx/catalogo_construccion/",
        percent: "18%",
        price: "$4,155.00",
        less: "$3,777.00",
        name_product: "CALENTADOR IUSA DE RÁPIDA RECUPERACIÓN, 5L, GAS LP",
        sku: "308773",
      },
      {
        src: "https://dev.iusaenlinea.com/img/lbl-iusa-26_principal.jpg",
        href: "https://iusa.com.mx/catalogo_construccion/",
        percent: "25%",
        price: "$8,522.00",
        less: "$7,747.00",
        name_product: "CALENTADOR SOLAR PRIMO DE TUBOS AL VACÍO",
        sku: "308773",
      },
      {
        src: "https://dev.iusaenlinea.com/img/617035_01.jpg",
        href: "https://iusa.com.mx/catalogo_construccion/",
        percent: "20%",
        price: "$8,236.00",
        less: "$7,100.00",
        name_product: "MOTOBOMBA A GASOLINA PARA ALTA CARGA 6.5 HP",
        sku: "308773",
      },
      {
        src: "https://dev.iusaenlinea.com/img/268458_1.jpg",
        href: "https://iusa.com.mx/catalogo_construccion/",
        percent: "38%",
        price: "$14,800.00",
        less: "$17,168.00",
        name_product: "AIRE ACONDICIONADO MINI SPLIT, MASTERFRIZ 2023",
        sku: "308773",
      },
      {
        src: "https://dev.iusaenlinea.com/img/619049_01.jpg",
        href: "https://iusa.com.mx/catalogo_construccion/",
        percent: "10%",
        price: "$14,800.00",
        less: "$17,168.00",
        name_product: "ROTOMARTILLO 750 W",
        sku: "308773",
      },
    ],
  }),
  created() {
    eventBus.$on("change-client", (data) => {
      console.log(
        "change-client => ",
        data,
        " client => ",
        this.client,
        " storage => ",
        JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR
      );
      this.p = 1;
      this._validateClient();
    });
  },
  async mounted() {
    this._validateClient();
  },
  methods: {
    _validateClient() {
      if (localStorage.getItem("1us4-pv-cl13nt")) {
        this.client = JSON.parse(localStorage.getItem("1us4-pv-cl13nt")).KUNNR;
        localStorage.setItem("1us4-p0rt4l-v3nd3nd0r-cl13n7", this.client);
        this.clientCurrent = true;

        this.getOrderID();
        this.checkOrdersHistory();
        this.getCatalogo();
        this.orderTypeItemsData();
      }
    },
    formatPrice(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return "00.00";
    },
    getCatalogo() {
      axios
        .get(process.env.VUE_APP_API_IEL_SERVER + "/getCatalogo")
        .then((response) => {
          this.catalogo_list = response.data;
        })
        .catch((error) => {
          //console.warn(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    getCatalogoRandom() {
      axios
        .get(process.env.VUE_APP_API_IEL_SERVER + "/getCatalogoRandom")
        .then((response) => {
          this.items_product = response.data;
        })
        .catch((error) => {
          //console.warn(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    newMaterialSearch(item) {
      this.sendMaterialNew = item;
    },
    getOrderID() {
      console.info("getOrderID()");
      let type = "VE";
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/getOrderID", {
          customer: this.client,
          user_id: this.user.id,
          type: type,
        })
        .then((response) => {
          this.order_id = response.data;
          this.getOrderInfo(this.order_id);
          //this.errormsg = true;
        })
        .catch((error) => {
          //console.warn(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    getOrderInfo(id) {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/getOrderInfo", {
          id_order: id,
        })
        .then((response) => {
          this.order = response.data.name;
        })
        .catch((error) => {
          //console.warn(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    checkOrdersHistory() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/checkOrdersHistory", {
          customer: this.client,
        })
        .then((response) => {
          this.orders_list = response.data;
        })
        .catch((error) => {
          //console.warn(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    saveOrderInfo() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/saveOrderInfo", {
          order_id: this.order_id,
          orden_compra: this.order,
          destiny: this.customerDestiny.KUNNR,
          cart: this.cart,
          total: this.total,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          //console.warn(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    loadOrder(item) {
      this.dialogLoading = true;
      this.dialogPedidos = false;
      this.order_id = item.id;
      this.getOrderInfo(this.order_id);
    },
    deleteOrder(item) {
      this.dialogLoading = true;
      this.dialogPedidos = false;
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/orderDelete", {
          order_id: item.id,
        })
        .then((response) => {
          console.log(response);
          this.dialogPedidos = true;
          this.checkOrdersHistory();
        })
        .catch((error) => {
          //console.warn(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    generateNewOrder() {
      this.dialogLoading = true;
      this.dialogPedidos = false;
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/generateNewOrder", {
          customer: this.client,
        })
        .then((response) => {
          this.order_id = response.data;
          this.checkOrdersHistory();
        })
        .catch((error) => {
          // console.warn(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    catchMaterialItem(data) {
      console.log(data);
      this.materialItem = data;
    },
    catchErrorMaterial(data) {
      this.hasError = data;
      window.scroll(0, window.pageYOffset - 50);
    },
    catchTotal(data) {
      this.subtotalPrint = parseFloat(data).toFixed(2);
      this.subtotal = parseFloat(data);
      this.iva = parseFloat((this.subtotal * 0.16).toFixed(2));
      this.total = this.subtotal + this.iva;
      this.total = this.total.toFixed(2);
    },
    catchDialogLoading(data) {
      this.dialogLoading = data;
    },
    cacthDestiny(data) {
      console.log("Destiny" + data);
      this.customerDestiny = data;
    },
    catchCart(data) {
      this.cart = data;
      this.saveOrderInfo();
    },
    catchRequestLock(data) {
      console.log(data);
      this.requestLock = data;
    },
    catchPercent(data) {
      this.porcentaje = data;
    },
    catchAviable(data) {
      this.aviable = data;
    },
    catchConnection(data) {
      this.errormsg = data;
    },
    checkBackOrder() {
      this.hasError = null;
      if (this.order == "") {
        this.hasError = "Es necesario ingresar orden de compra";
        this.$refs.errorMaterial.scrollTop = 0;
      } else if (this.customerDestiny == {}) {
        this.hasError = "Es necesario ingresar destino de compra";
      } else {
        const existBackOrder = this.cart.some((item) => item.recordatorios > 0);
        if (existBackOrder) {
          this.dialogBackOrder = true;
        } else {
          this.orderGenerate();
        }
      }
    },
    orderGenerate() {
      this.dialogBackOrder = false;
      this.dialogLoading = true;
      this.submitted = true;

      if (this.customerDestiny == {}) {
        this.warnError("El campo de Destino de compra es obligatorio.");
      } else if (this.order_id == "") {
        //this.warnError("El campo de Orden de compra es obligatorio.");
        //this.hasError = "Es necesario ingresar orden de compra";
        this.$refs.errorMaterial.scrollTop = 0;

        this.$swal({
          title: "Es necesario ingresar orden de compra.",
          icon: "error",
          type: "error",
        }).then((result) => {
          console.log(result);
          location.reload();
        });
      } else {
        axios
          .post(process.env.VUE_APP_API_IEL_SERVER + "/generateOrderCustomer", {
            order_id: this.order_id,
            orden_compra: this.order,
            destino_compra: this.customerDestiny.KUNNR,
            carrito: this.cart,
            customer: this.client,
            VKORG: "IUS2",
            VTWEG: "DT",
            VKBUR: localStorage.getItem("1us4-p0rt4l-v3nd3nd0r-VKBUR"),
            numero_vendedor: this.user.employee_number,
            order_type: this.orderType,
          })
          .then((response) => {
            if (response.data.message == "BACKORDER GENERADO") {
              this.$swal.fire(
                response.data.message,
                "Número de Pedido: S/N",
                "success"
              );
              this.$router.replace({
                name: "HistorialPedidos",
              });
            } else {
              this.dialogLoading = false;
              //this.order_response = response.data.RDCreaEntrega.VBELN;
              this.order_response = response.data.VBELN;
              this.cart = [];
              this.order = null;

              this.$swal.fire(
                "Pedido Generado",
                "Número de Pedido: " + this.order_response + ".",
                "success"
              );
              this.$router.replace({
                name: "HistorialPedidos",
              });
            }
          })
          .catch((error) => {
            this.dialogLoading = false;
            if (error.response.status == 404) {
              // console.warn(error.response.data.error_code);
              this.$swal({
                title: error.response.data.error_code,
                icon: "error",
                type: "error",
              }).then((result) => {
                console.log(result);
                // location.reload();
              });
            } else {
              console.warn(error);
              console.log(error);
            }

            this.submitted = false;
          })
          .finally(
            () => ((this.dialogLoading = false), (this.submitted = false))
          );
      }
    },
    orderTypeItemsData() {
      axios
        .post(process.env.VUE_APP_API_IEL_SERVER + "/orderType")
        .then((response) => {
          //this.orderType = response.data.type;
          // this.getItemTextOrder = response.data.name;
          this.orderItemsList = response.data;
          this.orderType = this.orderItemsList;

          //this.destiny = this.customerDestinyList;
          console.log("Tipo PEDIDO");
          console.log(this.orderTypeItems);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.dialogLoading = false));
    },
    getItemTextOrderType(item) {
      return `(${item.type}) ${item.name}`;
    },
    getItemValueOrderType(item) {
      return `${item.type}`;
    },
  },
  destroyed() {
    eventBus.$off("change-client");
  },
};
</script>
<style></style>
